<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.171 99.586">
    <g id="Group_244" data-name="Group 244" transform="translate(-549.236 0)">
      <path
        id="Path_318"
        data-name="Path 318"
        d="M600.57,14.826c-.6.4-1.2.8-1.793,1.209-1.39.952-2.823,1.967-4.265,3.016-16.451,11.976-29.732,25.938-38.4,40.37-.445.736-.875,1.487-1.283,2.217s-.788,1.448-1.151,2.166a41.793,41.793,0,0,0-4.085,11.326c-.136.759-.234,1.524-.293,2.275a19.244,19.244,0,0,0-.05,2.22l.015.191a15.037,15.037,0,0,0,.354,2.583,12.728,12.728,0,0,0,1.153,3.124l.124.242a14.018,14.018,0,0,0,2.262,3.007c2.507,2.561,6.147,4.635,11.13,6.329.688.234,1.433.472,2.285.72l.452.137c.876.249,1.8.49,2.747.719.227.054.456.105.686.156l.266.06c1.294.295,2.612.562,3.909.8,1.057.191,2.136.366,3.207.522l.632.088.763.107q.9.118,1.808.227c.6.074,1.209.141,1.82.2l.083.008c.253.025.5.047.758.07l.723.067.727.067.2.017c.114.01.227.02.343.025l.308.023c.592.047,1.181.089,1.773.131l.184.011c1.288.082,2.646.152,4.135.211,14.789.586,27.549-1.028,37.923-4.8a64.582,64.582,0,0,0,20.959-12.26c.558-.5,1.04-.938,1.475-1.349s.863-.843,1.288-1.271a63.067,63.067,0,0,0,9.829-13.036c.391-.689.779-1.48,1.152-2.243s.737-1.546,1.1-2.369a66.053,66.053,0,0,0,2.6-6.93q.259-.838.5-1.688c.16-.568.314-1.133.455-1.7l.047-.177c.065-.251.126-.5.189-.751.272-1.146.522-2.327.736-3.516.234-1.276.425-2.534.572-3.742.9-7.367.58-14.123-.92-19.532a44.668,44.668,0,0,0-3.872-9.764,36.868,36.868,0,0,0-3.77-5.548,18.526,18.526,0,0,0-2.036-2.014,25.222,25.222,0,0,0-2.191-1.743,26.338,26.338,0,0,0-8.43-3.919c-.706-.192-1.433-.358-2.154-.494-.8-.155-1.537-.269-2.239-.351a43.585,43.585,0,0,0-10.066.037c-.647.072-1.344.171-2.139.3-.694.113-1.417.244-2.146.392-8.853,1.8-18.232,5.958-28.675,12.714Q601.412,14.261,600.57,14.826Z"
        transform="translate(0)"
        fill="#e4b134"
      />
      <g
        id="Group_241"
        data-name="Group 241"
        transform="translate(552.185 1.487)"
      >
        <path
          id="Path_319"
          data-name="Path 319"
          d="M726.805,13.131a3.346,3.346,0,1,1,2.367-.979A3.337,3.337,0,0,1,726.805,13.131Zm0-4.384a1.038,1.038,0,1,0,.734.3A1.037,1.037,0,0,0,726.805,8.747Z"
          transform="translate(-686.19 -6.44)"
          fill="#3f6a32"
        />
        <path
          id="Path_320"
          data-name="Path 320"
          d="M614.516,390.775a3.346,3.346,0,1,1,2.367-.979,3.337,3.337,0,0,1-2.367.979Zm0-4.384a1.038,1.038,0,1,0,.735.3A1.037,1.037,0,0,0,614.516,386.391Z"
          transform="translate(-599.822 -296.907)"
          fill="#3f6a32"
        />
        <path
          id="Path_321"
          data-name="Path 321"
          d="M683.445,33.3a1.151,1.151,0,0,1-.816-.338l-1.377-1.377-1.377,1.377a1.154,1.154,0,0,1-1.632-1.632l2.193-2.193a1.155,1.155,0,0,1,1.632,0l2.193,2.193a1.154,1.154,0,0,1-.816,1.97Z"
          transform="translate(-651.151 -23.64)"
          fill="#3f6a32"
        />
        <path
          id="Path_322"
          data-name="Path 322"
          d="M968.412,379.079a1.149,1.149,0,0,1-.816-.338l-1.377-1.377-1.377,1.377a1.154,1.154,0,0,1-1.632-1.632l2.193-2.193a1.154,1.154,0,0,1,1.632,0l2.193,2.193a1.154,1.154,0,0,1-.816,1.97Z"
          transform="translate(-870.335 -289.595)"
          fill="#3f6a32"
        />
        <path
          id="Path_323"
          data-name="Path 323"
          d="M567.55,383.829a1.149,1.149,0,0,1-.816-.338l-1.377-1.377-1.377,1.377a1.154,1.154,0,0,1-1.632-1.632l2.193-2.193a1.153,1.153,0,0,1,1.632,0l2.193,2.193a1.154,1.154,0,0,1-.816,1.97Z"
          transform="translate(-562.01 -293.248)"
          fill="#3f6a32"
        />
      </g>
      <g
        id="Group_242"
        data-name="Group 242"
        transform="translate(575.923 15.136)"
      >
        <path
          id="Path_324"
          data-name="Path 324"
          d="M690.319,246.425h56.557V208.566H690.319Z"
          transform="translate(-684.438 -175.555)"
          fill="#fff"
        />
        <path
          id="Path_325"
          data-name="Path 325"
          d="M733.63,93.655l-2.848-10.721-3.964.012V65.566H671.184V82.948l-3.318.013-2.912,10.788c-.605,2.252,1.283,4.367,3.847,4.367H685.93l1.4-4.231-1.4,4.462h27.532l-.088-.231H729.7C732.32,98.115,734.248,95.954,733.63,93.655Z"
          transform="translate(-664.841 -65.566)"
          fill="#fff"
        />
      </g>
      <g
        id="Group_243"
        data-name="Group 243"
        transform="translate(574.689 12.819)"
      >
        <path
          id="Path_326"
          data-name="Path 326"
          d="M730.562,84.433,727.613,74.26a1.457,1.457,0,0,0-1.4-1.052H723.98V56.985a1.457,1.457,0,0,0-1.457-1.457H667.734a1.457,1.457,0,0,0-1.458,1.457V73.208h-2.233a1.458,1.458,0,0,0-1.4,1.052L659.7,84.433a5.032,5.032,0,0,0,.839,4.457,5.986,5.986,0,0,0,4.792,2.314h.276V128.02a1.457,1.457,0,0,0,1.456,1.457H723.2a1.457,1.457,0,0,0,1.457-1.457V107.564a1.457,1.457,0,0,0-2.914,0v19H690.975V114.457a8.767,8.767,0,0,0-17.534,0v12.106h-4.926V91.2h53.226v4.314a1.457,1.457,0,0,0,2.914,0V91.2h.275a5.986,5.986,0,0,0,4.792-2.314,5.033,5.033,0,0,0,.839-4.457Zm-54.206,30.024a5.852,5.852,0,0,1,11.7,0v12.106h-11.7Zm-7.164-56.015h51.874V73.208H669.191Zm33.883,17.68,3.841,12.167H684.143l3.84-12.167ZM662.863,87.14a2.116,2.116,0,0,1-.369-1.9l2.644-9.122h19.79L681.086,88.29H665.326a3.065,3.065,0,0,1-2.462-1.15Zm64.53,0a3.063,3.063,0,0,1-2.462,1.15h-14.96l-3.84-12.167h18.988l2.644,9.121A2.118,2.118,0,0,1,727.393,87.14Z"
          transform="translate(-659.495 -55.528)"
          fill="#3f6a32"
        />
        <path
          id="Path_327"
          data-name="Path 327"
          d="M814.212,222.749a5.835,5.835,0,0,0-5.828,5.829V241.1a5.835,5.835,0,0,0,5.828,5.829h12.7a5.836,5.836,0,0,0,5.829-5.829V228.578a5.836,5.836,0,0,0-5.829-5.829Zm15.61,5.828V241.1a2.918,2.918,0,0,1-2.915,2.914h-12.7A2.917,2.917,0,0,1,811.3,241.1V228.577a2.917,2.917,0,0,1,2.914-2.914h12.7A2.918,2.918,0,0,1,829.823,228.577Z"
          transform="translate(-774.014 -184.147)"
          fill="#ee8484"
        />
        <path
          id="Path_328"
          data-name="Path 328"
          d="M721.365,222.806a1.457,1.457,0,0,0-1.458,1.457v5.779a1.457,1.457,0,0,0,1.458,1.457h14.619a1.457,1.457,0,0,0,1.457-1.457v-5.779a1.457,1.457,0,0,0-1.457-1.457Zm13.162,5.779h-11.7V225.72h11.7Z"
          transform="translate(-705.961 -184.191)"
          fill="#3f6a32"
        />
        <path
          id="Path_329"
          data-name="Path 329"
          d="M930.236,247.363a1.444,1.444,0,1,1-1.444,1.444A1.445,1.445,0,0,1,930.236,247.363Z"
          transform="translate(-866.626 -203.079)"
          fill="#3f6a32"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
