<template>
  <go-back-bar titulo="Registro" @go-back="goToRegistro" />

  <form
    v-if="pasoActivo === 1"
    class="pt-10 w-full relative flex flex-col items-center max-w-lg mx-auto px-4 "
    style="margin:0 auto;"
    @submit.prevent="finalizarRegistro"
  >
    <header-registro />
    <div
      v-for="campo in campos"
      :key="campo.id"
      class="text-left w-full mt-6 z-10"
    >
      <label :for="campo.id" class="font-bold ">{{ campo.titulo }}</label>
      <input
        :id="campo.id"
        :name="campo.id"
        :type="campo.type"
        :autocomplete="campo.autocomplete"
        :required="campo.required"
        v-model="campo.value"
        :class="[
          'appearance-none rounded-full relative block w-full px-3 py-2 text-gray-900 shadow-md border border-gris-2 focus:outline-none focus:ring-verde-1 focus:border-verde-1 focus:z-10 sm:text-sm'
        ]"
      />
    </div>

    <button
      class="w-full rounded-full py-2 mt-10 mb-4 bg-verde-1 text-white text-center text-xl apparance-none focus:outline-none z-10"
      type="submit"
    >
      Siguiente
    </button>
  </form>

  <div
    v-if="pasoActivo === 2"
    class="w-full absolute max-w-lg z-50 h-full top-0 left-0 bg-white flex flex-col"
  >
    <div class="w-2/3 mt-5 mx-auto">
      <span class="text-4xl text-verde-1 font-bold">
        ¡Ya podés aprovechar la comida!
      </span>
    </div>

    <div class="my-12 mx-auto">
      <svg-bienvenido />
    </div>
    <div class="text-verde-1 font-bold">
      Provecho te da la bienvenida
    </div>
    <div class="w-2/3 mx-auto mt-2">
      <button-verde-rounded @click="goToLogin">
        Iniciar sesión
      </button-verde-rounded>
    </div>
  </div>
</template>

<script>
import router from "@/router/router.js";
import { apiRegistroCliente } from "@/services/routes.js";
import { ref } from "vue";

import GoBackBar from "@/components/elementos/GoBack.vue";
import HeaderRegistro from "./HeaderRegistro.vue";
import SvgBienvenido from "../Icons/svgBienvenido.vue";
import ButtonVerdeRounded from "../elementos/ButtonVerdeRounded.vue";

export default {
  components: { HeaderRegistro, GoBackBar, SvgBienvenido, ButtonVerdeRounded },
  setup() {
    let pasoActivo = ref(1);

    const campos = [
      {
        titulo: "Nombre",
        placeholder: "Nombre",
        value: "",
        autocomplete: true,
        required: true,
        type: "text",
        id: "nombre"
      },
      {
        titulo: "Email",
        placeholder: "Email",
        value: "",
        autocomplete: "email",
        required: true,
        type: "email",
        id: "email"
      },
      {
        titulo: "Contraseña",
        placeholder: "Contraseña",
        value: "",
        autocomplete: false,
        required: true,
        type: "password",
        id: "password"
      },
      {
        titulo: "Repita su contraseña",
        placeholder: "Repita su contraseña",
        value: "",
        autocomplete: false,
        required: true,
        type: "password",
        id: "passwordRep"
      }
    ];

    const goToRegistro = () => {
      router.push({ path: "registro" });
    };
    const goToLogin = () => {
      router.push({ path: "login" });
    };

    const finalizarRegistro = () => {
      fetch(apiRegistroCliente(), {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          email: campos.find(c => c.id === "email").value,
          password: campos.find(c => c.id === "password").value,
          first_name: campos.find(c => c.id === "nombre").value,
          last_name: campos.find(c => c.id === "nombre").value,
          district_id: null
        })
      })
        .then(res => res.json())
        .then(() => {
          pasoActivo.value = 2;
        });
    };

    return { campos, goToRegistro, finalizarRegistro, goToLogin, pasoActivo };
  }
};
</script>

<style></style>
