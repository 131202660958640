<template>
  <input
    id="searchTextField"
    placeholder="Escriba su dirección"
    class="border"
    type="text"
    size="50"
  />
</template>

<script>
import { Loader } from "@googlemaps/js-api-loader";
import { onMounted } from "vue";

export default {
  emits: ["changePlace"],
  setup(props, { emit }) {
    onMounted(() => {
      // eslint-disable-next-line no-undef
      const loader = new Loader({
        apiKey: "AIzaSyB83nMg2JYfHnLKQYnqmFBRDIhUxWNr2As",
        version: "weekly",
        libraries: ["places"]
      });

      loader
        .load()
        .then(() => {
          initializeMap();
        })
        .catch(() => {
          // do something
        });
    });
    let initializeMap = () => {
      var input = document.getElementById("searchTextField");
      // eslint-disable-next-line no-undef
      let autocomplete = new google.maps.places.Autocomplete(input);
      autocomplete.setComponentRestrictions({
        country: ["ar"]
      });
      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();

        let objPlace = {
          direccion: place.vicinity
            ? `${place.name}, ${place.vicinity}`
            : `${place.name}`,
          latitude: place.geometry ? place.geometry.location.lat() : null,
          longitude: place.geometry ? place.geometry.location.lng() : null
        };

        emit("changePlace", objPlace);
      });
    };
  }
};
</script>

<style></style>
