<template>
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    enable-background="new 0 0 24 24"
    xml:space="preserve"
  >
    <path
      fill="currentColor"
      stroke="#FFFFFF"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M4.318,6.318
	c-1.757,1.757-1.757,4.606,0,6.364L12,20.364l7.682-7.683c1.758-1.758,1.758-4.606,0-6.364c-1.757-1.758-4.605-1.758-6.363,0
	L12,7.636l-1.318-1.317C8.925,4.561,6.075,4.561,4.318,6.318z"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
