import { createRouter, createWebHashHistory } from "vue-router";
import Container from "../views/Container.vue";

import Home from "../views/Home.vue";
import HomeLocal from "../views/HomeLocal.vue";
import LocalesTab from "../views/Locales.vue";
import Tips from "../views/Tips.vue";
import Ticket from "../views/Ticket.vue";
import Local from "../views/Local.vue";
import CompraRealizada from "../views/CompraRealizada.vue";
import Favoritos from "../views/Favoritos.vue";
import Mas from "../views/Mas.vue";
import Test from "../views/Test.vue";

import Login from "../views/Login.vue";
import Registro from "../views/Registro.vue";
import RegistroPersona from "../components/Registro/Persona.vue";
import RegistroLocal from "../components/Registro/Local.vue";

const routes = [
  {
    path: "/test",
    name: "test",
    component: Test
  },
  {
    path: "/",
    name: "Container",
    component: Container,
    children: [
      {
        title: "HomeLocal",
        path: "homelocal",
        name: "HomeLocal",
        component: HomeLocal
      },
      {
        title: "Home",
        path: "",
        name: "Home",
        component: Home
      },
      {
        path: "locales",
        name: "Locales",
        component: LocalesTab,
        props: true
      },
      {
        path: "Tips",
        name: "Tips",
        component: Tips
      },
      {
        path: "favoritos",
        name: "favoritos",
        component: Favoritos
      },
      {
        path: "mas",
        name: "mas",
        component: Mas,
        props: true
      },
      {
        path: "ticket/:id",
        name: "Ticket",
        component: Ticket,
        props: true
      },
      {
        path: "CompraRealizada/:id",
        name: "CompraRealizada",
        component: CompraRealizada
      }
    ]
  },
  {
    name: "",
    path: "/local",
    component: Container,
    children: [
      {
        path: ":id",
        name: "local",
        component: Local
      }
    ]
  },
  {
    path: "/login",
    name: "Login",
    component: Login
  },
  {
    path: "/registro",
    name: "Registro",
    component: Registro
  },
  {
    path: "/registroPersona",
    name: "RegistroPersona",
    component: RegistroPersona
  },
  {
    path: "/registroLocal",
    name: "RegistroLocal",
    component: RegistroLocal
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

export default router;
