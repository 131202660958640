<template>
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 457.742 417.978"
    enable-background="new 0 0 457.742 417.978"
    xml:space="preserve"
  >
    <path
      fill="#E4B134"
      d="M287.757,333.662c2.042-1.91,4.086-3.829,6.091-5.771c4.713-4.529,9.551-9.325,14.398-14.258
	c55.324-56.313,97.251-118.207,121.233-178.957c1.232-3.101,2.401-6.248,3.497-9.3c1.066-3,2.093-6.044,3.025-9.021
	c5.419-17.179,8.257-32.622,8.413-45.946c0.035-2.992-0.075-5.979-0.327-8.891c-0.263-2.996-0.676-5.862-1.225-8.527l-0.18-0.719
	c-0.757-3.406-1.757-6.654-3.002-9.658c-1.656-4.054-3.817-7.824-6.4-11.222l-0.631-0.85c-2.904-3.667-6.465-7.068-10.57-10.067
	c-11.227-8.203-26.478-13.822-46.623-17.129c-2.781-0.459-5.786-0.894-9.204-1.298l-1.813-0.237
	c-3.512-0.396-7.2-0.729-10.969-1.002c-0.905-0.06-1.816-0.112-2.725-0.16l-1.056-0.06c-5.142-0.302-10.356-0.487-15.466-0.557
	c-4.166-0.056-8.409-0.04-12.604,0.045l-2.475,0.065c-0.989,0.026-1.984,0.048-2.988,0.076c-2.341,0.081-4.693,0.171-7.064,0.282
	c-2.351,0.101-4.718,0.232-7.095,0.378l-0.324,0.021c-0.981,0.064-1.958,0.142-2.945,0.215l-2.807,0.205
	c-0.93,0.067-1.874,0.134-2.829,0.207l-0.772,0.063c-0.44,0.035-0.881,0.069-1.327,0.124l-1.194,0.106
	c-2.296,0.198-4.573,0.412-6.865,0.629l-0.711,0.073c-4.985,0.505-10.226,1.104-15.961,1.828
	c-56.969,7.182-104.769,21.49-142.065,42.535c-28.938,16.351-53.297,36.623-72.393,60.27c-1.819,2.254-3.382,4.251-4.784,6.104
	c-1.402,1.865-2.766,3.776-4.12,5.684c-11.9,16.87-21.766,35.768-29.307,56.148c-1.061,2.886-2.039,6.161-2.981,9.316
	c-0.926,3.096-1.835,6.385-2.696,9.764c-2.352,9.227-4.212,18.708-5.518,28.174c-0.305,2.247-0.577,4.51-0.821,6.773
	c-0.25,2.273-0.48,4.534-0.655,6.795l-0.067,0.706c-0.088,1.003-0.161,1.997-0.245,2.995c-0.313,4.56-0.515,9.237-0.574,13.926
	c-0.083,5.031-0.014,9.968,0.193,14.684c1.255,28.763,6.778,54.414,15.966,74.157c6.34,13.608,13.22,25.027,21.041,34.896
	c5.386,6.794,11.269,12.958,17.963,18.83c2.474,2.181,5.813,4.374,9.074,6.41c3.15,1.975,6.334,3.754,9.493,5.273
	c10.674,5.184,22.368,8.406,34.76,9.626c2.823,0.285,5.715,0.454,8.56,0.517c3.176,0.081,6.056,0.051,8.794-0.084
	c12.618-0.626,25.576-2.818,38.498-6.555c2.432-0.688,5.036-1.509,7.995-2.503c2.586-0.878,5.269-1.836,7.966-2.867
	c32.732-12.526,65.972-34.415,101.632-66.923C283.917,337.242,285.852,335.458,287.757,333.662z"
    />
    <path
      fill="#FFFFFF"
      d="M398.368,225.698l0.081-0.126c-0.033-0.052-0.007-0.102-0.038-0.152l-30.478-90.465
	c-0.421-1.249-1.722-2.308-2.841-2.988c-9.729-5.918-19.943-10.399-28.943-13.489v-0.003c0-0.007,0.439-0.013,0.418-0.021
	l-18.729-55.649c-0.375-1.091-0.938-2.04-1.862-2.718c-0.695-0.532-1.343-1.043-2.071-1.532
	c-19.639-13.166-46.373-7.732-59.387,12.136c-0.72,0.97-1.368,2.149-1.368,3.362v59.865c-5,3.958-9.252,8.49-13.859,13.592
	c-12.688-7.777-29.107-7.789-41.809-0.043c-4.577-5.054-10.332-9.546-14.332-13.474V74.07c0-1.213-0.018-2.393-0.736-3.363
	c-0.485-0.741-0.811-1.469-1.341-2.18c-14.214-19.01-40.876-22.762-59.667-8.384c-0.924,0.677-1.576,1.626-1.949,2.718
	l-18.979,55.653c-0.003,0.002,0.01,0.003,0.004,0.005c-9.267,3.09-18.758,7.573-28.496,13.496c-1.12,0.681-1.962,1.738-2.384,2.988
	l-30.51,90.411l-0.319,0.946c-0.044,0.116-0.082,0.234-0.117,0.352c-0.886,2.963,0.771,6.092,3.697,6.988
	c0.519,0.159,1.042,0.236,1.557,0.24c-0.522-0.001-1.055-0.078-1.583-0.24c-2.928-0.896-4.57-4.025-3.685-6.988
	c0.035-0.117,0.08-0.235,0.125-0.352l0.323-0.946c-9.015,14.076-14.249,30.858-14.243,48.879c0.016,49.69,39.559,89.96,88.68,89.945
	c49.08-0.068,88.623-40.302,88.623-89.95c0-0.017,0-0.034,0-0.051c0-0.948,0.17-1.893,0.141-2.834
	c-0.129,2.979-2.593,5.355-5.567,5.355c2.975,0,5.416-2.377,5.545-5.355c-0.456-14.744-4.191-28.591-11.11-40.721
	c6.919,12.13,10.567,25.977,11.023,40.721c0.004-0.083-0.032-0.165-0.032-0.249v-53.828c10-5.987,22.211-6.003,32.098-0.035
	c0.986,0.514,2.025,1.059,2.839,1.661c0.957,0.625,2.163,0.913,3.241,0.896c-1.084,0.024-2.405-0.264-3.37-0.896
	c-0.815-0.603-1.808-1.147-2.808-1.661v53.521v0.343c0,0.021,0.283,0.041,0.283,0.062c0.006,1.014,0.162,2.031,0.162,3.054
	c0.004,49.691,39.66,89.971,88.781,89.968c49.09-0.057,88.773-40.293,88.773-89.952c0-0.01,0-0.018,0-0.028
	C412.149,256.371,407.266,239.706,398.368,225.698z M388.665,230.745c-13.855-21.197-37.604-35.179-64.987-35.21
	c27.352,0.03,51.083,13.979,64.949,35.136c-0.079-0.165-0.149-0.335-0.214-0.511c-0.027-0.072-0.051-0.147-0.076-0.221
	l-8.613-25.524c0,0,0,0.001,0.001,0.001l8.614,25.523c0.025,0.073,0.051,0.148,0.078,0.221c0.066,0.183,0.141,0.36,0.224,0.531
	c-0.006-0.007-0.009-0.014-0.014-0.021C388.639,230.696,388.652,230.72,388.665,230.745z M243.987,159.775
	c-0.985,0.848-2.24,1.33-3.564,1.33c-0.004,0-0.009,0-0.014,0C241.727,161.105,242.995,160.619,243.987,159.775z M323.567,184.292
	C323.568,184.292,323.568,184.292,323.567,184.292c1.117,0,2.225,0.032,3.33,0.073C325.791,184.324,324.683,184.292,323.567,184.292
	z M379.549,204.189L379.549,204.189l-21.639-63.657c-17.633-10.436-34.092-15.74-49.605-15.894
	c15.533,0.146,32.011,5.451,49.656,15.894L379.549,204.189z M308.013,68.308l16.09,46.744c0.039,0.008,0.08,0.018,0.121,0.025
	l3.307,9.672c0.811,2.377,3.043,3.848,5.403,3.775c-2.361,0.072-4.594-1.398-5.405-3.775l-3.54-9.697l-16.09-46.744
	c-5.303-3.634-11.216-5.429-17.11-5.519C296.728,62.865,302.687,64.659,308.013,68.308z M239.51,147.583
	c-1.03,1.143-2.129,2.313-3.158,3.512c1.029-1.205,2.097-2.382,3.132-3.528C239.492,147.572,239.501,147.579,239.51,147.583z
	 M237.146,159.552c0.256,0.186,0.532,0.342,0.818,0.479c-0.291-0.137-0.57-0.293-0.826-0.479c-0.755-0.638-1.439-1.219-2.427-1.768
	C235.698,158.333,236.391,158.915,237.146,159.552z M197.449,160.674c1.097-0.058,2.182-0.424,3.096-1.122
	C199.622,160.256,198.539,160.623,197.449,160.674z M195.491,149.303c-0.505,0.361-1.012,0.722-1.494,1.108
	c-0.026,0.019-0.063,0.038-0.097,0.057c0.024-0.019,0.046-0.038,0.073-0.057C194.451,150.028,194.968,149.662,195.491,149.303z
	 M129.862,124.631c-7.318-0.02-14.854,1.111-22.613,3.377c0.021-0.01,0.04-0.019,0.059-0.028
	C115.044,125.731,122.561,124.608,129.862,124.631z M128.882,113.46c-5.116,0.063-10.314,0.605-15.569,1.624l0.006-0.017
	C118.566,114.057,123.764,113.519,128.882,113.46z M113.552,185.105c0.002,0,0.004,0,0.006,0c-5.066,0-10.024,0.444-14.852,1.27
	C103.527,185.548,108.482,185.105,113.552,185.105z M113.207,115.123l-0.007,0.018c-3.263,0.644-6.558,1.475-9.883,2.486
	C106.647,116.606,109.917,115.768,113.207,115.123z M35.821,274.244c0.006-15.439,4.474-29.825,12.065-41.977
	c0.041-0.042,0.087-0.077,0.127-0.12c0.443-0.483,0.812-1.048,1.069-1.687c0.045-0.109,0.082-0.224,0.119-0.337
	c0.012,0.114,0.047,0.227,0.002,0.337c13.979-21.084,37.228-34.963,64.906-34.953c-27.679-0.01-50.903,13.869-64.881,34.953
	c-0.257,0.639-0.698,1.203-1.143,1.687c-7.637,12.177-12.152,26.607-12.158,42.097c-0.015,43.5,34.615,78.776,77.617,78.792
	C70.544,353.02,35.806,317.744,35.821,274.244z M191.207,214.313c0-0.042,0.008-0.083,0.008-0.124v0.276
	C191.215,214.415,191.207,214.365,191.207,214.313z M323.769,353.105c-0.004,0-0.009,0-0.013,0
	c42.991,0,77.806-35.291,77.813-78.782c-0.018-15.69-4.521-30.311-12.292-42.587c0.012,0.015,0.024,0.02,0.036,0.035
	c-0.005-0.008-0.009-0.019-0.014-0.026c1.104,1.394,2.813,2.177,4.581,2.093c-1.763,0.087-3.462-0.693-4.567-2.075
	c7.756,12.268,12.25,26.889,12.269,42.561C401.574,317.818,366.766,353.105,323.769,353.105z"
    />
    <g>
      <path
        fill="#EE8484"
        d="M295.669,329.265c-1.029-0.001-2.041-0.273-2.93-0.791c-29.928-17.685-39.854-56.281-22.17-86.208
		c17.686-29.928,56.283-39.853,86.209-22.167c19.162,11.323,30.922,31.925,30.922,54.184c0,1.979-0.066,3.923-0.252,5.812
		c-0.227,3.087-2.914,5.406-6,5.18c-3.088-0.228-5.406-2.914-5.18-6.001c0.006-0.088,0.016-0.177,0.025-0.265
		c0.152-1.56,0.195-3.118,0.195-4.726c-0.002-28.571-23.164-51.73-51.734-51.729c-28.57,0.001-51.73,23.165-51.729,51.734
		c0.002,18.241,9.607,35.132,25.283,44.457c2.715,1.487,3.709,4.894,2.223,7.609C299.558,328.132,297.698,329.246,295.669,329.265
		L295.669,329.265z"
      />
      <path
        fill="#E4B134"
        d="M348.411,332.124c-3.096,0.027-5.627-2.458-5.654-5.554c-0.021-2.407,1.496-4.56,3.771-5.348
		c4.336-2.012,8.361-4.641,11.949-7.802c2.342-2.024,5.881-1.767,7.906,0.575c2.023,2.342,1.766,5.881-0.574,7.905l0,0
		c-4.535,3.977-9.637,7.26-15.135,9.741C349.962,331.958,349.19,332.122,348.411,332.124z"
      />
      <path
        fill="#3F6A32"
        d="M240.446,276.759c-3.096,0-5.605-2.51-5.605-5.604V154.979c0.002-1.334,0.479-2.623,1.346-3.637
		c36.768-42.971,80.791-49.49,130.871-19.375c1.131,0.68,1.988,1.739,2.414,2.988l31.191,91.357c1.063,2.908-0.434,6.126-3.34,7.188
		c-2.908,1.062-6.127-0.434-7.188-3.341c-0.027-0.072-0.053-0.147-0.078-0.221l-30.529-89.407
		c-43.918-25.692-81.078-20.289-113.479,16.545v114.08C246.052,274.25,243.542,276.759,240.446,276.759L240.446,276.759z"
      />
      <path
        fill="#3F6A32"
        d="M259.497,142.25c-3.096,0-5.605-2.509-5.605-5.604V74.052c0-1.212,0.395-2.392,1.123-3.362
		c13.164-19.868,39.945-25.302,59.813-12.135c0.736,0.489,1.459,0.999,2.164,1.532c0.934,0.678,1.639,1.627,2.018,2.718
		l20.133,58.29c1.008,2.927-0.547,6.118-3.473,7.126c-2.928,1.008-6.117-0.545-7.127-3.471l-19.516-56.441
		c-14.316-9.698-33.744-6.304-43.924,7.672v60.666C265.103,139.741,262.593,142.25,259.497,142.25z"
      />
      <path
        fill="#EE8484"
        d="M112.349,337.229c-34.762,0-62.941-28.18-62.941-62.941c0-34.761,28.18-62.941,62.941-62.941
		s62.941,28.181,62.941,62.941l0,0C175.253,309.035,147.095,337.192,112.349,337.229z M112.349,222.556
		c-28.57,0-51.73,23.161-51.73,51.731c0,28.571,23.16,51.731,51.73,51.731c28.572,0,51.732-23.16,51.732-51.731l0,0
		C164.05,245.73,140.907,222.587,112.349,222.556z"
      />
      <path
        fill="#3F6A32"
        d="M196.651,276.759c-3.096,0-5.605-2.51-5.605-5.604V157.075c-32.389-36.834-69.543-42.243-113.479-16.545
		l-30.523,89.43c-0.896,2.963-4.025,4.639-6.988,3.741c-2.961-0.896-4.637-4.025-3.74-6.988c0.035-0.117,0.074-0.235,0.119-0.352
		l31.189-91.358c0.428-1.25,1.283-2.307,2.416-2.987c50.072-30.108,94.109-23.603,130.871,19.376
		c0.867,1.013,1.344,2.304,1.346,3.636v116.127C202.257,274.25,199.745,276.759,196.651,276.759L196.651,276.759z"
      />
      <path
        fill="#3F6A32"
        d="M177.601,142.25c-3.096,0-5.605-2.509-5.605-5.604V75.963c-10.184-13.98-29.619-17.368-43.93-7.656
		l-19.5,56.496c-1.01,2.927-4.199,4.481-7.125,3.473c-2.928-1.01-4.48-4.2-3.473-7.126l20.133-58.29
		c0.379-1.091,1.084-2.04,2.018-2.717c19.01-14.378,46.076-10.625,60.455,8.384c0.537,0.71,1.053,1.438,1.545,2.18
		c0.727,0.971,1.121,2.15,1.121,3.363v62.577c0,3.095-2.508,5.604-5.604,5.604C177.622,142.25,177.61,142.25,177.601,142.25
		L177.601,142.25z"
      />
      <path
        fill="#3F6A32"
        d="M240.44,160.584c-1.16,0.003-2.291-0.357-3.234-1.031c-10.799-9.03-26.516-9.03-37.316,0
		c-2.471,1.864-5.984,1.371-7.85-1.1c-1.863-2.471-1.371-5.986,1.1-7.85c0.088-0.066,0.18-0.129,0.271-0.191
		c14.727-11.669,35.547-11.669,50.275,0c2.521,1.793,3.113,5.292,1.32,7.816C243.954,159.705,242.253,160.583,240.44,160.584
		L240.44,160.584z"
      />
      <path
        fill="#3F6A32"
        d="M196.646,219.916c-3.096,0.002-5.605-2.508-5.607-5.603c0-1.896,0.957-3.664,2.549-4.699
		c14.879-10.753,34.967-10.784,49.883-0.078c2.598,1.681,3.344,5.15,1.664,7.75c-1.682,2.6-5.152,3.346-7.752,1.666
		c-11.23-8.211-26.488-8.186-37.691,0.061C198.782,219.601,197.726,219.915,196.646,219.916L196.646,219.916z"
      />
      <path
        fill="#3F6A32"
        d="M324.747,364.239c-49.691,0.003-89.975-40.276-89.979-89.968s40.277-89.977,89.969-89.979
		c49.689-0.003,89.975,40.276,89.979,89.967c0,0.011,0,0.019,0,0.028C414.649,323.946,374.405,364.182,324.747,364.239
		L324.747,364.239z M324.747,195.507c-43.498-0.002-78.766,35.259-78.77,78.759c-0.002,43.499,35.26,78.766,78.76,78.769
		c43.496,0.003,78.76-35.252,78.768-78.747c-0.051-43.474-35.283-78.704-78.758-78.753V195.507z"
      />
      <path
        fill="#3F6A32"
        d="M112.349,364.239c-49.691,0.015-89.984-40.255-90-89.945s40.254-89.986,89.945-90.002
		c49.689-0.015,89.984,40.255,90,89.946c0,0.017,0,0.034,0,0.051C202.229,323.937,161.999,364.17,112.349,364.239L112.349,364.239z
		 M112.349,195.507c-43.5-0.016-78.775,35.236-78.791,78.736s35.236,78.776,78.736,78.792c43.5,0.015,78.775-35.236,78.791-78.735
		c0-0.004,0-0.008,0-0.012c-0.057-43.464-35.271-78.688-78.736-78.753V195.507z"
      />
    </g>
    <path
      fill="#3F6A32"
      d="M299.517,397.84c-1.278,0-2.559-0.488-3.534-1.464l-5.966-5.964l-5.965,5.964
	c-1.95,1.952-5.119,1.952-7.069,0c-1.953-1.953-1.953-5.119,0-7.071l9.5-9.5c1.95-1.951,5.118-1.951,7.069,0l9.5,9.5
	c1.953,1.953,1.953,5.119,0,7.071C302.077,397.352,300.798,397.84,299.517,397.84z"
    />
    <path
      fill="#3F6A32"
      d="M440.134,263.631c-3.713,0-7.426-1.413-10.252-4.239c-5.654-5.654-5.654-14.854,0-20.507
	c5.65-5.651,14.852-5.653,20.504,0c5.654,5.654,5.654,14.853,0,20.507C447.56,262.218,443.847,263.631,440.134,263.631z
	 M440.134,244.64c-1.152,0-2.305,0.438-3.184,1.316c-1.754,1.754-1.754,4.609,0,6.363c1.758,1.755,4.609,1.756,6.365,0
	c1.754-1.754,1.754-4.608,0-6.363C442.438,245.08,441.284,244.64,440.134,244.64z"
    />
    <path
      fill="#3F6A32"
      d="M414.775,60.276c-3.713,0-7.427-1.414-10.253-4.24c-5.654-5.654-5.654-14.854,0-20.506
	c5.652-5.653,14.854-5.653,20.506,0c5.654,5.653,5.654,14.853,0,20.506C422.202,58.863,418.488,60.276,414.775,60.276
	L414.775,60.276z M414.775,41.285c-1.151,0-2.306,0.438-3.183,1.315c-1.754,1.754-1.754,4.609,0,6.364
	c1.756,1.755,4.608,1.755,6.365,0c1.754-1.754,1.754-4.609,0-6.364C417.08,41.723,415.927,41.285,414.775,41.285z"
    />
    <path
      fill="#3F6A32"
      d="M375.121,46.033c-1.278,0-2.56-0.488-3.534-1.464l-5.966-5.965l-5.965,5.965
	c-1.951,1.951-5.119,1.951-7.069,0c-1.953-1.953-1.953-5.119,0-7.071l9.5-9.5c1.95-1.952,5.118-1.952,7.069,0l9.5,9.5
	c1.953,1.953,1.953,5.119,0,7.071C377.681,45.543,376.4,46.033,375.121,46.033z"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
