<template>
  <go-back-bar titulo="Registro" @go-back="restarPaso" />

  <div
    class="w-full h-full relative flex flex-col items-center max-w-lg mx-auto px-4 "
    style="margin:0 auto;"
  >
    <div
      v-if="pasoActivo === 1 || pasoActivo === 2 || pasoActivo === 4"
      class="mt-8"
    ></div>
    <header-registro
      v-if="pasoActivo === 1 || pasoActivo === 2 || pasoActivo === 4"
    />
    <!-- Paso 1 -->
    <form
      v-if="pasoActivo === 1"
      class="w-full z-10 mt-6"
      @submit.prevent="sumarPaso"
    >
      <div
        v-for="campo in camposPaso1"
        :key="campo.id"
        class="text-left w-full mb-6"
      >
        <label :for="campo.id" class="text-t-gris-1">{{ campo.titulo }}</label>
        <input
          :id="campo.id"
          :name="campo.id"
          :type="campo.type"
          :autocomplete="campo.autocomplete"
          :required="campo.required"
          v-model="campo.value"
          :class="[
            'appearance-none rounded-full relative block w-full px-3 py-2 text-gray-900 shadow-md border border-gris-2 focus:outline-none focus:ring-verde-1 focus:border-verde-1 focus:z-10 sm:text-sm'
          ]"
        />
      </div>

      <button
        class="w-full rounded-full py-2 mt-10 mb-4 bg-verde-1 text-white text-center text-xl apparance-none focus:outline-none z-10"
        type="submit"
      >
        Siguiente
      </button>
    </form>

    <!-- Paso 2 -->
    <form
      v-if="pasoActivo === 2"
      class="w-full z-10 h-full relative mt-10"
      @submit.prevent="sumarPaso"
    >
      <div class="flex flex-col w-full">
        <div class="w-full md:flex-row flex-col flex text-left">
          <div class="w-full">
            <label for="telefono" class="text-negro-1 font-bold"
              >Teléfono de contacto
            </label>
            <div class="flex">
              <span
                class="inline-flex items-center px-3 shadow-md rounded-l-full border border-gray-300 bg-gray-50 text-gray-500 text-sm"
              >
                +54
              </span>
              <input
                id="telefono"
                name="telefono"
                type="number"
                autocomplete="true"
                required="true"
                v-model="camposPaso2.telefono"
                :class="[
                  'appearance-none rounded-r-full shadow-md relative block w-full px-3 py-2 border border-l-0 border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-bordo-1 focus:border-bordo-1 focus:z-10 sm:text-sm'
                ]"
              />
            </div>
          </div>
          <div class="w-full text-left md:my-0 my-5">
            <label for="rubro" class="text-negro-1 font-bold">
              Rubro
            </label>
            <div>
              <select
                name="rubros"
                id="rubros"
                v-model="camposPaso2.rubro"
                class="border rounded-full px-1 shadow-md h-10 w-full"
              >
                <option
                  v-for="rubro in rubros"
                  :value="rubro.id"
                  :key="rubro.id"
                >
                  {{ rubro.name }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <!-- Horario -->
        <div class="w-full text-left mt-4">
          <label for="direccion" class="text-negro-1 font-bold">
            Horario para retiro de canastas sorpresa
          </label>

          <div class="flex gap-8 mt-2">
            <div class="flex flex-col">
              <label for="desde" class="text-sm">Desde</label>
              <time-picker
                id="desde"
                :horario="camposPaso2.desde"
                @actualizar="camposPaso2.desde = $event"
              ></time-picker>
            </div>
            <div class="flex flex-col">
              <label for="hasta" class="text-sm">Hasta</label>
              <time-picker
                id="hasta"
                :horario="camposPaso2.hasta"
                @actualizar="camposPaso2.hasta = $event"
              ></time-picker>
            </div>
          </div>
        </div>

        <button
          class="w-full rounded-full py-2 mt-10 mb-4 bg-verde-1 text-white text-center text-xl apparance-none focus:outline-none z-10"
          type="submit"
        >
          Siguiente
        </button>
      </div>
    </form>

    <div
      v-if="pasoActivo === 3"
      class="w-full text-left"
      @submit.prevent="sumarPaso"
    >
      <!-- Mapa -->
      <form class="w-full text-left mt-4 mb-14 relative">
        <label for="direccion" class="text-negro-1 font-bold">
          Dirección de la tienda
        </label>

        <div class="w-full relative mb-4">
          <icon-search class="w-6 h-6 absolute top-2 left-2" />
          <input-direccion
            @changePlace="changePlace"
            class="w-full rounded-full shadow-md py-2 border pl-10 max-w-full"
            style="z-index:9"
            required
          ></input-direccion>
        </div>
        <div style="height:50vh" class="">
          <mapa-google-maps
            :tooltip="false"
            :ubicacion="false"
            :markers="[camposPaso2]"
          ></mapa-google-maps>
        </div>

        <button
          class="w-full rounded-full py-2 mt-10 mb-4 bg-verde-1 text-white text-center text-xl apparance-none focus:outline-none z-10"
          type="submit"
        >
          Siguiente
        </button>
      </form>
    </div>

    <div v-if="pasoActivo === 4" class="w-full text-left z-10 mt-6">
      <span class="text-t-gris-1 text-sm">
        Estos son tus datos.
        <br />
        Tené en cuenta que nos vamos a comunicar telefónicamente para poder
        finalizar el registro.
      </span>

      <div class="text-sm mt-8">
        <div
          v-for="(campo, index) in camposConfirmar"
          :key="campo.key"
          :class="[
            { 'border-t-2': index === 0 },
            'px-2 py-1 flex border-2 border-gris-2 border-t-0 text-negro-1 bg-white relative'
          ]"
        >
          <span class="font-semibold mr-1">{{ campo.titulo }}: </span>
          {{ campo.texto ? campo.texto() : usuario[campo.key] }}
        </div>
      </div>

      <button
        class="w-full rounded-full py-2 mt-10 mb-4 bg-verde-1 text-white text-center text-xl apparance-none focus:outline-none z-10"
        @click="registroFinalizado"
      >
        Siguiente
      </button>
    </div>
  </div>

  <div
    v-if="pasoActivo === 5"
    class="w-full absolute max-w-lg z-50 h-full top-0 left-0 bg-white flex flex-col"
  >
    <div class="w-2/3 mt-5 mx-auto">
      <span class="text-4xl text-verde-1 font-bold">
        ¡Ya podés aprovechar la comida!
      </span>
    </div>

    <div class="my-12 mx-auto">
      <svg-bienvenido />
    </div>
    <div class="text-verde-1 font-bold">
      Provecho te da la bienvenida
    </div>
    <div class="w-2/3 mx-auto mt-2">
      <button-verde-rounded @click="goToLogin">
        Iniciar sesión
      </button-verde-rounded>
    </div>
  </div>
</template>

<script>
import router from "@/router/router.js";
import { ref, computed, onMounted } from "vue";
import { apiRegistroLocal } from "@/services/routes.js";
import InputDireccion from "@/components/Mapa/InputDireccion";
import MapaGoogleMaps from "@/components/Mapa/MapaGoogleMap";
import TimePicker from "@/components/elementos/TimePicker.vue";
import store from "@/services/store";

import HeaderRegistro from "./HeaderRegistro.vue";
import GoBackBar from "@/components/elementos/GoBack.vue";
import IconSearch from "../Icons/iconSearch.vue";
import SvgBienvenido from "../Icons/svgBienvenido.vue";
import ButtonVerdeRounded from "../elementos/ButtonVerdeRounded.vue";

export default {
  components: {
    InputDireccion,
    MapaGoogleMaps,
    TimePicker,
    HeaderRegistro,
    GoBackBar,
    IconSearch,
    SvgBienvenido,
    ButtonVerdeRounded
  },
  setup() {
    let pasoActivo = ref(1);
    const camposPaso1 = ref([
      {
        titulo: "Nombre de la tienda",
        placeholder: "Nombre",
        value: "",
        autocomplete: true,
        required: true,
        type: "text",
        id: "nombre"
      },
      {
        titulo: "Email",
        placeholder: "Email",
        value: "",
        autocomplete: "email",
        required: true,
        type: "email",
        id: "email"
      },
      {
        titulo: "Contraseña",
        placeholder: "Contraseña",
        value: "",
        autocomplete: false,
        required: true,
        type: "password",
        id: "password"
      },
      {
        titulo: "Repita su contraseña",
        placeholder: "Repita su contraseña",
        value: "",
        autocomplete: false,
        required: true,
        type: "password",
        id: "passwordRep"
      }
    ]);

    const camposPaso2 = ref({
      telefono: null,
      rubro: 1,
      direccion: null,
      desde: "12:00",
      hasta: "17:30",
      latitude: null,
      longitude: null
    });

    const camposConfirmar = ref([
      {
        titulo: "Tienda",
        key: "nombre"
      },
      {
        titulo: "e-mail",
        key: "email"
      },
      {
        titulo: "Teléfono de contacto",
        key: "telefono"
      },
      {
        titulo: "Rubro",
        key: "rubro"
      },
      {
        titulo: "Horario disponible de retiro",
        key: "horario",
        texto: () => {
          return `de ${usuario.value.desde}hs a ${usuario.value.hasta}hs`;
        }
      },
      {
        titulo: "Dirección de la tienda",
        key: "direccion"
      }
    ]);

    onMounted(() => {
      store.dispatch("fetchRubros");
    });

    const sumarPaso = () => {
      pasoActivo.value++;
    };
    const restarPaso = () => {
      if (pasoActivo.value - 1 === 0) {
        router.push({ path: "registro" });
      } else {
        pasoActivo.value--;
      }
    };

    const changePlace = place => {
      camposPaso2.value = {
        ...camposPaso2.value,
        direccion: place.direccion,
        latitude: place.latitude,
        longitude: place.longitude
      };
    };

    const usuario = computed(() => {
      return {
        nombre: camposPaso1.value.find(c => c.id === "nombre").value,
        email: camposPaso1.value.find(c => c.id === "email").value,
        telefono: camposPaso2.value["telefono"],
        rubro: rubros.value.find(r => r.id === camposPaso2.value["rubro"]).name,
        direccion: camposPaso2.value["direccion"],
        latitude: camposPaso2.value["latitude"],
        longitude: camposPaso2.value["longitude"],
        desde: camposPaso2.value["desde"],
        hasta: camposPaso2.value["hasta"]
      };
    });

    const registroFinalizado = () => {
      fetch(apiRegistroLocal(), {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8"
        },
        body: JSON.stringify({
          name: usuario.value["nombre"],
          description: "Descripción de ejemplo",
          image:
            "https://statics-diariomedico.uecdn.es/cms/2019/06/verduras.jpg",
          email: usuario.value["email"],
          password: camposPaso1.value.find(c => c.id === "password").value,
          latitude: 0,
          longitude: 0,
          district_id: null,
          category_id: rubros.value.find(r => r.name === usuario.value["rubro"])
            .id,
          address: usuario.value["direccion"],
          number: usuario.value["telefono"],
          open: usuario.value["desde"].replace(":", ""),
          close: usuario.value["hasta"].replace(":", "")
        })
      })
        .then(res => res.json())
        .then(() => {
          sumarPaso();
        });
    };

    const goToLogin = () => {
      router.push({ path: "login" });
    };
    const rubros = computed(() => {
      return store.getters.getRubros;
    });

    return {
      camposPaso1,
      camposPaso2,
      pasoActivo,
      sumarPaso,
      restarPaso,
      changePlace,
      usuario,
      registroFinalizado,
      rubros,
      camposConfirmar,
      goToLogin
    };
  }
};
</script>

<style></style>
