<template>
  <div
    class="max-w-xl bg-white w-full rounded-md shadow-sm w-48 border p-6"
    style="margin: 0 auto;"
  >
    <div class=" text-left ">
      Contacto
    </div>

    <div>
      <select
        id="consulta"
        name="consulta"
        class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
      >
        <option selected disabled>Motivo de tu consulta</option>
      </select>

      <textarea
        rows="16"
        class="mt-4 h-26 border w-full border-gray-300 bg-white rounded-md shadow-sm"
      ></textarea>

      <div class="flex justify-end">
        <div class="bg-blue-300 py-2 px-6 rounded text-white">Enviar</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
