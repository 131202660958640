<template>
  <div class="flex pb-1 select-none h-6 w-full relative justify-center">
    <div
      class="border font-semibold rounded-full w-6 h-6 border-gray-400 cursor-pointer flex items-center justify-center absolute left-0"
      @click="minus"
    >
      -
    </div>
    <input
      type="text"
      class="text-center font-3xl font-bold focus:outline-none text-center"
      :value="valor"
      @input="event => $emit('update:valor', parseInt(event.target.value))"
    />
    <div
      class="border font-semibold rounded-full w-6 h-6 border-gray-400 cursor-pointer flex items-center justify-center absolute right-0"
      @click="plus"
    >
      <span>+</span>
    </div>
  </div>
</template>

<script>
import { toRefs } from "vue";
export default {
  props: {
    valor: {
      type: Number,
      required: true
    },
    max: {
      type: Number,
      required: false
    },
    min: {
      type: Number,
      required: false
    }
  },
  setup(props, { emit }) {
    let val = toRefs(props).valor;
    let max = toRefs(props).max;
    let min = toRefs(props).min;
    //Todo chequeo con keyup
    const plus = () => {
      if (max && val.value < max.value) {
        emit("update:valor", val.value + 1);
      } else if (!max) {
        emit("update:valor", val.value + 1);
      }
    };
    const minus = () => {
      if (val.value > 1) {
        if (min && val.value > min.value) {
          emit("update:valor", val.value - 1);
        } else if (!min) {
          emit("update:valor", val.value - 1);
        }
      }
    };
    return { plus, minus };
  }
};
</script>

<style></style>
