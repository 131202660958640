<template>
  <go-back
    :titulo="tituloGoBack"
    :arrow="opcionSeleccionada !== null"
    @go-back="refreshHoy"
  ></go-back>

  <div class="flex justify-center w-100">
    <div
      v-if="!opcionSeleccionada"
      class="grid grid-cols-1 md:grid-cols-2 gap-4 w-full px-4 md:px-0 md:w-9/12 pt-5 "
    >
      <div
        v-for="opcion in opciones"
        :key="opcion.id"
        class="bg-white border rounded-md shadow-md hover:shadow-xl p-4 text-left cursor-pointer flex"
        @click="opcionSeleccionada = opcion.id"
      >
        <div class="w-14 mx-3 flex justify-center">
          <component
            :is="opcion.icono"
            class="w-16 h-auto text-gris-2"
          ></component>
        </div>

        <div class="pl-4 text-t-gris-2 flex flex-col">
          <span class="text-2xl text-negro-1">{{ opcion.nombre }}</span>
          <span>{{ opcion.texto }}</span>
          <span v-if="opcion.id === 'stock'">
            Stock:
            <b>{{
              datosHoy && datosHoy.qty
                ? datosHoy.qty - (datosHoy.sold ? datosHoy.sold : 0)
                : 0
            }}</b>
          </span>
          <span v-if="opcion.id === 'ventas'">
            Ventas de hoy:
            <b>{{ datosHoy && datosHoy.sold ? datosHoy.sold : 0 }}</b>
          </span>
        </div>
      </div>
    </div>

    <div class="mt-4 w-full" v-if="opcionSeleccionada">
      <publicar
        v-if="opcionSeleccionada === 'stock'"
        @go-back="refreshHoy"
      ></publicar>

      <ventas v-if="opcionSeleccionada === 'ventas'"></ventas>

      <contacto v-if="opcionSeleccionada === 'contacto'"></contacto>

      <tienda v-if="opcionSeleccionada === 'tienda'" :local="store"></tienda>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from "vue";
import Publicar from "../components/HomeLocal/Publicar";
import Ventas from "../components/HomeLocal/Ventas";
import Contacto from "../components/HomeLocal/Contacto.vue";
import Tienda from "../components/HomeLocal/Tienda.vue";

import iconCart from "../components/Icons/iconCart.vue";
import iconScale from "../components/Icons/iconScale.vue";
import iconMail from "../components/Icons/iconMail.vue";
import iconConfig from "../components/Icons/iconConfig.vue";
import GoBack from "../components/elementos/GoBack.vue";

import { apiGetLocalBasket } from "@/services/routes.js";

export default {
  components: {
    Publicar,
    Ventas,
    Contacto,
    Tienda,
    iconCart,
    iconScale,
    iconMail,
    iconConfig,
    GoBack
  },
  setup() {
    let datosHoy = ref({});
    let store = ref({});

    let opcionSeleccionada = ref(null);
    let opciones = ref([
      {
        id: "stock",
        nombre: "Stock y precio",
        texto: `Gestioná tus productos del día.`,
        icono: "icon-scale"
      },
      {
        id: "ventas",
        nombre: "Ventas",
        texto: `Mirá en tiempo real tus ventas.`,
        icono: "icon-cart"
      },
      {
        id: "tienda",
        nombre: "Configuración",
        texto: `Completá toda la información de tu tienda.`,
        icono: "icon-config"
      }
    ]);

    // {
    //   id: "contacto",
    //   nombre: "Contactanos",
    //   texto: `Escribinos tus dudas, sugerencias o reclamos.`,
    //   icono: "icon-mail"
    // }

    onMounted(() => {
      fetchLocalBasket();
    });

    const fetchLocalBasket = async () => {
      let res = await fetch(apiGetLocalBasket(1));
      res = await res.json();
      datosHoy.value = res.baskets[0];
      store.value = res.store;
      console.log(store.value);
    };

    const refreshHoy = () => {
      fetchLocalBasket();
      opcionSeleccionada.value = null;
    };

    const tituloGoBack = computed(() => {
      if (opcionSeleccionada.value) {
        return opciones.value.find(o => o.id === opcionSeleccionada.value)
          .nombre;
      } else {
        return "Provecho tiendas";
      }
    });
    return {
      opciones,
      opcionSeleccionada,
      datosHoy,
      refreshHoy,
      tituloGoBack,
      store
    };
  }
};
</script>

<style></style>
