<template>
  <div class="absolute top-0 left-0 z-10" style="height: 91vh; width: 100%;">
    <div style="height: 100%" class="relative">
      <mapa
        :get-icon="getIconUrl"
        :markers="locales"
        :ubicacion="false"
        :tooltip="false"
        @marker-clickeado="localClickeado"
      />
    </div>
  </div>
</template>

<script>
import router from "@/router/router.js";
import Mapa from "../Mapa/MapaGoogleMap.vue";

export default {
  props: {
    locales: {
      type: Array,
      required: true
    }
  },
  components: {
    Mapa
  },
  setup() {
    const getIconUrl = local => {
      if (local.baskets_qty > 0) {
        return "negocio1.png";
      } else {
        return "negocio2.png";
      }
    };

    const localClickeado = id => {
      router.push({ name: "local", params: { id: id } });
    };

    return { getIconUrl, localClickeado };
  }
};
</script>
