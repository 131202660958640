<template>
  <div class="bg-beige-1 w-full h-auto">
    <div
      class="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between"
    >
      <div class="flex justify-center items-center flex-col md:flex-row w-full">
        <div class="text-bordo-1 text-left order-2 md:order-1">
          <div class="text-4xl">
            Tu compromiso <br />
            no tiene desperdicio.
          </div>
          <div class="font-bold mt-3">
            En Argentina una persona desperdicia <br />
            aproximadamente 38 kilos de comida por año.
          </div>
          <div class="font-medium">
            Con provecho queremos ayudar a reducir este <br />
            desperdicio y formentar un consumo más <br />
            responsable y sustentable
          </div>
          <div
            class="bg-verde-1 text-white rounded-full shadow-md px-3 py-2 w-36 text-center mt-6"
          >
            Ingresá
          </div>
        </div>
        <div class="order-1 md:order-2">
          <img class="w-auto md:h-80 h-48" src="img/home/home-1.png" alt="" />
        </div>
      </div>
    </div>
  </div>
  <div class="bg-gris-1 w-full h-auto">
    <div
      class="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between"
    >
      <div class="flex justify-center w-full">
        <div class="text-bordo-1 text-center">
          <div class="text-4xl font-bold mb-8">
            ¿Qué hacemos?
          </div>

          <div class="font-medium mb-8">
            <span class="font-bold">
              #lacomidanosetira
            </span>
            El excedente del día de tu comercio cercano puede transformarse en
            <br />
            una caja sorpresa llena de alimentos deliciosos que podes salvar y
            disfrutar a un precio increíble.
          </div>
          <div class="flex justify-center">
            <img class="w-auto h-80" src="img/home/home-2.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {}
};
</script>
