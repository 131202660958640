<template>
  <div v-if="!usuarioLogueado && !pestaniaFiltros">
    <boton-deslogueado />
  </div>

  <div
    v-if="locales"
    v-show="!pestaniaFiltros"
    class="home flex flex-col items-center justify-center pt-4 relative"
  >
    <!-- Botonera visualización -->
    <!-- Todo pasar a componente   -->
    <div class="grid grid-cols-2 w-3/4 z-20">
      <div
        v-for="(opcion, index) in opciones"
        :key="opcion.id"
        @click="opcionSeleccionada = opcion.id"
        :class="[
          {
            'text-white bg-verde-1': opcionSeleccionada === opcion.id,
            'rounded-l-full': index === 0,
            'rounded-r-full': index === opciones.length - 1
          },
          'text-center text-black cursor-pointer border-2 border-verde-1 bg-white transition duration-200'
        ]"
      >
        <div>{{ opcion.nombre }}</div>
      </div>
    </div>

    <!-- Busqueda -->
    <div class="flex flex-row p-2 mt-2 w-full items-center md:w-9/12 z-20">
      <div
        :class="[
          { 'w-full': busquedaActiva },
          'flex relative rounded shadow-md border bg-white p-1 mr-2'
        ]"
      >
        <div class="w-6 h-6">
          <icon-search
            @click="busquedaActiva = !busquedaActiva"
            class="w-6 h-6 text-t-gris-1"
          ></icon-search>
        </div>
        <input
          v-if="busquedaActiva"
          type="text"
          name="search"
          id="search"
          v-model="busqueda"
          class="focus:outline-none block pr-2 pl-2 w-full sm:text-sm border-gray-300 text-t-gris-1 font-sans rounded-md"
          placeholder="Buscá las tiendas que desees"
        />
      </div>

      <div class="flex flex-grow justify-end">
        <div class="bg-white shadow-md rounded border p-1">
          <icon-filtros
            @click="pestaniaFiltros = true"
            class="w-6 h-6 text-t-gris-1"
          ></icon-filtros>
        </div>
      </div>
    </div>

    <div class="flex flex-col md:flex-row p-2 pb-8 w-full md:w-9/12">
      <div class="w-full md:ml-4">
        <mapa
          v-if="opcionSeleccionada === 'mapa'"
          :locales="localesFiltrados"
        ></mapa>
        <listado
          v-if="opcionSeleccionada === 'listado'"
          :locales="localesFiltrados"
          @toggleFavorito="toggleFavorito"
        ></listado>
      </div>
    </div>

    <div
      v-if="tickets.length > 0"
      class="fixed bottom-14 w-full h-14 bg-verde-1 text-white flex z-50"
      @click="goToPedidos"
    >
      <div class="text-left ml-8 flex flex-col">
        <span class="font-bold">Tu pedido</span>
        <span>Tocá acá para ver tu pedido.</span>
      </div>
    </div>

    <!-- Popup compraActiva -->
    <template v-if="compraActiva === 'true'">
      <closer
        class="bg-black opacity-60 z-40"
        @click="compraActiva = false"
      ></closer>
      <div
        class="w-3/4 bg-white rounded-xl px-2 py-2 fixed bottom-36 z-50 text-left"
      >
        <div class="grid grid-cols-6">
          <div class="w-16 col-span-1"></div>
          <span class="font-bold col-span-5">¿Y ahora...?</span>
        </div>
        <div class="grid grid-cols-6 my-1">
          <div class="col-span-1 flex justify-center items-center">
            <icon-regalo class="w-6 h-6 text-rojo-1"></icon-regalo>
          </div>
          <span class="w-auto text-xs col-span-5"
            >Tu canasta sorpresa te va a estar esperando en la tienda en el
            horario de retiro indicado.
          </span>
        </div>
        <div class="grid grid-cols-6 my-1">
          <div class="col-span-1 flex justify-center items-center">
            <icon-time class="w-6 h-6 text-rojo-1"></icon-time>
          </div>
          <span class="w-auto text-xs col-span-5">
            Presta atención a la hora. Tendrás que recoger tu pack sorpresa
            dentro del horario de recogida.
          </span>
        </div>
      </div>
      <div class="arrow-down z-50 fixed bottom-32"></div>
    </template>

    <!-- No hay resultados -->
    <div
      v-if="localesFiltrados.length === 0"
      class="flex flex-col items-center justify-center"
    >
      <icon-binoculares class="w-1/2" />
      <div class="mt-4 flex flex-col w-2/3">
        <span class="font-bold">
          No hay tiendas que <br />coincidan con tus filtros
        </span>
        <span class="text-gris-1">
          Intentá limpiar algunos de tus filtros para obtener más resultados
        </span>
        <div
          class="bg-verde-1 rounded-full w-full py-2 text-white mt-3"
          @click="limpiarFiltros"
        >
          Limpiar filtros
        </div>
      </div>
    </div>
  </div>

  <!-- Filtros -->
  <div v-if="pestaniaFiltros">
    <go-back-bar :titulo="'Filtros'" @go-back="pestaniaFiltros = false" />

    <div class="mt-3 px-2">
      <div class="flex">
        <div class="flex flex-col text-left">
          <span class="font-bold">Ocultar sin packs</span>
          <span class="text-xs">
            La mayoría de los establecimientos tienen una cantidad limitada de
            packs sorpresa a la venta cada día. Si activas este filtro, solo te
            mostraremos establecimientos que aún disponen de comida para salvar.
          </span>
        </div>
        <div class="mx-4 flex items-center">
          <input
            type="checkbox"
            name=""
            id=""
            v-model="filtros['ocultarSinPacks']"
          />
        </div>
      </div>

      <div class="border-t border-gris-2 w-full h-1 mt-4 mb-2"></div>
      <div class="flex flex-col text-left">
        <span class="font-bold">Ordenar por</span>
        <div class="flex">
          <div
            v-for="crit in orden.opciones"
            :key="crit.id"
            :class="[
              {
                'bg-verde-1 text-white': filtros.ordenarPor === crit.id,
                'bg-white text-gris-1': filtros.ordenarPor !== crit.id
              },
              'w-auto border-2 border-gris-1 rounded px-2 mr-2'
            ]"
            @click="filtros.ordenarPor = crit.id"
          >
            {{ crit.nombre }}
          </div>
        </div>
      </div>

      <div class="border-t border-gris-2 w-full h-1 mt-4 mb-2"></div>
      <div class="flex flex-col text-left">
        <span class="font-bold">Categoría</span>
        <div class="flex flex-col">
          <div
            v-for="categoria in categorias.opciones"
            :key="categoria.id"
            :class="[
              {
                'text-verde-1 font-bold': filtros.categoria === categoria.id,
                'text-gris-1': filtros.categoria !== categoria.id
              },
              'w-auto'
            ]"
            @click="filtros.categoria = categoria.id"
          >
            {{ categoria.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from "vue";
import {
  apiGetListaLocales,
  apiGetRubros,
  apiGetClientSales
} from "@/services/routes.js";
import router from "@/router/router.js";
import store from "@/services/store";
import _ from "lodash";

import Mapa from "@/components/Locales/MapaTab.vue";
import Listado from "@/components/Locales/Listado.vue";
import IconSearch from "../components/Icons/iconSearch.vue";

import Closer from "../components/Closer.vue";
import IconTime from "../components/Icons/iconTime.vue";
import IconRegalo from "../components/Icons/iconRegalo.vue";
import BotonDeslogueado from "../components/elementos/BotonDeslogueado.vue";
import IconFiltros from "../components/Icons/iconFiltros.vue";
import GoBackBar from "../components/elementos/GoBack.vue";
import IconBinoculares from "../components/Icons/iconBinoculares.vue";

export default {
  name: "Locales",
  components: {
    Mapa,
    Listado,
    IconSearch,
    Closer,
    IconTime,
    IconRegalo,
    BotonDeslogueado,
    IconFiltros,
    GoBackBar,
    IconBinoculares
  },
  props: {
    compra: {
      type: String,
      default: "false",
      required: false
    }
  },
  setup(props) {
    let usuarioLogueado = store.state.usuario.logueado;
    let auth = store.state.usuario.auth;
    let idUsuario = store.state.usuario.id;

    let compraActiva = ref(props.compra);
    let locales = ref([]);
    let rubros = ref([]);
    let tickets = ref([]);
    let busquedaActiva = ref(false);
    let busqueda = ref(null);
    let pestaniaFiltros = ref(false);

    let orden = ref({
      opciones: [
        { id: "baskets_disponibles", nombre: "Cantidad", order: "desc" },
        { id: "name", nombre: "Nombre", order: "asc" },
        { id: "sold", nombre: "Ventas", order: "desc" }
      ]
    });
    let categorias = ref({
      opciones: []
    });

    let filtros = ref({
      ocultarSinPacks: false,
      ordenarPor: "baskets_disponibles",
      categoria: "todo"
    });

    const opciones = [
      { id: "listado", nombre: "Lista", icon: "icon-list" },
      { id: "mapa", nombre: "Mapa", icon: "icon-map" }
    ];
    let opcionSeleccionada = ref(opciones[0].id);

    onMounted(async () => {
      let pLocales = fetchLocales();
      let pRubros = fetchRubros();
      let pTickets = fetchTickets();

      Promise.all([pLocales, pRubros, pTickets]).then(() => {
        locales.value = locales.value.map(l => {
          return {
            ...l,
            rubroName: rubros.value.find(r => r.id == l.category_id).name
          };
        });
        tickets.value = tickets.value.map(t => {
          return {
            ...t,
            local: locales.value.find(l => l.id === t._basket.store_id)
          };
        });
      });
    });

    const fetchLocales = async () => {
      let res = await fetch(apiGetListaLocales(), {
        headers: {
          Authorization: auth,
          "Content-Type": "application/json"
        }
      });
      res = await res.json();
      if (res.detail) {
        goToLogin();
      } else {
        res = res.map(l => {
          return {
            ...l,
            baskets_disponibles: l.baskets_qty ? l.baskets_qty - l.sold : 0
          };
        });
        locales.value = res;
      }
    };

    const fetchRubros = async () => {
      let res = await fetch(apiGetRubros());
      res = await res.json();
      rubros.value = res;
      categorias.value.opciones = [
        { id: "todo", name: "Mostrar todo" },
        ...res
      ];
    };

    const fetchTickets = async () => {
      let res = await fetch(apiGetClientSales(idUsuario));
      res = await res.json();
      res.forEach(ticket => {
        tickets.value.push(ticket);
      });
      tickets.value = tickets.value.filter(t => t.state === "OPEN");
    };

    // Locales que correspondan a Filtros
    const localesFiltrados = computed(() => {
      let arrLocalesFiltrados = locales.value;
      if (filtros.value.ocultarSinPacks) {
        arrLocalesFiltrados = arrLocalesFiltrados.filter(
          l => l.baskets_disponibles > 0
        );
      } else if (filtros.value.categoria !== "todo") {
        arrLocalesFiltrados = arrLocalesFiltrados.filter(
          l => l.category_id === filtros.value.categoria
        );
      } else if (arrLocalesFiltrados) {
        let ordenPor = filtros.value.ordenarPor;
        return _.orderBy(
          arrLocalesFiltrados,
          ordenPor,
          orden.value.opciones.find(o => o.id === ordenPor).order
        );
      }

      return arrLocalesFiltrados;
    });

    const limpiarFiltros = () => {
      filtros.value = {
        ocultarSinPacks: false,
        ordenarPor: "baskets_disponibles",
        categoria: "todo"
      };
    };

    const goToTicket = id => {
      router.push({ name: "Ticket", params: { id: id } });
    };

    const goToPedidos = () => {
      router.push({ name: "mas", params: { activa: "pedidos" } });
    };

    const goToLogin = () => {
      router.push({ name: "Login" });
    };

    const toggleFavorito = (id, add) => {
      locales.value.find(l => l.id === id).favorite = add;
    };

    return {
      locales,
      localesFiltrados,
      opciones,
      opcionSeleccionada,
      goToTicket,
      rubros,
      tickets,
      toggleFavorito,
      compraActiva,
      usuarioLogueado,
      goToPedidos,
      busquedaActiva,
      busqueda,
      pestaniaFiltros,
      filtros,
      orden,
      categorias,
      limpiarFiltros
    };
  }
};
</script>

<style scoped>
::-webkit-input-placeholder {
  font-style: italic;
}
:-moz-placeholder {
  font-style: italic;
}
::-moz-placeholder {
  font-style: italic;
}
:-ms-input-placeholder {
  font-style: italic;
}

.arrow-down {
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;

  border-top: 20px solid white;
}
</style>
