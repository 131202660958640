<template>
  <div class="p-2">
    <!-- todo pasa a componente -->
    <div class="grid grid-cols-2 w-3/4 z-20 mx-auto mb-2">
      <div
        v-for="(opcion, index) in opciones"
        :key="opcion.id"
        @click="opcionSeleccionada = opcion.id"
        :class="[
          {
            'text-white bg-verde-1': opcionSeleccionada === opcion.id,
            'rounded-l-full': index === 0,
            'rounded-r-full': index === opciones.length - 1
          },
          'text-center text-black cursor-pointer border-2 border-verde-1 bg-white transition duration-200'
        ]"
      >
        <div>{{ opcion.nombre }}</div>
      </div>
    </div>

    <div
      v-for="sale in salesComputed"
      :key="sale.id"
      @click="goToTicket(sale.id)"
      class="w-full bg-white mb-1 shadow-md rounded relative flex"
    >
      <div class="flex items-center justify-center">
        <div class="w-16 h-full rounded-full">
          <img
            v-if="sale.local && sale.local.image"
            class="object-cover	w-full"
            :src="sale.local.image"
            alt=""
          />
          <img v-else class="h-full w-full" src="default-profile.png" alt="" />
        </div>
      </div>
      <div
        class="flex flex-col text-left text-xs ml-3 py-2 px-3"
        v-if="sale.local"
      >
        <!-- TODO -->
        <span class="font-bold">
          {{ sale.local.name }}
        </span>
        <span>Ticket: {{ sale.id }}</span>
        <span class="text-gris-1">
          Comprado el
          {{
            new Date(sale.date).toLocaleDateString("es-AR", { timeZone: "UTC" })
          }}
        </span>
      </div>
      <div class="flex flex-grow justify-end items-center">
        <icon-chevron-right />
      </div>
    </div>

    <div
      v-if="salesComputed.length === 0 && opcionSeleccionada === 'OPEN'"
      class="flex flex-col items-center justify-center mt-8"
    >
      <icon-vacio class="w-3/5 mx-auto max-w-sm mb-5" />
      <span class="font-bold">No tenés pedidos</span>
      <span class="text-sm"
        >Reservá alguna canasta sorpresa para tener pedidos.</span
      >

      <div
        class="py-1 w-2/3 max-w-lg rounded-full text-verde-1 border-2 text-lg border-verde-1 font-bold mt-8"
        @click="goToLocales"
      >
        Encontrá tiendas
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router/router.js";
import { computed, onMounted, ref } from "vue";
import { apiGetClientSales, apiGetListaLocales } from "@/services/routes.js";
import IconChevronRight from "../Icons/Mas/iconChevronRight.vue";
import IconVacio from "../Icons/iconVacio.vue";
import store from "@/services/store";

export default {
  components: {
    IconChevronRight,
    IconVacio
  },
  setup() {
    let sales = ref([]);
    let locales = ref([]);
    let idUsuario = store.state.usuario.id;
    let auth = store.state.usuario.auth;

    const opciones = [
      { id: "OPEN", nombre: "Actuales" },
      { id: "DONE", nombre: "Anteriores" }
    ];
    let opcionSeleccionada = ref(opciones[0].id);

    onMounted(async () => {
      let pSales = fetchSales();
      let pLocales = fetchLocales();

      Promise.all([pSales, pLocales]).then(() => {
        sales.value = sales.value.map(s => {
          return {
            ...s,
            local: locales.value.find(l => l.id == s._basket.store_id)
          };
        });
      });
    });
    const fetchSales = async () => {
      let res = await fetch(apiGetClientSales(idUsuario));
      res = await res.json();
      sales.value = res;
    };

    const fetchLocales = async () => {
      let res = await fetch(apiGetListaLocales(), {
        headers: {
          Authorization: auth,
          "Content-Type": "application/json"
        }
      });
      res = await res.json();
      if (res.detail) {
        goToLogin();
      } else {
        res = res.map(l => {
          return {
            ...l,
            baskets_disponibles: l.baskets_qty ? l.baskets_qty - l.sold : 0
          };
        });
        locales.value = res;
      }
    };

    const goToTicket = id => {
      router.push({ name: "Ticket", params: { id: id } });
    };

    let goToLocales = () => {
      router.push({ name: "Locales" });
    };

    const goToLogin = () => {
      router.push({ name: "Login" });
    };

    let salesComputed = computed(() => {
      return sales.value.filter(s => s.state === opcionSeleccionada.value);
    });

    return {
      sales,
      goToTicket,
      goToLocales,
      opciones,
      opcionSeleccionada,
      salesComputed
    };
  }
};
</script>

<style></style>
