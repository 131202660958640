<template>
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 529.233 431.395"
    enable-background="new 0 0 529.233 431.395"
    xml:space="preserve"
  >
    <path
      fill="#E4B134"
      d="M222.372,64.225c-2.601,1.73-5.203,3.47-7.769,5.238c-6.021,4.124-12.23,8.521-18.475,13.064
	C124.863,134.408,67.332,194.891,29.776,257.406c-1.927,3.189-3.791,6.441-5.558,9.604c-1.725,3.107-3.413,6.273-4.985,9.381
	c-9.121,17.928-15.078,34.418-17.693,49.064c-0.589,3.289-1.016,6.6-1.271,9.854c-0.261,3.348-0.332,6.58-0.216,9.615l0.066,0.826
	c0.209,3.891,0.715,7.65,1.534,11.188c1.081,4.77,2.77,9.318,4.993,13.535l0.536,1.051c2.527,4.572,5.826,8.971,9.797,13.027
	c10.862,11.094,26.629,20.08,48.214,27.418c2.979,1.016,6.209,2.045,9.897,3.117l1.957,0.594c3.796,1.08,7.795,2.123,11.897,3.115
	c0.985,0.232,1.978,0.457,2.971,0.676l1.152,0.26c5.607,1.275,11.317,2.436,16.933,3.449c4.579,0.826,9.255,1.586,13.892,2.262
	l2.738,0.383c1.094,0.152,2.195,0.311,3.305,0.465c2.594,0.34,5.201,0.672,7.833,0.984c2.607,0.32,5.239,0.609,7.883,0.885
	l0.361,0.035c1.095,0.109,2.183,0.203,3.284,0.305l3.131,0.289c1.035,0.096,2.085,0.195,3.151,0.291l0.862,0.072
	c0.492,0.043,0.984,0.086,1.484,0.107l1.335,0.102c2.565,0.203,5.114,0.385,7.679,0.566l0.797,0.049
	c5.581,0.357,11.464,0.66,17.913,0.914c64.067,2.537,119.341-4.455,164.279-20.795c34.874-12.703,65.423-30.564,90.791-53.107
	c2.419-2.15,4.507-4.063,6.391-5.846c1.885-1.797,3.738-3.652,5.58-5.506c16.201-16.398,30.533-35.404,42.577-56.469
	c1.696-2.984,3.376-6.412,4.991-9.715c1.588-3.24,3.194-6.697,4.762-10.262c4.282-9.73,8.07-19.832,11.244-30.02
	c0.748-2.42,1.463-4.861,2.146-7.311c0.694-2.458,1.361-4.906,1.97-7.363l0.202-0.767c0.282-1.088,0.544-2.169,0.819-3.254
	c1.181-4.963,2.261-10.08,3.188-15.231c1.016-5.527,1.843-10.979,2.479-16.209c3.893-31.912,2.515-61.178-3.986-84.611
	c-4.486-16.15-9.971-29.989-16.774-42.295c-4.687-8.469-10.035-16.338-16.332-24.033c-2.325-2.855-5.601-5.884-8.819-8.725
	c-3.106-2.752-6.289-5.297-9.49-7.551c-10.805-7.664-23.094-13.359-36.521-16.977c-3.058-0.832-6.209-1.549-9.332-2.139
	c-3.485-0.672-6.66-1.166-9.701-1.52c-14.015-1.625-28.688-1.587-43.606,0.159c-2.805,0.312-5.824,0.739-9.268,1.29
	c-3.008,0.491-6.138,1.057-9.297,1.695c-38.352,7.795-78.979,25.809-124.218,55.075C227.257,60.984,224.8,62.595,222.372,64.225z"
    />
    <path
      fill="#FFFFFF"
      d="M413.415,151.162C413.415,151.162,413.303,151.162,413.415,151.162
	c-9.257-45.942-42.586-75.569-84.941-75.569c-20.714,0-42.934,7.522-64.689,21.756c-21.988-14.928-43.976-22.451-65.27-22.451
	c-42.59,0-75.802,29.974-84.597,76.379c-12.498,65.153,26.618,151.369,141.997,197.197c0.23,0.115,0.348,0.115,0.578,0.232
	c1.738,0.578,4.513,1.271,7.291,1.271s5.441-0.693,7.29-1.271c0.231-0.117,0.464-0.117,0.579-0.232
	C386.801,301.604,425.916,215.623,413.415,151.162z"
    />
    <path
      fill="none"
      stroke="#3F6A32"
      stroke-width="14"
      stroke-miterlimit="10"
      d="M414.91,154.199
	C414.91,154.199,414.796,154.199,414.91,154.199c-9.292-46.11-42.742-75.844-85.252-75.844c-20.789,0-43.09,7.55-64.924,21.835
	c-22.068-14.983-44.136-22.533-65.508-22.533c-42.743,0-76.077,30.083-84.904,76.657c-12.543,65.39,26.715,151.919,142.515,197.913
	c0.23,0.116,0.349,0.116,0.58,0.233c1.743,0.58,4.528,1.276,7.317,1.276c2.788,0,5.461-0.696,7.317-1.276
	c0.231-0.117,0.465-0.117,0.579-0.233C388.197,305.189,427.455,218.893,414.91,154.199z"
    />
    <g>
      <path
        fill="#3F6A32"
        d="M331.604,45.425c-3.715,0-7.428-1.413-10.254-4.239c-5.654-5.654-5.654-14.854,0-20.507
		c5.65-5.651,14.852-5.653,20.506,0c5.654,5.654,5.654,14.853,0,20.507C339.03,44.012,335.317,45.425,331.604,45.425z
		 M331.604,26.434c-1.152,0-2.305,0.438-3.184,1.316c-1.754,1.754-1.754,4.609,0,6.363c1.756,1.755,4.607,1.756,6.363,0
		c1.754-1.754,1.754-4.608,0-6.363C333.907,26.873,332.755,26.434,331.604,26.434z"
      />
      <path
        fill="#3F6A32"
        d="M219.313,423.069c-3.713,0-7.426-1.414-10.252-4.24c-5.654-5.654-5.654-14.854,0-20.506
		c5.652-5.652,14.854-5.652,20.506,0c5.654,5.654,5.654,14.854,0,20.506C226.741,421.657,223.026,423.069,219.313,423.069
		L219.313,423.069z M219.313,404.079c-1.15,0-2.305,0.438-3.182,1.314c-1.754,1.754-1.754,4.609,0,6.365
		c1.756,1.754,4.607,1.754,6.365,0c1.754-1.754,1.754-4.609,0-6.365C221.618,404.516,220.466,404.079,219.313,404.079z"
      />
      <path
        fill="#3F6A32"
        d="M295.556,58.301c-1.279,0-2.559-0.488-3.535-1.464l-5.965-5.964l-5.965,5.964
		c-1.951,1.952-5.119,1.952-7.07,0c-1.953-1.953-1.953-5.119,0-7.071l9.5-9.5c1.951-1.951,5.119-1.951,7.07,0l9.5,9.5
		c1.953,1.953,1.953,5.119,0,7.071C298.116,57.813,296.837,58.301,295.556,58.301z"
      />
      <path
        fill="#3F6A32"
        d="M440.522,404.077c-1.279,0-2.561-0.488-3.535-1.465l-5.965-5.965l-5.965,5.965
		c-1.951,1.951-5.119,1.951-7.07,0c-1.953-1.953-1.953-5.119,0-7.07l9.5-9.5c1.951-1.953,5.119-1.953,7.07,0l9.5,9.5
		c1.953,1.953,1.953,5.119,0,7.07C443.081,403.586,441.802,404.077,440.522,404.077z"
      />
      <path
        fill="#3F6A32"
        d="M179.659,408.827c-1.277,0-2.559-0.488-3.533-1.465l-5.967-5.965l-5.965,5.965
		c-1.951,1.951-5.119,1.951-7.068,0c-1.953-1.953-1.953-5.119,0-7.07l9.5-9.5c1.949-1.953,5.117-1.953,7.068,0l9.5,9.5
		c1.953,1.953,1.953,5.119,0,7.07C182.22,408.336,180.938,408.827,179.659,408.827z"
      />
    </g>
    <path
      fill="none"
      stroke="#EE8484"
      stroke-width="14"
      stroke-linecap="round"
      stroke-miterlimit="10"
      d="M286.807,292.743
	c0.127-0.064,0.254-0.066,0.315-0.13c49.543-20.655,73.057-54.423,77.252-85.043"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
