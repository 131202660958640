<template>
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 50 50"
    enable-background="new 0 0 50 50"
    xml:space="preserve"
  >
    <path
      fill="currentColor"
      d="M26.12,14.488c0.879-1.468,2.271-3.569,4.026-5.585h0.002c1.488-1.711,4.401-3.907,4.401-3.907
	c0.438-0.331,0.392-0.792-0.106-1.024l-3.521-1.646c-0.498-0.233-1.175-0.063-1.505,0.376c0,0-2.604,3.737-5.671,11.692
	c-9.48-4.288-18.491,1.718-18.491,12.163c0,10.908,8.393,27.479,19.743,22.478c11.954,5.174,19.749-11.568,19.749-22.478
	C44.747,16.022,36.646,9.846,26.12,14.488z"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
