<template>
  <Header />
  <!-- <div class="h-100 md:max-w-7xl mx-auto px-4 sm:px-6"> -->
  <div class="h-100 mx-auto pb-14">
    <router-view />
  </div>
</template>

<script>
import Header from "./Header";

export default {
  components: {
    Header
  }
};
</script>

<style>
body {
  background: white;
}
</style>
