<template>
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 529.235 431.396"
    enable-background="new 0 0 529.235 431.396"
    xml:space="preserve"
  >
    <path
      fill="#E4B134"
      d="M222.373,64.225c-2.601,1.73-5.203,3.47-7.769,5.238c-6.021,4.124-12.23,8.521-18.475,13.064
	c-71.266,51.88-128.797,112.363-166.353,174.878c-1.927,3.189-3.791,6.441-5.558,9.604c-1.725,3.107-3.413,6.273-4.985,9.381
	c-9.121,17.928-15.078,34.418-17.693,49.064c-0.589,3.289-1.016,6.6-1.271,9.854c-0.261,3.348-0.332,6.58-0.216,9.615l0.066,0.826
	c0.209,3.891,0.715,7.65,1.534,11.188c1.081,4.77,2.77,9.318,4.993,13.535l0.536,1.051c2.527,4.572,5.826,8.971,9.797,13.027
	c10.862,11.094,26.629,20.08,48.214,27.418c2.979,1.016,6.209,2.045,9.897,3.117l1.957,0.594c3.796,1.08,7.795,2.123,11.897,3.115
	c0.985,0.232,1.978,0.457,2.971,0.676l1.152,0.26c5.607,1.275,11.317,2.436,16.933,3.449c4.579,0.826,9.255,1.586,13.892,2.262
	l2.738,0.383c1.094,0.152,2.195,0.311,3.305,0.465c2.594,0.34,5.201,0.672,7.833,0.984c2.607,0.32,5.239,0.609,7.883,0.885
	l0.361,0.035c1.095,0.109,2.183,0.203,3.284,0.305l3.131,0.289c1.035,0.096,2.085,0.195,3.151,0.291l0.862,0.072
	c0.492,0.043,0.984,0.086,1.484,0.107l1.335,0.102c2.565,0.203,5.114,0.385,7.679,0.566l0.797,0.049
	c5.581,0.357,11.464,0.66,17.913,0.914c64.067,2.537,119.341-4.455,164.279-20.795c34.874-12.703,65.423-30.564,90.791-53.107
	c2.419-2.15,4.507-4.063,6.391-5.846c1.885-1.797,3.738-3.652,5.58-5.506c16.201-16.398,30.533-35.404,42.577-56.469
	c1.696-2.984,3.376-6.412,4.991-9.715c1.588-3.24,3.194-6.697,4.762-10.262c4.282-9.73,8.07-19.832,11.244-30.02
	c0.748-2.42,1.463-4.861,2.146-7.311c0.694-2.459,1.361-4.906,1.97-7.363l0.202-0.768c0.282-1.088,0.544-2.168,0.819-3.254
	c1.181-4.963,2.261-10.078,3.188-15.23c1.016-5.527,1.843-10.979,2.479-16.209c3.893-31.912,2.515-61.178-3.986-84.611
	c-4.486-16.15-9.971-29.989-16.774-42.295c-4.687-8.469-10.035-16.338-16.332-24.033c-2.325-2.855-5.601-5.884-8.819-8.725
	c-3.106-2.752-6.289-5.297-9.49-7.551c-10.805-7.664-23.094-13.359-36.521-16.977c-3.058-0.832-6.209-1.549-9.332-2.139
	c-3.485-0.672-6.66-1.166-9.701-1.52c-14.015-1.625-28.688-1.587-43.606,0.159c-2.805,0.312-5.824,0.739-9.268,1.29
	c-3.008,0.491-6.138,1.057-9.297,1.695c-38.352,7.795-78.979,25.809-124.218,55.075C227.258,60.985,224.801,62.595,222.373,64.225z"
    />
    <path
      fill="#FFFFFF"
      d="M404.299,180.249h-84.332l42.543-81.203c5.922-11.309,1.559-25.275-9.75-31.198
	c-11.307-5.923-25.275-1.558-31.197,9.75l-53.775,102.651h-43.34L170.68,77.604c-5.92-11.307-19.887-15.674-31.195-9.754
	c-11.305,5.922-15.672,19.887-9.752,31.195l42.529,81.203H87.938c-11.914,0-21.57,9.658-21.57,21.57v7.19
	c0,11.913,9.656,21.57,21.57,21.57h8.238l18.695,118.405c1.613,10.507,10.674,18.25,21.303,18.205h219.887
	c10.629,0.045,19.691-7.698,21.305-18.205l18.693-118.405h8.24c11.912,0,21.57-9.657,21.57-21.57v-7.19
	C425.869,189.907,416.211,180.249,404.299,180.249z M259.152,196.743"
    />
    <g>
      <path
        fill="#3F6A32"
        d="M404.299,179.75h-84.332l42.543-81.204c5.922-11.308,1.559-25.275-9.75-31.198
		c-11.307-5.923-25.275-1.558-31.197,9.75L267.787,179.75h-43.34L170.68,77.105c-5.92-11.307-19.887-15.674-31.195-9.753
		c-11.305,5.921-15.672,19.886-9.752,31.194l42.529,81.204H87.938c-11.914,0-21.57,9.657-21.57,21.57v7.19
		c0,11.913,9.656,21.57,21.57,21.57h8.238l18.695,118.405c1.613,10.507,10.674,18.25,21.303,18.205h219.887
		c10.629,0.045,19.691-7.698,21.305-18.205l18.693-118.405h8.24c11.912,0,21.57-9.657,21.57-21.57v-7.19
		C425.869,189.408,416.211,179.75,404.299,179.75z M334.303,83.764c1.502-2.879,4.482-4.682,7.73-4.674
		c4.824,0.002,8.732,3.917,8.73,8.741c0,1.408-0.342,2.796-0.994,4.044l-62.418,119.152c-2.547,4.279-8.08,5.681-12.359,3.132
		c-1.129-0.672-2.1-1.585-2.84-2.672c-1.617-2.603-1.719-5.872-0.266-8.571L334.303,83.764z M260.254,194.13l-1.102,2.114
		c-3.139,6.051-3.404,13.19-0.719,19.456h-24.617c2.682-6.266,2.418-13.404-0.721-19.456l-1.113-2.114H260.254z M142.732,83.303
		c1.553-2.643,4.404-4.251,7.469-4.213c3.252-0.008,6.236,1.797,7.738,4.681l62.41,119.146c1.451,2.699,1.35,5.968-0.268,8.571
		c-2.801,4.117-8.408,5.184-12.525,2.384c-1.092-0.743-2.008-1.717-2.682-2.851L142.467,91.875
		C141.014,89.175,141.115,85.906,142.732,83.303z M80.746,208.511v-7.19c0-3.97,3.221-7.19,7.191-7.19h91.859l11.295,21.57H87.938
		C83.967,215.7,80.746,212.481,80.746,208.511z M363.164,346.243c-0.551,3.495-3.564,6.069-7.104,6.068H136.174
		c-3.537,0.001-6.551-2.573-7.104-6.068l-18.342-116.162h270.777L363.164,346.243z M411.488,208.511
		c0,3.971-3.219,7.189-7.189,7.189H301.135l11.305-21.57h91.859c3.971,0,7.189,3.22,7.189,7.19V208.511z"
      />
      <path
        fill="#EE8484"
        d="M246.117,244.461c-3.971,0-7.189,3.219-7.189,7.189v79.091c0,3.971,3.219,7.19,7.189,7.19
		s7.191-3.22,7.191-7.19V251.65C253.309,247.68,250.088,244.461,246.117,244.461z"
      />
      <path
        fill="#EE8484"
        d="M302.99,337.932h0.654c3.732,0.015,6.855-2.826,7.191-6.543l7.189-79.09
		c0.357-3.975-2.574-7.484-6.547-7.842s-7.482,2.573-7.84,6.546l0,0l-7.191,79.091c-0.371,3.954,2.531,7.46,6.484,7.832
		C302.951,337.928,302.971,337.93,302.99,337.932z"
      />
      <path
        fill="#EE8484"
        d="M180.76,244.461c-3.955,0.341-6.887,3.823-6.547,7.779c0,0.019,0.002,0.038,0.004,0.059l7.191,79.09
		c0.355,3.971,3.865,6.899,7.836,6.543c3.971-0.357,6.9-3.866,6.543-7.838l-7.189-79.091
		C188.225,247.041,184.727,244.119,180.76,244.461z"
      />
    </g>
    <g>
      <path
        fill="#3F6A32"
        d="M433.094,45.425c-3.715,0-7.428-1.413-10.254-4.239c-5.654-5.654-5.654-14.854,0-20.507
		c5.65-5.651,14.852-5.653,20.506,0c5.654,5.654,5.654,14.853,0,20.507C440.52,44.012,436.807,45.425,433.094,45.425z
		 M433.094,26.434c-1.152,0-2.305,0.438-3.184,1.316c-1.754,1.754-1.754,4.609,0,6.363c1.756,1.755,4.607,1.756,6.363,0
		c1.754-1.754,1.754-4.608,0-6.363C435.396,26.874,434.244,26.434,433.094,26.434z"
      />
      <path
        fill="#3F6A32"
        d="M132.814,423.069c-3.713,0-7.426-1.414-10.252-4.24c-5.654-5.654-5.654-14.854,0-20.506
		c5.652-5.652,14.854-5.652,20.506,0c5.654,5.654,5.654,14.854,0,20.506C140.242,421.657,136.527,423.069,132.814,423.069
		L132.814,423.069z M132.814,404.079c-1.15,0-2.305,0.438-3.182,1.314c-1.754,1.754-1.754,4.609,0,6.365
		c1.756,1.754,4.607,1.754,6.365,0c1.754-1.754,1.754-4.609,0-6.365C135.119,404.517,133.967,404.079,132.814,404.079z"
      />
      <path
        fill="#3F6A32"
        d="M397.045,58.301c-1.279,0-2.559-0.488-3.535-1.464l-5.965-5.964l-5.965,5.964
		c-1.951,1.952-5.119,1.952-7.07,0c-1.953-1.953-1.953-5.119,0-7.071l9.5-9.5c1.951-1.951,5.119-1.951,7.07,0l9.5,9.5
		c1.953,1.953,1.953,5.119,0,7.071C399.605,57.813,398.326,58.301,397.045,58.301z"
      />
      <path
        fill="#3F6A32"
        d="M428.023,404.077c-1.279,0-2.561-0.488-3.535-1.465l-5.965-5.965l-5.965,5.965
		c-1.951,1.951-5.119,1.951-7.07,0c-1.953-1.953-1.953-5.119,0-7.07l9.5-9.5c1.951-1.953,5.119-1.953,7.07,0l9.5,9.5
		c1.953,1.953,1.953,5.119,0,7.07C430.582,403.587,429.303,404.077,428.023,404.077z"
      />
      <path
        fill="#3F6A32"
        d="M93.16,408.827c-1.277,0-2.559-0.488-3.533-1.465l-5.967-5.965l-5.965,5.965
		c-1.951,1.951-5.119,1.951-7.068,0c-1.953-1.953-1.953-5.119,0-7.07l9.5-9.5c1.949-1.953,5.117-1.953,7.068,0l9.5,9.5
		c1.953,1.953,1.953,5.119,0,7.07C95.721,408.337,94.439,408.827,93.16,408.827z"
      />
    </g>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
