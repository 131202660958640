const BASE_URL = process.env.VUE_APP_BASE_URL;

function apiGetListaLocales() {
  return BASE_URL + "stores_baskets/all";
}

function apiGetLocalBasket(id) {
  return BASE_URL + `stores_baskets/${id}`;
}
function apiPostLocalBasket() {
  return BASE_URL + `basket`;
}
function apiPutLocalBasket(id) {
  return BASE_URL + `basket/${id}`;
}

function apiGetClientSales(client_id) {
  return BASE_URL + `client/sales/${client_id}`;
}

function apiPutClient(client_id) {
  return BASE_URL + `client/${client_id}`;
}

function apiGetSale(id) {
  return BASE_URL + `sale/${id}`;
}
function apiPostSale() {
  return BASE_URL + `sale`;
}
function apiPutSale(id) {
  return BASE_URL + `sale/${id}`;
}

function apiPutStore(id) {
  return BASE_URL + `store/${id}`;
}

function apiGetRubros() {
  return BASE_URL + "category";
}

function apiLogin() {
  return BASE_URL + "token";
}

function apiRegistroCliente() {
  return BASE_URL + "signup/client";
}

function apiRegistroLocal() {
  return BASE_URL + "signup/store";
}

function apiMe() {
  return BASE_URL + "user/me";
}

function apiPostFavorito() {
  return BASE_URL + "favorites";
}
function apiGetFavoritos() {
  return BASE_URL + "stores_baskets/all?only_favorites=true";
}

export {
  apiGetListaLocales,
  apiGetRubros,
  apiGetLocalBasket,
  apiPostLocalBasket,
  apiPutLocalBasket,
  apiGetSale,
  apiPostSale,
  apiGetClientSales,
  apiLogin,
  apiMe,
  apiPostFavorito,
  apiRegistroCliente,
  apiRegistroLocal,
  apiPutSale,
  apiGetFavoritos,
  apiPutClient,
  apiPutStore
};
