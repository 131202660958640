<template>
  <div v-if="local">
    <div>
      <div
        class="w-full h-48 relative"
        :style="{
          backgroundImage: local.image
            ? `url(${local.image})`
            : `url(default-banner.png)`,
          backgroundSize: 'cover'
        }"
      >
        <div class="absolute top-1 left-1 text-white">
          <icon-flecha-izquierda @click="goback"></icon-flecha-izquierda>
        </div>
        <div
          class="w-auto rounded absolute top-10 text-sm left-1 bg-verde-1 p-1 text-white shadow-md"
        >
          {{ cantidadBasket }} Disponibles
        </div>
        <div class="absolute right-2 top-2">
          <favorito
            :active="local.favorite"
            class="w-8 h-8"
            @toggleFavorito="toggleFavorito"
          ></favorito>
        </div>
        <div
          class="absolute right-2 bottom-2 text-sm shadow-md"
          v-if="cantidadBasket > 0"
        >
          <div class="flex flex-grow md:justify-end md:mr-2">
            <div
              class="bg-azul-1 px-3 py-1 shadow-md text-white rounded cursor-pointer"
            >
              ${{ basket.price }}
            </div>
          </div>
        </div>
      </div>
      <div class="border-2 p-2 border-gris-2 bg-white text-left">
        <span class="font-semibold">
          {{ local.name }}
        </span>
        <span class="text-gris-1 flex items-center text-sm">
          <icon-time class="w-3 h-3 mr-1"></icon-time>
          Retiro: hoy {{ local.open }}hs a {{ local.close }}hs
        </span>
      </div>
      <div
        class="border-2 border-t-0 p-2 border-gris-2 bg-white text-left flex items-baseline text-verde-1 text-sm"
      >
        <icon-location class="w-3 h-3 mr-1"></icon-location>
        {{ local.address }} {{ local.number }}
      </div>

      <div
        class="mt-2 bg-white border-2 border-gris-2 p-2 text-md text-left flex flex-col"
      >
        <span class="font-semibold">Sobre la tienda</span>
        <span class="text-sm">{{ local.description }}</span>

        <div class="flex mt-1 mb-2">
          <div class="px-1 rounded bg-verde-2 mr-1">{{ local.rubroName }}</div>
        </div>
      </div>

      <div
        class="border-2 border-t-0 p-2 border-gris-2 bg-white text-left flex items-center text-verde-1 text-sm"
      >
        <icon-manzana class="w-4 h-4 mr-1"></icon-manzana>
        Ingredientes y alérgenos
      </div>

      <!-- <div
      class="border-2 border-t-0 p-2 border-gris-2 bg-white text-left flex items-baseline text-verde-1 text-sm"
    >
      <span class="font-semibold">¿Qué piensan los usuarios?</span>
    </div> -->

      <div class="w-full bg-azul-1 text-white flex py-1 mt-2 mb-20">
        <div class="flex items-center justify-center w-14">
          <icon-leaf class="w-4 h-4"></icon-leaf>
        </div>
        <div class="flex flex-col text-sm text-left">
          <span>¿Sabías qué...?</span>
          <span class="text-xs">
            Casi la mitad de todas las frutas y verduras que se <br />producen
            al año terminan en la basura
          </span>
        </div>
      </div>

      <div
        class="bg-white fixed bottom-12 w-full px-8 py-4"
        v-if="cantidadBasket"
      >
        <button-verde-rounded @click="cambiarPaso(2)">
          Reservar
        </button-verde-rounded>
      </div>
    </div>
  </div>

  <template v-if="pasoActivo !== 1">
    <tooltip-bottom @onClose="cambiarPaso(1)">
      <div class="px-6 w-full">
        <div v-if="pasoActivo === 2">
          <span class="text-xl text-verde-1 font-semibold">¡Sorpresa!</span>
          <div class="flex justify-center">
            <icon-sorpresa class="w-28 h-28"></icon-sorpresa>
          </div>
          <span class="text-gris-1 text-xs font-semibold"
            >El contenido de la canasta es sorpresa porque depende de los
            alimentos no vendidos del día. Si tenés alguna duda sobre el
            contenido preguntá en la tienda.</span
          >
          <button-verde-rounded @click="cambiarPaso(3)" class="mt-4">
            Reservar
          </button-verde-rounded>
        </div>
        <div v-if="pasoActivo === 3">
          <span class="text-xl font-semibold">{{ local.name }}</span>
          <div class="flex justify-center text-verde-1 text-xs">
            <icon-time class="w-4 h-4 mr-1"></icon-time>
            Retiro: hoy {{ local.open }}hs a {{ local.close }}hs
          </div>
          <div
            class="border-t border-b border-gris-2 mt-4 w-11/12 py-2 mx-auto"
          >
            <input-cantidad
              v-model:valor="cantidadCompra"
              :max="cantidadBasket"
            />
          </div>
          <div class="border-b h-6 border-gris-2 w-11/12 flex mx-auto relative">
            <span class="absolute left-0 font-bold text-sm">Total</span>
            <span class="absolute right-0 font-semibold text-verde-1 text-sm">
              ${{
                basket.price * cantidadCompra
                  ? basket.price * cantidadCompra
                  : "-"
              }}
            </span>
          </div>
          <div>
            <span class="text-gris-1 text-xs">
              Reservando esta comida aceptas los
              <b>términos y condiciones.</b>
            </span>
          </div>
          <button-verde-rounded @click="realizarCompra" class="mt-4">
            Reservar
          </button-verde-rounded>
        </div>
      </div>
    </tooltip-bottom>
  </template>
</template>

<script>
import { useRoute } from "vue-router";
import { ref, onMounted, computed } from "vue";
import {
  apiGetLocalBasket,
  apiGetRubros,
  apiPostSale,
  apiPostFavorito
} from "@/services/routes.js";
import router from "@/router/router.js";
import store from "@/services/store";

import IconLocation from "../components/Icons/iconLocation.vue";
import IconFlechaIzquierda from "../components/Icons/iconFlechaIzquierda.vue";

import InputCantidad from "../components/elementos/inputCantidad.vue";
import IconTime from "../components/Icons/iconTime.vue";
import Favorito from "@/components/elementos/Favorito.vue";
import IconManzana from "../components/Icons/iconManzana.vue";
import IconLeaf from "../components/Icons/iconLeaf.vue";
import IconSorpresa from "../components/Icons/iconSorpresa.vue";
import TooltipBottom from "../components/elementos/TooltipBottom.vue";
import ButtonVerdeRounded from "../components/elementos/ButtonVerdeRounded.vue";

export default {
  components: {
    IconLocation,
    InputCantidad,
    IconFlechaIzquierda,
    Favorito,
    IconTime,
    IconManzana,
    IconLeaf,
    IconSorpresa,
    TooltipBottom,
    ButtonVerdeRounded
  },
  setup() {
    let idUsuario = store.state.usuario.id;
    let auth = store.state.usuario.auth;

    let rubros = ref([]);

    let local = ref({});
    let basket = ref({});
    let cantidadCompra = ref(1);
    let pasoActivo = ref(1); // 1- PgLocal, 2- MsjSorpresa, 3- Cantidad

    let datosCargados = ref(false);

    onMounted(() => {
      let pLocales = fetchLocal();
      let pRubros = fetchRubros();
      Promise.all([pLocales, pRubros]).then(() => {
        local.value = {
          ...local.value,
          rubroName: rubros.value.find(r => r.id == local.value.category_id)
            .name
        };
        datosCargados.value = true;
      });
    });

    const route = useRoute();
    const id = route.params.id;
    const fetchLocal = async () => {
      let res = await fetch(apiGetLocalBasket(id), {
        headers: {
          Authorization: auth,
          "Content-Type": "application/json"
        }
      });
      res = await res.json();
      local.value = res.store;
      basket.value = res.baskets[0];
    };

    const fetchRubros = async () => {
      let res = await fetch(apiGetRubros());
      res = await res.json();
      rubros.value = res;
    };

    const realizarCompra = () => {
      fetch(apiPostSale(), {
        method: "POST",
        headers: {
          Authorization: auth,
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          qty: cantidadCompra.value,
          basket_id: basket.value.id,
          client_id: idUsuario,
          date: new Date().toISOString().substring(0, 10),
          state: "OPEN"
        })
      })
        .then(res => res.json())
        .then(res => {
          goToCompraRealizada(res.id);
        });
    };

    const cantidadBasket = computed(() => {
      return basket.value && basket.value.qty
        ? basket.value.qty - (basket.value.sold ? basket.value.sold : 0)
        : 0;
    });

    const goToCompraRealizada = id => {
      router.push({ name: "CompraRealizada", id: id });
    };

    const toggleFavorito = async () => {
      let res = await fetch(apiPostFavorito(), {
        method: "POST",
        headers: {
          Authorization: auth,
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          store_id: local.value.id,
          client_id: idUsuario,
          add: local.value.favorite ? false : true
        })
      });
      res = await res.json();
      if (res.result === "ok") {
        local.value.favorite = local.value.favorite ? false : true;
      }
    };

    const cambiarPaso = paso => {
      console.log(paso);
      pasoActivo.value = paso;
    };

    const goback = () => {
      router.push({ name: "Locales" }); //TODO que sepa si viene de locales o de perfil
    };

    return {
      local,
      basket,
      cantidadCompra,
      datosCargados,
      realizarCompra,
      cantidadBasket,
      toggleFavorito,
      cambiarPaso,
      pasoActivo,
      goback
    };
  }
};
</script>

<style></style>
