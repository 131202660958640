<template>
  <go-back-bar
    :titulo="'Tus favoritos'"
    :arrow="false"
    @go-back="goToLocales"
  ></go-back-bar>

  <div
    class="flex flex-col items-center justify-center mt-8 max-w-lg mx-auto"
    v-if="favoritos.length === 0"
  >
    <svg-corazon class="w-2/5 max-w-xs" />

    <div class="flex flex-col w-2/3">
      <span class="font-bold text-xl mt-8">
        Aún no has añadido favoritos
      </span>
      <span class="text-md mt-2">
        Pulsa en el icono del corazón para añadirlo a favoritos y mostrarlo
        aquí.
      </span>
    </div>

    <div class="bg-gris-2 w-2/3 h-14 mt-4 relative">
      <favorito :active="false" class="absolute right-1 top-1 w-6 h-6" />
    </div>
    <div class="bg-white w-2/3 h-10 relative shadow-md"></div>

    <div
      class="py-1 w-2/3 max-w-lg rounded-full text-verde-1 border-2 text-lg border-verde-1 font-bold mt-8"
      @click="goToLocales"
    >
      Encontrá<br />
      tiendas
    </div>
  </div>

  <div v-else class="p-2">
    <div
      v-for="local in favoritos"
      :key="local.id"
      :class="[
        {
          'opacity-low':
            !local.baskets_qty || local.baskets_qty - local.sold === 0
        },
        'border font-sans bg-white shadow-ms mt-2 mb-4 grid grid-cols-3 justify-left shadow-md rounded'
      ]"
      @click="goToLocal(local.id)"
    >
      <div class="h-full relative">
        <div
          class="absolute left-0 bottom-0 w-full text-left h-6 px-1 pl-2 font-medium bg-verde-1 text-white text-sm rounded-bl shadow-md"
          v-if="local.baskets_disponibles !== 0"
        >
          {{ local.baskets_disponibles }} Disponibles
        </div>
        <div class="w-full rounded-l" style="overflow:hidden;height:99.6%">
          <img
            v-if="local.image"
            class="object-cover	h-36 w-full"
            :src="local.image"
            alt=""
          />
          <img v-else class="h-full w-full" src="default-profile.png" alt="" />
        </div>
      </div>

      <div
        class="ml-4 mt-1 flex flex-grow text-left flex-col col-span-2 relative"
      >
        <!-- Favoritos -->
        <div class="absolute right-2 top-0">
          <favorito
            class="w-4 h-4"
            :active="local.favorite"
            @click.stop="toggleFavorito(local.id)"
          ></favorito>
        </div>

        <div class="text-lg font-semibold">
          {{ local.name }}
        </div>
        <div class="text-gray-400 text-sm leading-4">
          Retiro: hoy {{ local.open }}hs a {{ local.close }}hs
        </div>
        <div
          class="absolute right-2 bottom-1 text-sm"
          v-if="local.baskets_qty && local.baskets_qty - local.sold > 0"
        >
          <div class="flex flex-grow md:justify-end md:mr-2">
            <div
              class="bg-azul-1 px-3 py-1 shadow-md text-white rounded cursor-pointer"
            >
              ${{ local.baskets_price }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router/router.js";
import { apiGetFavoritos, apiPostFavorito } from "@/services/routes.js";
import store from "@/services/store";

import GoBackBar from "../components/elementos/GoBack.vue";
import SvgCorazon from "../components/Icons/svgCorazon.vue";
import Favorito from "../components/elementos/Favorito.vue";
import { onMounted, ref } from "vue";
export default {
  name: "favoritos",
  components: {
    GoBackBar,
    SvgCorazon,
    Favorito
  },
  setup() {
    let favoritos = ref([]);
    let auth = store.state.usuario.auth;
    let idUsuario = store.state.usuario.id;

    let goToLocales = () => {
      router.push({ name: "Locales" });
    };

    const goToLocal = id => {
      router.push({ name: "local", params: { id: id } });
    };

    onMounted(() => {
      getFavoritos();
    });

    const getFavoritos = async () => {
      let res = await fetch(apiGetFavoritos(), {
        method: "GET",
        headers: {
          Authorization: auth,
          "Content-Type": "application/json"
        }
      });
      res = await res.json();
      res = res.map(l => {
        return {
          ...l,
          baskets_disponibles: l.baskets_qty ? l.baskets_qty - l.sold : 0
        };
      });
      favoritos.value = res;
    };

    const toggleFavorito = async id => {
      let add = false;
      let res = await fetch(apiPostFavorito(), {
        method: "POST",
        headers: {
          Authorization: auth,
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          client_id: idUsuario,
          store_id: id,
          add: add
        })
      });
      res = await res.json();
      if (res.result === "ok") {
        getFavoritos();
      }
    };

    return { goToLocales, favoritos, goToLocal, toggleFavorito };
  }
};
</script>

<style></style>
