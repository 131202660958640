<template>
  <go-back-bar
    :titulo="'Tu pedido'"
    :arrow="true"
    @go-back="goToPedidos"
  ></go-back-bar>

  <div class="p-1">
    <!-- Datos pedido -->
    <div class="bg-white rounded px-1 pt-3 shadow-md">
      <div class="mx-auto flex flex-col items-center">
        <span class="text-gris-1">
          Estado del pedido
        </span>
        <span
          :class="[
            {
              'bg-amarillo-1 text-negro-1': ticket.state === 'OPEN',
              'bg-rojo-1 text-white': ticket.state === 'CANC',
              'bg-verde-1 text-white': ticket.state === 'DONE'
            },
            'flex rounded py-1 px-2 font-semibold'
          ]"
        >
          {{
            ticket.state === "OPEN"
              ? "Retiro pendiente"
              : ticket.state === "CANC"
              ? "Retiro cancelado"
              : ticket.state === "DONE"
              ? "Retiro finalizado"
              : ""
          }}
        </span>
        <div
          class="bg-azul-1 text-white px-3 w-min mt-2 mb-4 rounded font-bold"
        >
          F8J3
          <!-- TODO: Poner desde la base -->
        </div>
      </div>
      <div class="w-full border-b-2 border-gris-2 mx-auto"></div>

      <div class="flex w-full py-4">
        <div class="w-14 flex items-center justify-center">
          <div
            class="w-8 h-8 rounded-full bg-azul-1 flex items-center justify-center text-white"
          >
            {{ ticket.qty }}x
          </div>
        </div>
        <div class="flex flex-col text-left w-full">
          <span class="font-semibold">Pack sorpresa</span>
          <div class="w-full relative">
            <span>Total</span>
            <span class="text-verde-1 font-semibold absolute right-1">
              ${{ ticket._basket ? ticket._basket.price * ticket.qty : "-" }}
            </span>
          </div>
        </div>
      </div>

      <div class="w-full border-b-2 border-gris-2 mx-auto"></div>

      <div class="flex w-full py-4 relative">
        <div class="w-14 flex items-center justify-center">
          <div
            class="w-8 h-8 rounded-full bg-azul-1 flex items-center justify-center text-white"
          >
            <icon-store class="w-5 h-5" />
          </div>
        </div>
        <div class="flex flex-col text-left w-full">
          <span class="font-semibold">{{ local.name }}</span>
          <div class="w-full relative">
            <span>
              {{ local.address }}
            </span>
          </div>
        </div>
        <div class="flex flex-grow justify-center items-center">
          <icon-chevron-right />
        </div>
      </div>

      <div class="w-full border-b-2 border-gris-2 mx-auto"></div>

      <div class="flex w-full py-4 relative">
        <div class="w-14 flex items-center justify-center">
          <div
            class="w-8 h-8 rounded-full bg-azul-1 flex items-center justify-center text-white"
          >
            <icon-time class="w-5 h-5" />
          </div>
        </div>
        <div class="flex flex-col text-left w-full">
          <div class="w-full relative flex flex-col">
            <span> Retiro: {{ formatDateFront(ticket.date) }} </span>
            <span>entre {{ local.open }}hs y {{ local.close }}hs</span>
          </div>
        </div>
      </div>

      <div class="w-full border-b-2 border-gris-2 mx-auto"></div>

      <div class="flex w-full py-4 relative">
        <div class="w-14 flex items-center justify-center">
          <div
            class="w-8 h-8 rounded-full bg-azul-1 flex items-center justify-center text-white"
          >
            <icon-bag class="w-5 h-5" />
          </div>
        </div>
        <div class="flex flex-col text-left w-full">
          <div class="w-4/5 relative flex flex-col text-sm leading-4">
            <span>
              Esta tienda te ofrecerá un pack envasado y listo para retirar,
              pero te animamos a que lleves tu propia bolsa para llevártelo a
              casa.
            </span>
          </div>
        </div>
      </div>

      <div class="w-full border-b-2 border-gris-2 mx-auto"></div>

      <div class="py-6">
        <div
          class="rounded-full text-verde-1 mx-auto py-2 bg-white border-2 border-verde-1 font-semibold text-lg w-4/5"
        >
          ¿Necesitas ayuda?
        </div>
      </div>
    </div>

    <!-- Información importante -->
    <div class="bg-white rounded py-3 mt-3 shadow-md font-medium mb-24">
      <div class="max-w-md mx-auto">
        <div class="text-gris-1 font-semibold">Información importante</div>
        <div class="flex mt-4 mx-auto">
          <div class="w-10 mx-3 flex justify-center items-center">
            <icon-regalo class="w-8 h-8 text-verde-1" />
          </div>
          <div class="text-left w-4/5 text-sm leading-4">
            Acordate que la canasta es sorpresa. <br />No podemos saber que
            alimentos sobran cada día.
          </div>
        </div>
        <div class="flex mt-4 mx-auto">
          <div class="w-10 mx-3 flex justify-center items-center">
            <icon-time class="w-8 h-8 text-verde-1" />
          </div>
          <div class="text-left w-4/5 text-sm leading-4">
            Es importante que respetes el horario de retiro para poder
            garantizarte la entrega.
          </div>
        </div>
        <div class="flex mt-4 mx-auto">
          <div class="w-10 mx-3 flex justify-center items-center">
            <icon-mobile class="w-8 h-8 text-verde-1" />
          </div>
          <div class="text-left w-4/5 text-sm leading-4">
            Mostrá el pedido en la tienda, tocá el botón y confirmá para retirar
            tu canasta
          </div>
        </div>
      </div>
    </div>

    <!-- Botón retirar -->
    <div class="py-6 bg-white mt-4 fixed bottom-10 w-full">
      <div
        class="rounded-full text-white mx-auto py-2 bg-verde-1 border-2 border-verde-1 font-semibold text-lg w-4/5"
        @click="cambiarPaso(1)"
      >
        Tocá para retirar
      </div>
    </div>
  </div>

  <!-- Oscurece la pantalla -->
  <div v-if="pasoActivo !== 0">
    <closer class="bg-black opacity-60" @click="cambiarPaso(0)"></closer>
  </div>

  <!-- Paso 1 -->
  <div v-if="pasoActivo === 1">
    <div
      class="bg-white rounded-t-xl py-2 h-auto w-full fixed bottom-14 flex flex-col z-50 items-center"
    >
      <span class="font-bold">Pedido para retirar</span>
      <div class="w-full flex flex-col">
        <div
          class="w-8 h-8 mt-4 mx-auto rounded-full bg-azul-1 flex items-center justify-center text-white"
        >
          {{ ticket.qty }}x
        </div>
        <span class="font-bold mt-1">Pack sorpresa</span>
        <span class="text-sm">{{ local.address }}</span>
        <div
          class="bg-amarillo-1 mx-auto px-3 w-min mt-2 mb-4 rounded font-bold"
        >
          TODO
          <!-- TODO poner el código real -->
        </div>
      </div>

      <!-- Slider -->
      <div class="w-3/4 h-12 max-w-md relative" v-if="!retiroConfirm">
        <div
          class="mx-auto w-full text-verde-1 font-semibold text-xl absolute top-2"
          style="pointer-events: none;"
        >
          Confirmar retiro
        </div>
        <!-- INFO: No toma el color del Tailwind, está en CSS -->
        <input
          type="range"
          min="1"
          max="100"
          v-model="porcConfirm"
          class="slider"
        />
      </div>

      <div
        v-if="retiroConfirm"
        class="flex flex-col px-4 py-1 border-t border-gris-2"
      >
        <div
          class="text-white bg-verde-1 w-12 h-12 rounded-full flex justify-center items-center my-1 mx-auto"
        >
          <icon-check class="h-10 w-10" />
        </div>
        <span class="text-sm font-bold">Retiro confirmado</span>
        <span class="text-sm">
          Enseña esta pantalla en el <br />establecimiento para recoger tu pack
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch } from "vue";
import router from "@/router/router.js";
import {
  apiGetSale,
  apiGetLocalBasket,
  apiPutSale
} from "@/services/routes.js";
import { formatDateFront } from "@/services/functions.js";

import GoBackBar from "../components/elementos/GoBack.vue";
import IconStore from "../components/Icons/iconStore.vue";
import IconChevronRight from "../components/Icons/Mas/iconChevronRight.vue";
import IconTime from "../components/Icons/iconTime.vue";
import IconBag from "../components/Icons/iconBag.vue";
import IconRegalo from "../components/Icons/iconRegalo.vue";
import IconMobile from "../components/Icons/iconMobile.vue";
import Closer from "../components/Closer.vue";
import IconCheck from "../components/Icons/iconCheck.vue";

export default {
  components: {
    GoBackBar,
    IconStore,
    IconChevronRight,
    IconTime,
    IconBag,
    IconRegalo,
    IconMobile,
    Closer,
    IconCheck
  },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const ticket = ref({});
    const local = ref({});
    let porcConfirm = ref(0);
    let retiroConfirm = ref(false);
    let pasoActivo = ref(0);

    onMounted(() => {
      fetchTicket().then(() => {
        fetchLocal(ticket.value._basket.store_id);
      });
    });

    watch(porcConfirm, nuevoPorc => {
      if (nuevoPorc === "100") {
        fetch(apiPutSale(idTicket.value), {
          method: "PUT",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            state: "DONE",
            date: new Date().toISOString().substring(0, 10),
            qty: ticket.value.qty
          })
        }).then(() => {
          porcConfirm.value = 0;
          retiroConfirm.value = true;
        });
      }
    });
    const idTicket = ref(props.id);
    const fetchTicket = async () => {
      let res = await fetch(apiGetSale(idTicket.value));
      res = await res.json();
      ticket.value = res;
    };
    const fetchLocal = async id => {
      let res = await fetch(apiGetLocalBasket(id));
      res = await res.json();
      local.value = res.store;
    };

    const goToPedidos = () => {
      router.push({ name: "mas", params: { activa: "pedidos" } });
    };

    const cambiarPaso = paso => {
      pasoActivo.value = paso;
    };

    return {
      ticket,
      local,
      goToPedidos,
      pasoActivo,
      cambiarPaso,
      porcConfirm,
      retiroConfirm,
      formatDateFront
    };
  }
};
</script>

<style>
.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 100%;
  background: white;
  border: 1px solid #3f6a32;
  outline: none;
  border-radius: 9999px;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 3rem;
  height: 3rem;
  background: #3f6a32;
  cursor: pointer;
  border-radius: 999px;
  background-image: url("/chevronRight.svg");
  background-repeat: no-repeat;
  background-position: center center;
}

.slider::-moz-range-thumb {
  width: 3rem;
  height: 3rem;
  background: #3f6a32;
  cursor: pointer;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  input[type="range"] {
    overflow: hidden;
    -webkit-appearance: none;
  }

  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    cursor: ew-resize;
    box-shadow: -768px 0 0 747px #3f6a32;
  }
}
/** FF*/
input[type="range"]::-moz-range-track {
  background-color: #3f6a32;
}
/* IE*/
input[type="range"]::-ms-fill-upper {
  background-color: #3f6a32;
}
</style>
