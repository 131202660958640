import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import { apiGetRubros } from "@/services/routes.js";

const store = createStore({
  state: {
    usuario: {
      logueado: false,
      nombre: null,
      auth: null,
      id: null,
      userType: null
    },
    rubros: []
  },
  plugins: [createPersistedState()],
  mutations: {
    setUsuario(state, usuario) {
      state.usuario.logueado = true;
      state.usuario.id = usuario.id;
      state.usuario.nombre = usuario.nombre;
      state.usuario.auth = usuario.auth;
      state.usuario.userType = usuario.userType;
    },
    logOut(state) {
      state.usuario.logueado = false;
      state.usuario.id = null;
      state.usuario.nombre = null;
      state.usuario.auth = null;
      state.usuario.userType = null;
    },
    setRubros(state, rubros) {
      state.rubros = rubros;
    }
  },
  getters: {
    getRubros(state) {
      return state.rubros;
    }
  },
  actions: {
    async fetchRubros(context) {
      this.state.rubros = [];
      if (this.state.rubros.length === 0) {
        let res = await fetch(apiGetRubros());
        res = await res.json();
        context.commit("setRubros", res);
      }
    }
  }
});

export default store;
