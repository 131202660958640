<template>
  <div class="text-left text-sm" v-if="pasoActivo === 1">
    <div class="mb-1">
      <span class="font-bold px-4">Detalles de la cuenta</span>
    </div>
    <div
      :class="[
        { 'border-t-0': index !== 0 },
        'px-4 py-1 flex border-2 border-gris-2 font-semibold text-negro-1 bg-white relative'
      ]"
      v-for="(detalle, index) in detalles"
      :key="detalle.id"
      @click="cambiarPaso(2, detalle.id)"
    >
      {{ detalle.nombre }}
      <div class="absolute right-1 ">
        <icon-chevron-right />
      </div>
    </div>

    <div class="mb-1 mt-4">
      <span class="font-bold px-4">Ajustes</span>
    </div>
    <div
      :class="[
        { 'border-t-0': index !== 0 },
        'px-4 py-1 flex border-2 border-gris-2 font-semibold text-negro-1 bg-white relative'
      ]"
      v-for="(ajuste, index) in ajustes"
      :key="ajuste.id"
      @click="cambiarPaso(3, ajuste.id)"
    >
      {{ ajuste.nombre }}
      <div class="absolute right-1 ">
        <icon-chevron-right />
      </div>
    </div>

    <div
      class="px-4 py-1 mt-6 flex border-2 border-gris-2 font-semibold text-rojo-1 bg-white relative"
      @click="logOut"
    >
      Cerrar Sesión
    </div>
  </div>

  <!-- Modificar Perfil Paso 2 -->
  <div v-if="pasoActivo === 2" class="flex flex-col relative">
    <go-back
      :titulo="'Editar ' + campoEditar.nombre"
      @go-back="cambiarPaso(1)"
    />

    <div class="text-left mx-4 flex flex-col">
      {{ campoEditar.nombre }}
      <input
        type="text"
        v-model="valorEditar"
        class="rounded-full shadow-md px-2 py-3"
      />
      <div
        class="mt-5 py-3 rounded-full bg-verde-1 text-white text-center w-full"
        @click="guardarCampo(campoEditar.id)"
      >
        Guardar
      </div>
    </div>
  </div>

  <!-- Ajustes Paso 3 -->
  <div v-if="pasoActivo === 3">
    <div v-if="campoEditar.id === 'camContraseña'">
      <go-back :titulo="'Cambiar contraseña'" @go-back="cambiarPaso(1)" />
      <div class="flex flex-col text-left mx-4">
        <div class="flex flex-col">
          Contraseña actual
          <input
            type="text"
            v-model="contraseñaEditar.old"
            class="rounded-full shadow-md px-2 py-3"
          />
        </div>
        <div class="mt-10 flex flex-col">
          Nueva contraseña
          <input
            type="text"
            v-model="contraseñaEditar.new"
            class="rounded-full shadow-md px-2 py-3"
          />
        </div>
        <div class="mt-4 flex flex-col">
          Confirmar nueva contraseña
          <input
            type="text"
            v-model="contraseñaEditar.newRe"
            class="rounded-full shadow-md px-2 py-3"
          />
        </div>
        <div
          class="mt-5 py-3 rounded-full bg-verde-1 text-white text-center w-full"
          @click="cambiarContraseña()"
        >
          Guardar
        </div>
      </div>
    </div>
    <div v-if="campoEditar.id === 'image'">
      <div class="grid grid-cols-3 max-w-md mx-auto">
        <img
          v-for="i in 8"
          :key="i"
          :class="[
            { 'opacity-40': valorEditar && valorEditar !== i },
            { 'border-verde-1 border-4': valorEditar && valorEditar === i },
            'foto object-cover max-h-36'
          ]"
          :src="'img/avatar/' + i + '.jpg'"
          alt=""
          @click="valorEditar = i"
        />
      </div>
      <div class="grid mx-auto max-w-md">
        <div
          class="mt-5 py-3  mx-auto rounded-full bg-verde-1 text-white text-center w-full"
          @click="guardarCampo(campoEditar.id)"
        >
          Guardar
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, toRefs } from "vue";
import store from "@/services/store";
import router from "@/router/router.js";
import iconChevronRight from "../Icons/Mas/iconChevronRight.vue";
import GoBack from "../elementos/GoBack.vue";
import { apiPutStore } from "@/services/routes.js";

export default {
  components: { iconChevronRight, GoBack },
  props: {
    local: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    console.log(props.local);
    let local = toRefs(props).local;
    let usuario = store.state.usuario;
    let pasoActivo = ref(1);
    let campoEditar = ref(null);
    let valorEditar = ref(null);
    let contraseñaEditar = ref({
      old: null,
      new: null,
      newRe: null
    });

    let logOut = () => {
      store.commit("logOut");
      router.push({ path: "login" });
    };

    let detalles = [
      { id: "nombre", nombre: "Nombre" },
      { id: "email", nombre: "Email" }
    ];

    let ajustes = [
      { id: "image", nombre: "Imagen de portada" },
      { id: "camContraseña", nombre: "Cambiar contraseña" },
      { id: "privacidad", nombre: "Privacidad y permisos" }
    ];

    const cambiarPaso = (paso, id) => {
      pasoActivo.value = paso;
      if (id) {
        if (paso === 2) {
          campoEditar.value = detalles.find(d => d.id === id);
        } else if (paso === 3) {
          campoEditar.value = ajustes.find(d => d.id === id);
        }
      }
    };

    const guardarCampo = id => {
      let obj = {
        ...local.value
      };
      console.log(local.value);
      obj[id] = valorEditar.value;
      fetch(apiPutStore(usuario.id), {
        method: "PUT",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(obj)
      }).then(() => {
        campoEditar.value = null;
        valorEditar.value = null;
        cambiarPaso(1);
      });
    };

    const cambiarContraseña = () => {};

    return {
      logOut,
      detalles,
      ajustes,
      cambiarPaso,
      pasoActivo,
      campoEditar,
      valorEditar,
      guardarCampo,
      contraseñaEditar,
      cambiarContraseña
    };
  }
};
</script>

<style>
.foto {
  width: 33.3vw;
  height: 33.3vw;
}
</style>
