<template>
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 529.235 431.396"
    enable-background="new 0 0 529.235 431.396"
    xml:space="preserve"
  >
    <path
      fill="#E4B134"
      d="M322.339,341.557c2.042-1.91,4.086-3.829,6.091-5.771c4.713-4.529,9.551-9.325,14.398-14.258
	c55.324-56.313,97.251-118.207,121.233-178.957c1.232-3.101,2.401-6.247,3.497-9.3c1.066-3,2.093-6.045,3.025-9.021
	c5.419-17.18,8.257-32.623,8.413-45.946c0.035-2.992-0.075-5.979-0.327-8.891c-0.263-2.996-0.676-5.862-1.225-8.526l-0.18-0.72
	c-0.757-3.406-1.757-6.654-3.002-9.657c-1.656-4.055-3.817-7.824-6.4-11.223l-0.631-0.85c-2.904-3.667-6.465-7.068-10.57-10.067
	c-11.227-8.202-26.478-13.821-46.623-17.129c-2.781-0.46-5.786-0.894-9.204-1.298l-1.813-0.236c-3.512-0.396-7.2-0.729-10.969-1.002
	c-0.905-0.061-1.816-0.113-2.725-0.16l-1.056-0.061c-5.142-0.302-10.356-0.486-15.466-0.557c-4.166-0.057-8.409-0.039-12.604,0.045
	l-2.475,0.065c-0.989,0.026-1.984,0.048-2.988,0.075c-2.341,0.082-4.693,0.172-7.064,0.283c-2.351,0.101-4.718,0.232-7.095,0.378
	l-0.324,0.021c-0.981,0.064-1.958,0.142-2.945,0.215l-2.807,0.204c-0.93,0.067-1.874,0.135-2.829,0.207l-0.772,0.063
	c-0.44,0.035-0.881,0.069-1.327,0.124l-1.194,0.106c-2.296,0.197-4.573,0.412-6.865,0.629l-0.711,0.074
	c-4.985,0.504-10.226,1.104-15.961,1.828c-56.969,7.182-104.769,21.489-142.065,42.534c-28.938,16.351-53.297,36.622-72.393,60.27
	c-1.819,2.255-3.382,4.251-4.784,6.104c-1.402,1.864-2.766,3.776-4.12,5.685c-11.9,16.869-21.766,35.768-29.307,56.147
	c-1.061,2.886-2.039,6.161-2.981,9.316c-0.926,3.096-1.835,6.386-2.696,9.765c-2.352,9.227-4.212,18.707-5.518,28.174
	c-0.305,2.247-0.577,4.51-0.821,6.773c-0.25,2.273-0.48,4.534-0.655,6.795l-0.067,0.706c-0.088,1.003-0.161,1.997-0.245,2.995
	c-0.313,4.56-0.515,9.237-0.574,13.926c-0.083,5.031-0.014,9.968,0.193,14.684c1.255,28.763,6.778,54.414,15.966,74.157
	c6.34,13.608,13.22,25.027,21.041,34.896c5.386,6.794,11.269,12.958,17.963,18.83c2.474,2.181,5.813,4.374,9.074,6.41
	c3.15,1.975,6.334,3.754,9.493,5.273c10.674,5.184,22.368,8.406,34.76,9.626c2.823,0.285,5.715,0.454,8.56,0.517
	c3.176,0.081,6.056,0.051,8.794-0.084c12.618-0.626,25.576-2.818,38.498-6.555c2.432-0.688,5.036-1.509,7.995-2.503
	c2.586-0.878,5.269-1.836,7.966-2.867c32.732-12.526,65.972-34.415,101.632-66.923C318.499,345.137,320.434,343.354,322.339,341.557
	z"
    />
    <g>
      <g>
        <path
          fill="#EE8484"
          d="M228.712,208.678c-13.708-33.742-44.4-63.646-45.699-64.902c-2.055-1.985-5.329-1.931-7.315,0.124
			c-1.986,2.054-1.932,5.329,0.122,7.315c0.303,0.295,30.434,29.676,43.304,61.357c0.817,2.01,2.754,3.229,4.796,3.229
			c0.65,0,1.309-0.123,1.946-0.381C228.513,214.342,229.787,211.324,228.712,208.678z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          fill="#EE8484"
          d="M247.375,144.391c-2.858,0-5.175,2.317-5.175,5.175v47.604c0,2.859,2.316,5.176,5.175,5.176
			c2.857,0,5.175-2.316,5.175-5.176v-47.604C252.548,146.708,250.232,144.391,247.375,144.391z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          fill="#EE8484"
          d="M336.865,162.622c-2.22-1.801-5.479-1.462-7.278,0.759c-12.854,15.847-22.299,31.088-28.071,45.297
			c-1.076,2.646,0.2,5.664,2.847,6.74c0.639,0.26,1.297,0.383,1.945,0.383c2.043,0,3.98-1.219,4.796-3.23
			c6.711-16.516,18.126-32.321,26.521-42.671C339.424,167.682,339.085,164.422,336.865,162.622z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          fill="#EE8484"
          d="M291.852,127.875c-2.834-0.369-5.429,1.631-5.797,4.465l-8.334,64.166
			c-0.368,2.832,1.631,5.428,4.465,5.797c0.226,0.027,0.45,0.043,0.673,0.043c2.561,0,4.786-1.9,5.124-4.51l8.335-64.164
			C296.687,130.839,294.686,128.243,291.852,127.875z"
        />
      </g>
    </g>
    <path
      fill="#FFFFFF"
      d="M400.424,245.809L366.52,210.51l-0.007-0.006c-0.004-0.004-0.009-0.008-0.013-0.012
	c-0.521-0.539-1.164-0.966-1.9-1.229l-16.522,4.717l-82.663,29.605l-82.664-29.605l-16.521-4.717
	c-0.735,0.264-1.38,0.69-1.901,1.229c-0.003,0.004-0.007,0.008-0.012,0.012l-0.006,0.006h-0.001l-33.903,35.299
	c-1.179,1.225-1.654,2.963-1.267,4.62c0.385,1.655,1.402,3.001,2.996,3.573l30.42,10.961v86.482c0,2.119,1.51,4.008,3.492,4.719
	l97.603,34.925c0.542,0.194,1.154,0.291,1.722,0.291c0.565,0,1.155-0.099,1.698-0.291l71.222-25.534
	c0.084-0.025,0.18-0.037,0.264-0.066l25.361-9.324c1.984-0.711,2.639-2.6,2.639-4.719V264.96L397.83,254
	c1.594-0.57,3.132-1.916,3.521-3.571C401.737,248.771,401.6,247.033,400.424,245.809z"
    />
    <path
      fill="#3F6A32"
      d="M404.609,244.289l-35.18-36.473l-0.007-0.006c-0.004-0.004-0.009-0.008-0.013-0.012
	c-0.54-0.557-1.208-0.998-1.973-1.27l-30.409-10.846c-2.691-0.961-5.652,0.443-6.611,3.135c-0.961,2.691,0.443,5.652,3.135,6.611
	l16.742,5.973l-85.772,30.59l-85.771-30.59l17.229-6.146c2.691-0.959,4.096-3.92,3.135-6.611c-0.96-2.691-3.92-4.094-6.612-3.135
	l-30.895,11.02c-0.763,0.271-1.432,0.713-1.973,1.27c-0.004,0.004-0.008,0.008-0.013,0.012l-0.006,0.006h-0.001l-35.179,36.473
	c-1.222,1.266-1.716,3.063-1.313,4.773c0.4,1.711,1.835,3.102,3.489,3.693l31.943,11.324v89.357c0,2.189,1.186,4.141,3.244,4.875
	l101.082,36.086c0.563,0.201,1.104,0.301,1.691,0.301s1.152-0.102,1.715-0.301l73.878-26.383c0.086-0.026,0.174-0.038,0.26-0.068
	l26.854-9.635c2.061-0.734,3.275-2.686,3.275-4.875v-89.36l31.908-11.323c1.654-0.59,2.978-1.98,3.38-3.691
	S405.83,245.555,404.609,244.289z M360.555,349.791l-23.594,8.41c-0.068,0.024-0.132,0.063-0.198,0.091l-72.218,25.737l-95.99-34.24
	v-82.018l19.824,7.076c0.576,0.205,1.154,0.303,1.731,0.303c2.125,0,4.115-1.322,4.869-3.438c0.96-2.691-0.445-5.652-3.137-6.611
	l-54.39-19.4l27.301-28.301l90.476,32.268l-27.3,28.303l-17.554-6.26c-2.691-0.961-5.652,0.443-6.611,3.135
	c-0.961,2.691,0.442,5.652,3.135,6.611l20.697,7.383c1.917,0.684,4.053,0.182,5.464-1.281l31.462-32.617l31.462,32.617
	c0.993,1.029,2.345,1.582,3.726,1.582c0.583,0,1.185-0.1,1.751-0.301l59.094-21.071V349.791z M301.116,277.971l-27.302-28.305
	l90.478-32.268l27.301,28.303L301.116,277.971z"
    />
    <g>
      <g>
        <path
          fill="#3F6A32"
          d="M264.524,273.754c-2.859,0-5.176,2.316-5.176,5.174v87.967c0,2.859,2.316,5.176,5.176,5.176
			c2.857,0,5.175-2.316,5.175-5.176v-87.967C269.699,276.07,267.382,273.754,264.524,273.754z"
        />
      </g>
    </g>
    <g>
      <path
        fill="#3F6A32"
        d="M427.858,49.425c-3.715,0-7.428-1.413-10.254-4.239c-5.654-5.654-5.654-14.854,0-20.507
		c5.65-5.651,14.852-5.653,20.506,0c5.654,5.654,5.654,14.853,0,20.507C435.284,48.012,431.571,49.425,427.858,49.425z
		 M427.858,30.434c-1.152,0-2.305,0.438-3.184,1.316c-1.754,1.754-1.754,4.609,0,6.363c1.756,1.755,4.607,1.756,6.363,0
		c1.754-1.754,1.754-4.608,0-6.363C430.161,30.874,429.009,30.434,427.858,30.434z"
      />
      <path
        fill="#3F6A32"
        d="M127.579,427.069c-3.713,0-7.426-1.414-10.252-4.24c-5.654-5.654-5.654-14.854,0-20.506
		c5.652-5.652,14.854-5.652,20.506,0c5.654,5.654,5.654,14.854,0,20.506C135.007,425.657,131.292,427.069,127.579,427.069
		L127.579,427.069z M127.579,408.079c-1.15,0-2.305,0.438-3.182,1.314c-1.754,1.754-1.754,4.609,0,6.365
		c1.756,1.754,4.607,1.754,6.365,0c1.754-1.754,1.754-4.609,0-6.365C129.884,408.517,128.731,408.079,127.579,408.079z"
      />
      <path
        fill="#3F6A32"
        d="M391.81,62.301c-1.279,0-2.559-0.488-3.535-1.464l-5.965-5.964l-5.965,5.964
		c-1.951,1.952-5.119,1.952-7.07,0c-1.953-1.953-1.953-5.119,0-7.071l9.5-9.5c1.951-1.951,5.119-1.951,7.07,0l9.5,9.5
		c1.953,1.953,1.953,5.119,0,7.071C394.37,61.813,393.091,62.301,391.81,62.301z"
      />
      <path
        fill="#3F6A32"
        d="M422.788,408.077c-1.279,0-2.561-0.488-3.535-1.465l-5.965-5.965l-5.965,5.965
		c-1.951,1.951-5.119,1.951-7.07,0c-1.953-1.953-1.953-5.119,0-7.07l9.5-9.5c1.951-1.953,5.119-1.953,7.07,0l9.5,9.5
		c1.953,1.953,1.953,5.119,0,7.07C425.347,407.587,424.067,408.077,422.788,408.077z"
      />
      <path
        fill="#3F6A32"
        d="M87.925,412.827c-1.277,0-2.559-0.488-3.533-1.465l-5.967-5.965l-5.965,5.965
		c-1.951,1.951-5.119,1.951-7.068,0c-1.953-1.953-1.953-5.119,0-7.07l9.5-9.5c1.949-1.953,5.117-1.953,7.068,0l9.5,9.5
		c1.953,1.953,1.953,5.119,0,7.07C90.485,412.337,89.204,412.827,87.925,412.827z"
      />
    </g>
    <g>
      <g>
        <path
          fill="#FFFFFF"
          d="M263.764,90.289l-10.678-2.165c-8.814-1.787-15.664-8.637-17.451-17.451l-2.164-10.678
			c-0.872-4.298-4.542-7.298-8.926-7.298c-4.385,0-8.055,3-8.925,7.297l-2.165,10.677c-1.787,8.814-8.637,15.664-17.451,17.451
			l-10.678,2.165c-4.295,0.871-7.297,4.541-7.297,8.925c0,4.384,3.002,8.054,7.297,8.926l10.678,2.165
			c8.814,1.787,15.664,8.637,17.451,17.451l2.165,10.678c0.87,4.297,4.54,7.297,8.925,7.297c4.384,0,8.055-3,8.926-7.297
			l2.164-10.678c1.787-8.814,8.637-15.664,17.449-17.451l10.68-2.165c4.295-0.872,7.296-4.541,7.296-8.925
			C271.06,94.83,268.061,91.16,263.764,90.289z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          fill="#FFFFFF"
          d="M372.457,104.619l-8.688-1.762c-7.173-1.454-12.746-7.027-14.2-14.199l-1.761-8.688
			c-0.71-3.497-3.696-5.939-7.263-5.939c-3.567,0-6.554,2.442-7.262,5.938l-1.762,8.688c-1.454,7.171-7.028,12.745-14.2,14.199
			l-8.688,1.762c-3.495,0.709-5.938,3.695-5.938,7.262c0,3.567,2.442,6.554,5.938,7.263l8.688,1.761
			c7.172,1.454,12.746,7.028,14.2,14.2l1.762,8.688c0.708,3.496,3.694,5.938,7.262,5.938c3.566,0,6.554-2.442,7.263-5.938
			l1.761-8.688c1.454-7.172,7.027-12.746,14.198-14.2l8.689-1.761c3.495-0.709,5.937-3.695,5.937-7.263
			C378.394,108.313,375.953,105.327,372.457,104.619z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          fill="#EE8484"
          d="M375.313,103.406l-9.812-1.989c-7.647-1.551-13.591-7.494-15.142-15.142l-1.989-9.812
			c-0.909-4.487-4.743-7.62-9.321-7.62c-4.577,0-8.41,3.133-9.32,7.621l-1.989,9.811c-1.55,7.647-7.493,13.591-15.142,15.142
			l-9.812,1.989c-4.487,0.909-7.62,4.742-7.62,9.32c0,4.579,3.133,8.412,7.62,9.321l9.812,1.989
			c7.647,1.55,13.592,7.493,15.142,15.142l1.989,9.812c0.91,4.486,4.743,7.62,9.32,7.62c4.578,0,8.412-3.134,9.321-7.62l1.989-9.812
			c1.551-7.648,7.494-13.592,15.142-15.142l9.812-1.989c4.487-0.909,7.62-4.742,7.62-9.321
			C382.934,108.148,379.8,104.316,375.313,103.406z M363.445,113.893c-11.731,2.378-20.85,11.496-23.229,23.228l-1.167,5.754
			l-1.166-5.754c-2.378-11.732-11.496-20.849-23.229-23.228l-5.753-1.166l5.753-1.166c11.732-2.379,20.85-11.497,23.229-23.229
			l1.166-5.753l1.167,5.753c2.378,11.731,11.496,20.85,23.229,23.229l5.753,1.166L363.445,113.893z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          fill="#EE8484"
          d="M271.456,89.023l-12.59-2.553c-10.394-2.107-18.471-10.184-20.578-20.577l-2.552-12.59
			c-1.028-5.068-5.355-8.606-10.524-8.606c-5.17,0-9.497,3.538-10.523,8.604l-2.553,12.59c-2.107,10.393-10.185,18.47-20.578,20.577
			l-12.59,2.553c-5.065,1.027-8.604,5.354-8.604,10.524s3.539,9.497,8.604,10.525l12.59,2.552
			c10.394,2.107,18.471,10.184,20.578,20.577l2.553,12.591c1.026,5.066,5.354,8.604,10.523,8.604c5.169,0,9.497-3.538,10.524-8.604
			l2.552-12.591c2.107-10.393,10.185-18.47,20.576-20.577l12.592-2.552c5.065-1.028,8.604-5.355,8.604-10.524
			C280.06,94.377,276.522,90.05,271.456,89.023z M269.398,99.927l-12.59,2.553c-14.478,2.936-25.729,14.187-28.663,28.663
			l-2.553,12.591c-0.047,0.227-0.063,0.312-0.381,0.312c-0.317,0-0.336-0.085-0.381-0.312l-2.553-12.591
			c-2.935-14.477-14.187-25.729-28.662-28.663l-12.591-2.553c-0.228-0.046-0.312-0.063-0.312-0.381c0-0.317,0.084-0.335,0.312-0.38
			l12.591-2.553c14.477-2.936,25.728-14.187,28.662-28.663l2.553-12.59c0.045-0.227,0.063-0.312,0.381-0.312
			c0.318,0,0.336,0.085,0.381,0.312l2.553,12.59c2.936,14.477,14.186,25.729,28.663,28.663l12.59,2.553
			c0.227,0.045,0.312,0.063,0.312,0.38C269.71,99.865,269.625,99.882,269.398,99.927z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
