<template>
  <div
    class="fixed bottom-0 h-14 bg-white w-full grid grid-cols-4 text-center z-50"
  >
    <div
      v-for="opcion in opcionesComp"
      :key="opcion.id"
      class="flex items-center justify-center"
    >
      <router-link
        :to="{ name: opcion.route() }"
        exact-active-class="text-verde-1"
        class="flex flex-col items-center"
      >
        <component :is="opcion.icon"></component>
        {{ opcion.nombre }}
      </router-link>
    </div>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import store from "@/services/store";

import iconHome from "@/components/Icons/Header/iconHome";
import iconFavoritos from "@/components/Icons/Header/iconFavoritos";
import iconMas from "@/components/Icons/Header/iconMas";
import iconTips from "@/components/Icons/Header/iconTips";

export default {
  components: {
    iconHome,
    iconFavoritos,
    iconMas,
    iconTips
  },
  setup() {
    let usuario = store.state.usuario;

    let usuarioLogueado = usuario.logueado;
    let esLocal = ref(false);

    let opciones = {
      invitado: [
        {
          id: "inicio",
          nombre: "Inicio",
          route: () => "Locales",
          icon: "iconHome"
        },
        {
          id: "favoritos",
          nombre: "Favoritos",
          route: () => "Login",
          icon: "iconFavoritos"
        },
        {
          id: "tips",
          nombre: "Tips",
          route: () => "Login",
          icon: "iconTips"
        },
        {
          id: "mas",
          nombre: "Más",
          route: () => "Login",
          icon: "iconMas"
        }
      ],
      logueado: [
        {
          id: "inicio",
          nombre: "Inicio",
          route: () => "Locales",
          icon: "iconHome"
        },
        {
          id: "favoritos",
          nombre: "Favoritos",
          route: () => "favoritos",
          icon: "iconFavoritos"
        },
        {
          id: "tips",
          nombre: "Tips",
          route: () => "Tips",
          icon: "iconTips"
        },
        {
          id: "mas",
          nombre: "Más",
          route: () => "mas",
          icon: "iconMas"
        }
      ],
      local: [
        {
          id: "inicio",
          nombre: "Inicio",
          route: () => "Home",
          icon: "iconHome"
        },
        {
          id: "favoritos",
          nombre: "Favoritos",
          route: () => "favoritos",
          icon: "iconFavoritos"
        },
        {
          id: "tips",
          nombre: "Tips",
          route: () => "Tips",
          icon: "iconTips"
        },
        {
          id: "mas",
          nombre: "Más",
          route: () => "mas",
          icon: "iconMas"
        }
      ]
    };

    let opcionesComp = computed(() => {
      if (usuarioLogueado && esLocal.value) {
        return opciones["local"];
      } else if (usuarioLogueado) {
        return opciones["logueado"];
      } else {
        return opciones["invitado"];
      }
    });

    return {
      usuarioLogueado,
      esLocal,
      opcionesComp,
      usuario
    };
  }
};
</script>

<style></style>
