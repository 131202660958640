<template>
  <div
    class="flex flex-col items-center bg-gris-3 h-screen w-full max-w-lg mx-auto px-4 pt-10 relative"
  >
    <div class="absolute top-4 h-full overflow-hidden" style="height:97%">
      <img src="fondo_1.svg" class="z-0" alt="" />
      <img src="fondo_2.svg" class="z-0" alt="" />
    </div>
    <div class="z-10">
      <img class="mx-auto w-full" src="logo.svg" alt="" />
    </div>
    <form class="w-full mt-8 space-y-6 z-10" @submit.prevent="login">
      <span class="text-red-500">{{ error }}</span>
      <div>
        <div v-for="campo in campos" :key="campo.id" class="text-left mb-4">
          <label :for="campo.id" class="font-bold">{{ campo.titulo }}</label>
          <input
            :id="campo.id"
            :name="campo.id"
            :type="campo.type"
            :autocomplete="campo.autocomplete"
            :required="campo.required"
            v-model="campo.value"
            :class="[
              'appearance-none rounded-full relative block w-full px-3 py-2 text-gray-900 shadow-md border border-gris-2 focus:outline-none focus:ring-verde-1 focus:border-verde-1 focus:z-10 sm:text-sm'
            ]"
            :placeholder="campo.placeholder"
          />
        </div>
      </div>

      <button
        type="submit"
        class="w-full rounded-full py-2 mt-8 bg-verde-1 text-white text-center text-xl apparance-none focus:outline-none"
      >
        Ingresar
      </button>

      <div
        class="mt-2 font-bold text-negro-1 text-xl w-full text-center"
        @click="goToRegistrarse"
      >
        Crear cuenta
      </div>

      <div class="mt-8 w-full mx-auto text-xs pb-5">
        ¿Olvidaste tu contraseña? Click
        <span @click="openAlert('Falta implementar')" class="underline">
          acá
        </span>
      </div>
    </form>
  </div>
</template>

<script>
import { ref } from "vue";
import { apiLogin, apiMe } from "@/services/routes.js";
import router from "@/router/router.js";
import store from "@/services/store";

export default {
  setup() {
    let error = ref("");

    let campos = ref([
      {
        titulo: "Email",
        placeholder: "Email",
        value: "",
        autocomplete: "email",
        required: true,
        type: "email",
        id: "email"
      },
      {
        titulo: "Contraseña",
        placeholder: "Contraseña",
        value: "",
        autocomplete: false,
        required: true,
        type: "password",
        id: "password"
      }
    ]);

    let login = async () => {
      error.value = "";
      let details = {
        username: campos.value.find(c => c.id === "email").value,
        password: campos.value.find(c => c.id === "password").value
      };

      const formBody = Object.keys(details)
        .map(
          key =>
            encodeURIComponent(key) + "=" + encodeURIComponent(details[key])
        )
        .join("&");

      let res = await fetch(apiLogin(), {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
        },
        body: formBody
      });
      res = await res.json();
      if (res.detail) {
        error.value = "Email o contraseña incorrecto.";
      } else {
        var bearer = "Bearer " + res.access_token;
        let me = await fetch(apiMe(), {
          method: "GET",
          headers: {
            Authorization: bearer
          }
        });
        me = await me.json();
        store.commit("setUsuario", {
          id: me.id,
          nombre: me.first_name,
          auth: bearer,
          userType: me.user_type
        });
        router.push({ name: "Locales" });
      }
    };

    let openAlert = mensaje => {
      alert(mensaje);
    };

    let goToRegistrarse = () => {
      router.push({ path: "registro" });
    };

    return { campos, login, error, openAlert, goToRegistrarse };
  }
};
</script>

<style></style>
