<template>
  <go-back-bar
    :titulo="'Blog'"
    :arrow="false"
    @go-back="goToLocales"
  ></go-back-bar>

  <div class="flex flex-col text-left p-4 max-w-lg mx-auto">
    <span class="font-bold text-negro-1 text-xl">
      Blog de Provecho
    </span>
    <span class="text-md text-negro-1 leading-5">
      Te contamos las últimas novedades de Provecho y te damos consejos sobre
      cómo reducir al máximo el desperdicio de alimentos.
    </span>
  </div>
  <div class="masonry-container mx-auto px-4 max-w-lg">
    <div
      v-for="post in out"
      :key="post.id"
      class="mb-4 w-full"
      style="break-inside: avoid-column;"
    >
      <div class="border rounded shadow-md">
        <template v-if="post.mediaType === 'IMAGE'">
          <img
            class="w-full h-36"
            :src="post.mediaUrl"
            alt=""
            style="object-fit: cover;"
          />
        </template>
        <template v-if="post.mediaType === 'VIDEO'">
          <video class="w-48 h-28" controls>
            <source :src="post.mediaUrl" type="video/mp4" />
          </video>
        </template>

        <div class="p-4 text-bordo-1 text-xs text-left">
          {{ post.caption }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Instagram } from "instagram-api-js-client";
import { onMounted, ref } from "vue";
import GoBackBar from "../components/elementos/GoBack.vue";

export default {
  components: {
    GoBackBar
  },
  setup() {
    let posteos = ref([]);
    let out = ref([]);

    onMounted(async () => {
      let instagram = new Instagram(
        "IGQVJYbWk1UVdHSVN3S1RfcE9FNTRMOXUzTWQ3aGdhc1g0V2Q5bFhJV1BkeVB2ZADBKS0l3T05Wc0M3ZAWJFZA2dXemJEdGYtMmRJcU14V1d0ZAFlJZAC1udDhjbVF2UXV6VkUzLWZAHUU9MbHVVdzJfWGVENgZDZD",
        {
          limit: 2,
          fetchChildren: true,
          allowUnsupportedType: true,
          flatten: false
        }
      );

      // const user = await instagram.fetchSelf();
      for await (const item of instagram.mediaIterator()) {
        posteos.value.push(item);
      }

      console.log("iterateMedia", posteos.value);

      const cols = 3;
      let col = 0;
      while (col < cols) {
        for (let i = 0; i < posteos.value.length; i += cols) {
          let _val = posteos.value[i + col];
          if (_val !== undefined) out.value.push(_val);
        }
        col++;
      }
    });

    return { posteos, out };
  }
};
</script>

<style>
.masonry-container {
  column-count: 2;
}
@media only screen and (max-width: 454px) {
  .masonry-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
</style>
