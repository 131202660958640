<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 183.59 149.65">
    <g id="Group_258" data-name="Group 258" transform="translate(0 0)">
      <path
        id="Path_344"
        data-name="Path 344"
        d="M77.14,22.28c-.9.6-1.8,1.2-2.695,1.817-2.089,1.431-4.243,2.956-6.409,4.532-24.722,18-44.679,38.978-57.707,60.665C9.661,90.4,9.014,91.528,8.4,92.625,7.8,93.7,7.217,94.8,6.672,95.88A62.786,62.786,0,0,0,.534,112.9c-.2,1.141-.352,2.29-.441,3.418a28.843,28.843,0,0,0-.075,3.335l.023.287a22.642,22.642,0,0,0,.532,3.881,19.144,19.144,0,0,0,1.732,4.7l.186.364a21.08,21.08,0,0,0,3.4,4.519c3.768,3.848,9.238,6.966,16.725,9.511,1.033.352,2.154.709,3.434,1.081l.679.206c1.317.375,2.7.736,4.127,1.081q.513.121,1.031.234l.4.09c1.945.443,3.926.845,5.874,1.2,1.589.287,3.211.55,4.819.784l.95.133,1.146.161q1.35.177,2.718.341c.9.111,1.817.212,2.734.307l.126.012c.38.038.757.07,1.139.106l1.086.1,1.093.1.3.025c.171.015.342.03.515.037l.463.035c.89.071,1.774.134,2.663.2l.276.017c1.936.124,3.977.229,6.214.317,22.225.88,41.4-1.545,56.989-7.214a97.051,97.051,0,0,0,31.5-18.423c.839-.746,1.563-1.409,2.217-2.028s1.3-1.267,1.936-1.91a94.753,94.753,0,0,0,14.77-19.589c.588-1.035,1.171-2.224,1.731-3.37.551-1.124,1.108-2.323,1.652-3.56a99.191,99.191,0,0,0,3.9-10.414q.39-1.259.745-2.536c.241-.853.472-1.7.683-2.554l.07-.266c.1-.377.188-.752.284-1.128.41-1.722.784-3.5,1.106-5.284.352-1.917.639-3.808.86-5.623,1.35-11.07.872-21.223-1.382-29.351a67.148,67.148,0,0,0-5.82-14.672,55.39,55.39,0,0,0-5.665-8.337,27.9,27.9,0,0,0-3.06-3.026,38.012,38.012,0,0,0-3.292-2.619,39.587,39.587,0,0,0-12.669-5.889c-1.06-.289-2.154-.537-3.237-.742-1.209-.233-2.31-.4-3.365-.527a65.493,65.493,0,0,0-15.127.055c-.973.108-2.02.256-3.215.447-1.043.17-2.129.367-3.225.588-13.3,2.7-27.4,8.953-43.091,19.105Q78.406,21.43,77.14,22.28Z"
        transform="translate(0 0)"
        fill="#e4b134"
      />
      <path
        id="Path_345"
        data-name="Path 345"
        d="M386.03,231.88a8.786,8.786,0,0,0,2.643-6.249v-8.238a2.241,2.241,0,0,0-2.232-2.193,2.282,2.282,0,0,0-1.6.64,2.216,2.216,0,0,0-.682,1.552v8.238a4.67,4.67,0,0,1-1.3,3.263,4.477,4.477,0,0,1-3.151,1.395H373.9a2.008,2.008,0,0,1-1.876-2.091V206.564a5.188,5.188,0,0,0-5.167-5.105,5.233,5.233,0,0,0-3.668,1.5,5.162,5.162,0,0,0-1.571,3.6v40.722h10.407V236.473a1.95,1.95,0,0,1,1.876-2.022h5.818A8.906,8.906,0,0,0,386.03,231.88Z"
        transform="translate(-236.172 -131.573)"
        fill="#fff"
      />
      <path
        id="Path_346"
        data-name="Path 346"
        d="M352.844,63.2a12.66,12.66,0,1,0,12.66,12.66A12.614,12.614,0,0,0,352.844,63.2Z"
        transform="translate(-222.18 -41.278)"
        fill="#fff"
      />
      <path
        id="Path_347"
        data-name="Path 347"
        d="M354.685,55.179h-4.326a16.673,16.673,0,0,0-3.435-8.382l3.053-3.1a1.96,1.96,0,0,0,0-2.772,1.936,1.936,0,0,0-2.751-.011l-3,3.047a16.2,16.2,0,0,0-8.241-3.441V36.2a2.086,2.086,0,0,0-4.163,0v4.325a16.151,16.151,0,0,0-8.226,3.435l-3.014-3.052a1.912,1.912,0,0,0-2.732,0,1.936,1.936,0,0,0,.01,2.751l3.058,3.138a16.664,16.664,0,0,0-3.43,8.382h-4.326a1.908,1.908,0,1,0,0,3.816h4.326a16.233,16.233,0,0,0,3.435,8.254l-3.053,3.032a1.94,1.94,0,1,0,2.751,2.735L323.6,69.96a16.15,16.15,0,0,0,8.227,3.433v4.325a2.086,2.086,0,0,0,4.163,0V73.393a16.2,16.2,0,0,0,8.241-3.435l3.024,3.053a1.938,1.938,0,1,0,2.73-2.751l-3.056-3.01A16.235,16.235,0,0,0,350.359,59h4.326a1.908,1.908,0,1,0,0-3.816Zm-20.758,14.44a12.66,12.66,0,1,1,12.66-12.659A12.615,12.615,0,0,1,333.926,69.619Z"
        transform="translate(-203.262 -22.374)"
        fill="#ee8484"
      />
      <path
        id="Path_348"
        data-name="Path 348"
        d="M198.958,339.566H86.563a8.673,8.673,0,0,0,0,17.345h112.4a8.673,8.673,0,0,0,0-17.345Z"
        transform="translate(-50.908 -221.771)"
        fill="#fff"
      />
      <path
        id="Path_349"
        data-name="Path 349"
        d="M169.429,154.559V46.848c0-4.757-4.65-8.615-10.407-8.615s-10.407,3.857-10.407,8.615V157.681"
        transform="translate(-97.061 -24.97)"
        fill="#fff"
      />
      <path
        id="Path_350"
        data-name="Path 350"
        d="M222.613,182.289v-22.57c0-2.577,2.963-4.666,6.764-4.666s6.765,2.089,6.765,4.666v27.773s-.607,11.708-30.527,11.708V184.37S222.613,186.972,222.613,182.289Z"
        transform="translate(-134.288 -101.265)"
        fill="#fff"
      />
      <path
        id="Path_351"
        data-name="Path 351"
        d="M97.716,111.939V93.8c0-2.071-2.5-3.75-5.55-3.75s-5.551,1.679-5.551,3.75V116.12s.347,9.408,24.63,9.408V113.611S97.716,115.7,97.716,111.939Z"
        transform="translate(-56.569 -58.814)"
        fill="#fff"
      />
      <path
        id="Path_352"
        data-name="Path 352"
        d="M169.389,77.936h3a1.946,1.946,0,1,1,0,3.891h-3v20.787h7.989a2.46,2.46,0,0,0,2.435-2.434V81.82a9,9,0,1,1,18.007,0v4.774a1.946,1.946,0,0,1-3.891,0V81.82a5.112,5.112,0,0,0-10.224,0v18.361a6.133,6.133,0,0,1-.486,2.405,6.344,6.344,0,0,1-5.841,3.921h-9.931a1.953,1.953,0,0,1-1.949-1.949v-59.8a8.893,8.893,0,0,0-17.786,0v11.2a1.949,1.949,0,1,1-3.9,0v-11.2a12.791,12.791,0,0,1,25.429-1.949h3.148a1.946,1.946,0,1,1,0,3.891h-3V60.37h3a1.946,1.946,0,1,1,0,3.891h-3Z"
        transform="translate(-93.925 -20.879)"
        fill="#3f6a32"
      />
      <path
        id="Path_353"
        data-name="Path 353"
        d="M202.608,174.46a11.391,11.391,0,0,0-8.113-3.314h-7.078v-9.019h3.878a12.66,12.66,0,0,0,12.773-12.636v-8.239a6.071,6.071,0,1,0-12.141,0v8.239c0,.331-.3.5-.631.5h-3.878V130.424a9.019,9.019,0,0,0-18.039,0v9.5h-2.961a1.908,1.908,0,1,0,0,3.816h2.961v14.223h-2.961a2.086,2.086,0,0,0,0,4.163h2.961v9.019H151.322a1.908,1.908,0,1,0,0,3.816h43.173a7.58,7.58,0,0,1,7.591,7.565,7.7,7.7,0,0,1-2.229,5.413,7.617,7.617,0,0,1-5.362,2.286H84.6A7.73,7.73,0,0,1,77,182.549a7.62,7.62,0,0,1,7.591-7.587H142.51a2.03,2.03,0,0,0,2.02-1.967,1.911,1.911,0,0,0-1.863-1.959v.11H123.24V155.189H131.2a20.487,20.487,0,0,0,14.447-6.012,20.59,20.59,0,0,0,4.457-6.637,20.243,20.243,0,0,0,1.579-7.806V129.96a1.908,1.908,0,1,0-3.816,0v4.774a16.688,16.688,0,0,1-4.942,11.688,16.835,16.835,0,0,1-5.391,3.649,16.5,16.5,0,0,1-6.333,1.3h-9.931a1.789,1.789,0,0,0-1.848,1.854v17.919H101.386V121.78a1.847,1.847,0,0,0-1.8-1.975H91.68a12.6,12.6,0,0,1-9.026-3.732,12.93,12.93,0,0,1-2.821-4.145,12.79,12.79,0,0,1-1-4.874V92.437a3.723,3.723,0,0,1,3.72-3.671A3.541,3.541,0,0,1,85.1,89.841a3.619,3.619,0,0,1,1.021,2.6v14.617a5.448,5.448,0,0,0,.48,2.067,5.588,5.588,0,0,0,5.077,3.4h7.908a1.856,1.856,0,0,0,1.8-1.98v-11.2a1.908,1.908,0,1,0-3.816,0V108.7H91.68a1.538,1.538,0,0,1-1.395-1.651V92.437a7.632,7.632,0,0,0-15.264,0v14.617A16.514,16.514,0,0,0,91.68,123.621h5.89v11.448H94.645a1.908,1.908,0,1,0,0,3.816H97.57v14.57H94.645a1.908,1.908,0,1,0,0,3.816H97.57v13.876H84.6a11.448,11.448,0,0,0,0,22.9h109.9a11.464,11.464,0,0,0,8.113-19.582ZM183.6,160.333v10.813H173.194V130.424a5.231,5.231,0,0,1,5.194-5.105,5.219,5.219,0,0,1,3.662,1.5,5.141,5.141,0,0,1,1.551,3.6v21.634a2.008,2.008,0,0,0,1.876,2.091h5.819a4.477,4.477,0,0,0,3.151-1.395,4.669,4.669,0,0,0,1.3-3.263v-8.238a2.245,2.245,0,0,1,2.244-2.193,2.268,2.268,0,0,1,1.584.64,2.21,2.21,0,0,1,.681,1.552v8.238a8.785,8.785,0,0,1-2.643,6.249,8.906,8.906,0,0,1-6.314,2.571h-5.819A1.95,1.95,0,0,0,183.6,160.333Z"
        transform="translate(-47.75 -55.433)"
        fill="#3f6a32"
      />
      <g
        id="Group_257"
        data-name="Group 257"
        transform="translate(17.236 4.296)"
      >
        <path
          id="Path_354"
          data-name="Path 354"
          d="M216.164,22.438a5.028,5.028,0,1,1,3.556-1.471A5.013,5.013,0,0,1,216.164,22.438Zm0-6.588a1.561,1.561,0,1,0,1.1.457A1.558,1.558,0,0,0,216.164,15.85Z"
          transform="translate(-155.129 -12.383)"
          fill="#3f6a32"
        />
        <path
          id="Path_355"
          data-name="Path 355"
          d="M110.4,400.022a1.56,1.56,0,1,0,1.1.456A1.557,1.557,0,0,0,110.4,400.022Z"
          transform="translate(-88.321 -265.551)"
          fill="#3f6a32"
        />
        <path
          id="Path_356"
          data-name="Path 356"
          d="M173.907,41.51A1.731,1.731,0,0,1,172.68,41l-2.069-2.069L168.542,41a1.734,1.734,0,1,1-2.453-2.453l3.3-3.3a1.735,1.735,0,0,1,2.453,0l3.3,3.3a1.734,1.734,0,0,1-1.226,2.961Z"
          transform="translate(-125.378 -26.988)"
          fill="#3f6a32"
        />
        <path
          id="Path_357"
          data-name="Path 357"
          d="M458.873,387.285a1.728,1.728,0,0,1-1.226-.508l-2.069-2.069-2.069,2.069a1.734,1.734,0,0,1-2.453-2.453l3.3-3.3a1.734,1.734,0,0,1,2.453,0l3.3,3.3a1.735,1.735,0,0,1-1.226,2.961Z"
          transform="translate(-311.49 -252.814)"
          fill="#3f6a32"
        />
        <path
          id="Path_358"
          data-name="Path 358"
          d="M58.011,392.035a1.728,1.728,0,0,1-1.226-.508l-2.07-2.069-2.069,2.069a1.734,1.734,0,0,1-2.452-2.453l3.3-3.3a1.733,1.733,0,0,1,2.452,0l3.3,3.3a1.735,1.735,0,0,1-1.227,2.961Z"
          transform="translate(-49.686 -255.917)"
          fill="#3f6a32"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
