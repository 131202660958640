<template>
  <div class="w-full bg-verde-1 text-white rounded-full py-2 px-4">
    <slot />
  </div>
</template>

<script>
export default {};
</script>

<style></style>
