<template>
  <go-back-bar titulo="Registro" @go-back="goToLogin" />

  <div
    class="pt-10 w-full relative flex flex-col items-center max-w-lg mx-auto px-4 "
  >
    <header-registro />
    <div class="z-10 mt-5">
      <span class="font-bold text-negro-1">¿Qué vas a hacer en Provecho?</span>
    </div>

    <div class="mt-8 flex justify-center w-full z-10">
      <router-link
        :to="{ name: 'RegistroPersona' }"
        class="text-sm border-b-4 border-transparent font-bold hover:text-bordo-1 transition duration-400"
      >
        <div
          class="border p-4 mx-2 text-t-negro-1 rounded cursor-pointer bg-white shadow-lg rounded-lg"
        >
          <svg-comprar class="w-32 h-32" />
          <span class="font-bold text-xl">Comprar</span>
        </div>
      </router-link>
      <router-link
        :to="{ name: 'RegistroLocal' }"
        class="text-sm border-b-4 border-transparent font-bold hover:text-bordo-1 transition duration-400"
      >
        <div
          class="border p-4 mx-2 text-t-negro-1 rounded cursor-pointer bg-white shadow-lg rounded-lg"
        >
          <svg-vender class="w-32 h-32" />
          <span class="font-bold text-xl">Vender</span>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import GoBackBar from "@/components/elementos/GoBack.vue";
import SvgComprar from "../components/Icons/svgComprar.vue";
import SvgVender from "../components/Icons/svgVender.vue";
import router from "@/router/router.js";
import HeaderRegistro from "../components/Registro/HeaderRegistro.vue";

export default {
  components: {
    GoBackBar,
    SvgComprar,
    SvgVender,
    HeaderRegistro
  },
  setup() {
    const goToLogin = () => {
      router.push({ path: "login" });
    };

    return { goToLogin };
  }
};
</script>

<style></style>
