import { createApp } from "vue";
import App from "./App.vue";
import router from "./router/router";
import store from "./services/store";

import "./index.css";
require("typeface-karla");

createApp(App)
  .use(router)
  .use(store)
  .mount("#app");
