<template>
  <div>
    <div>
      <div
        class="w-100 bg-white mb-4 py-2 px-4 border-t border-b border-gris-2 text-left"
      >
        <div class="text-verde-1">
          Sobre los pack sorpresa:
        </div>
        <div class="border-b border-gris-2 w-auto mx-auto mt-1"></div>
        <div class="flex my-4">
          <div class="mx-4">
            <div
              class="rounded-full w-10 h-10 flex justify-center items-center bg-azul-1 text-white "
            >
              <icon-bag class="w-7 h-7" />
            </div>
          </div>
          <div class="text-gris-1 leading-5 w-2/3">
            Pueden ser distintas pero su precio es el mismo para todas.
          </div>
        </div>
        <div class="border-b border-gris-2 w-auto mx-auto mt-1"></div>
        <div class="flex my-4">
          <div class="mx-4">
            <div
              class="rounded-full w-10 h-10 flex justify-center items-center bg-azul-1 text-white "
            >
              <icon-time class="w-7 h-7" />
            </div>
          </div>
          <div class="text-gris-1 leading-5 w-2/3">
            Pasadas las 24hs Provecho las da de baja automáticamente.
          </div>
        </div>
      </div>

      <div
        class="w-100 bg-white mb-4 pt-2 pb-4 px-4 border-t border-b border-gris-2 text-left"
      >
        <div class="border-b border-gris-2 w-auto mx-auto mt-1"></div>

        <div class="py-1">
          <div class="flex justify-left">
            <div class="text-verde-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                class="w-5 h-5"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4"
                />
              </svg>
            </div>

            <div class="pl-2 font-semibold">
              Cantidad de packs sorpresa
            </div>
          </div>
          <div class="text-sm text-negro-1">
            ¿Cuántos packs sorpresa vas a vender hoy?
          </div>
          <div class="border-b border-gris-2 w-auto mx-auto mt-1"></div>

          <div>
            <input-cantidad
              class="mt-2 text-xl"
              :min="basket.vendidos"
              v-model:valor="basket.cantidad"
            />
          </div>
        </div>
        <div class="border-b border-gris-2 w-auto mx-auto mt-1"></div>
      </div>

      <div
        class="w-100 bg-white mb-4 pt-2 pb-4 px-4 border-t border-b border-gris-2 text-left"
      >
        <div class="border-b border-gris-2 w-auto mx-auto mt-1"></div>

        <div class="py-1">
          <div class="flex justify-left">
            <div class="text-verde-1 rounded-full">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                class="w-5 h-5"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </div>

            <div class="pl-2 font-semibold">
              Precio
            </div>
          </div>
          <div class="border-b border-gris-2 w-auto mx-auto mt-1"></div>

          <div class="mt-1 relative rounded-md shadow-sm">
            <div
              class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
            >
              <span class="text-gray-500 sm:text-sm text-md">
                $
              </span>
            </div>
            <input
              type="text"
              name="price"
              id="price"
              v-model="basket.precio"
              class="focus:outline-none block w-full pl-7 pr-12 sm:text-sm text-md border-gray-300 rounded-md"
              placeholder="0.00"
            />
          </div>
        </div>
        <div class="border-b border-gris-2 w-auto mx-auto mt-1"></div>
      </div>

      <div class="bg-white w-full py-4 z-10">
        <div
          class="rounded-full flex justify-center text-white mx-auto py-2 bg-verde-1 w-4/5"
          @click="cambiarPaso(2)"
        >
          Vender
        </div>
      </div>
    </div>

    <tooltip-bottom v-if="pasoActivo === 2" @onClose="pasoActivo = 1">
      <span class="text-xl font-semibold">
        Vas a vender
      </span>

      <span>Packs sorpresa: {{ basket.cantidad }}</span>
      <span>Precio: ${{ basket.precio }} c/u</span>

      <div
        class="rounded-full text-white mx-auto mt-4 py-2 bg-verde-1 w-4/5"
        @click="publicarCaja"
      >
        Publicar
      </div>
    </tooltip-bottom>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";

import {
  apiGetLocalBasket,
  apiPutLocalBasket,
  apiPostLocalBasket
} from "@/services/routes.js";

import InputCantidad from "../elementos/inputCantidad.vue";
import IconBag from "../Icons/iconBag.vue";
import IconTime from "../Icons/iconTime.vue";
import TooltipBottom from "../elementos/TooltipBottom.vue";

export default {
  components: { InputCantidad, IconBag, IconTime, TooltipBottom },
  emits: ["goBack"],
  setup(props, { emit }) {
    let error = ref(null);
    let pasoActivo = ref(1);
    let localId = 1;
    let idBasketPrecargado = ref(null);
    let basket = ref({
      cantidad: 1,
      precio: null,
      vendidos: 0
    });
    onMounted(() => {
      fetchLocales();
    });

    const fetchLocales = async () => {
      let res = await fetch(apiGetLocalBasket(localId));
      res = await res.json();
      if (res.baskets.length > 0) {
        idBasketPrecargado.value = res.baskets[0].id;
        basket.value.cantidad = res.baskets[0].qty;
        basket.value.precio = res.baskets[0].price;
        basket.value.vendidos = res.baskets[0].sold;
      }
    };

    const publicarCaja = () => {
      if (!basket.value.precio) {
        error.value = "Error: Caja sin precio";
      } else {
        error.value = null;

        if (idBasketPrecargado.value) {
          fetch(apiPutLocalBasket(idBasketPrecargado.value), {
            method: "PUT",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify({
              price: basket.value.precio,
              qty: basket.value.cantidad,
              store_id: localId,
              description: "",
              date: new Date().toISOString().substring(0, 10)
            })
          }).then(() => {
            goToHomeLocal();
          });
        } else {
          fetch(apiPostLocalBasket(), {
            method: "POST",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify({
              price: basket.value.precio,
              qty: basket.value.cantidad,
              store_id: localId,
              description: "",
              date: new Date().toISOString().substring(0, 10)
            })
          }).then(() => {
            goToHomeLocal();
          });
        }
      }
    };

    const cambiarPaso = paso => {
      pasoActivo.value = paso;
    };
    const goToHomeLocal = () => {
      pasoActivo.value = 1;
      emit("goBack");
    };

    return {
      basket,
      publicarCaja,
      idBasketPrecargado,
      error,
      pasoActivo,
      cambiarPaso
    };
  }
};
</script>

<style></style>
