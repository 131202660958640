<template>
  <div>
    <div
      class="text-verde-1 pt-8 flex flex-col items-center bg-white h-screen absolute w-full top-0 z-50 px-2"
    >
      <div class="flex flex-wrap flex-col w-4/5">
        <span class="text-4xl font-bold">¡Listo!</span>
        <span class="text-2xl font-bold"
          >Gracias por aprovechar <br />esta comida</span
        >
      </div>

      <div>
        <icon-compra-realizada class="w-36 h-36"></icon-compra-realizada>
      </div>
      <div class="text-lg text-verde-1 font-semibold mt-4 px-2">
        <div>
          Tu pedido ya está reservado.
        </div>
        <div>
          Recordá el horario de retiro.
        </div>
      </div>

      <div
        class="bg-verde-1 text-white rounded-full px-4 py-2 mt-4 text-xl font-bold"
        @click="goToListado"
      >
        Volver al listado
      </div>
    </div>
  </div>
</template>

<script>
import { useRoute } from "vue-router";
import { ref, onMounted } from "vue";
import router from "@/router/router.js";

import { apiGetSale, apiGetLocalBasket } from "@/services/routes.js";
import IconCompraRealizada from "../components/Icons/iconCompraRealizada.vue";

export default {
  components: { IconCompraRealizada },
  setup() {
    const ticket = ref({});
    const local = ref({});
    let prepararDescarga = ref(false);
    onMounted(() => {
      fetchTicket().then(() => {
        fetchLocal(ticket.value._basket.store_id);
      });
    });

    const route = useRoute();
    const idTicket = route.params.id;
    const fetchTicket = async () => {
      let res = await fetch(apiGetSale(idTicket));
      res = await res.json();
      ticket.value = res;
    };
    const fetchLocal = async id => {
      let res = await fetch(apiGetLocalBasket(id));
      res = await res.json();
      local.value = res.store;
    };

    const goToListado = () => {
      router.push({ name: "Locales", params: { compra: true } });
    };

    return { ticket, local, prepararDescarga, goToListado };
  }
};
</script>

<style></style>
