<template>
  <div :class="[{ 'bg-rojo-1': active, 'bg-gris-1': !active }, 'rounded-full py-0.5']">
    <icon-corazon
      :class="[
        { 'text-white': active, 'text-transparent': !active },
        'w-full h-full'
      ]"
      @click="toggleFavorito"
    ></icon-corazon>
  </div>
</template>

<script>
import IconCorazon from "@/components/Icons/iconCorazon.vue";

export default {
  components: {
    IconCorazon
  },
  emits: ["toggleFavorito"],

  props: {
    active: {
      type: Boolean
    }
  },
  setup(props, { emit }) {
    const toggleFavorito = () => {
      emit("toggleFavorito");
    };
    return { toggleFavorito };
  }
};
</script>

<style></style>
