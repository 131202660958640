<template>
  <go-back-bar
    :titulo="
      opcionActiva ? opciones.find(o => o.id === opcionActiva).titulo : 'Más'
    "
    :arrow="opcionActiva !== null"
    @go-back="resetOpcionActiva"
  ></go-back-bar>

  <div class="mt-4">
    <div v-show="!opcionActiva">
      <div
        v-for="(opcion, index) in opciones"
        :key="opcion.id"
        :class="[
          { 'border-t-2': index === 0 },
          'px-2 py-2 flex border-2 border-gris-2 border-t-0 text-negro-1 bg-white relative'
        ]"
        @click="opcionActiva = opcion.id"
      >
        <div class="w-8">
          <component :is="opcion.icon"></component>
        </div>
        <span class="font-semibold">
          {{ opcion.titulo }}
        </span>
        <div class="absolute right-1 ">
          <icon-chevron-right />
        </div>
      </div>
    </div>

    <div v-if="opcionActiva">
      <component :is="opcionActiva" />
    </div>
  </div>
</template>

<script>
import router from "@/router/router.js";
import { ref } from "vue";

import GoBackBar from "../components/elementos/GoBack.vue";

import IconUser from "@/components/Icons/Mas/iconUser";
import IconAyuda from "@/components/Icons/Mas/iconAyuda";
import IconTarjeta from "@/components/Icons/Mas/iconTarjeta";
import IconTerminos from "@/components/Icons/Mas/iconTerminos";
import IconTicket from "@/components/Icons/Mas/iconTicket";
import IconChevronRight from "@/components/Icons/Mas/iconChevronRight.vue";

import Perfil from "@/components/Mas/Perfil";
import Pedidos from "@/components/Mas/Pedidos";
import Pago from "@/components/Mas/Pago";
import Terminos from "@/components/Mas/Terminos";
import Ayuda from "@/components/Mas/Ayuda";

export default {
  name: "favoritos",
  components: {
    GoBackBar,
    IconUser,
    IconTicket,
    IconAyuda,
    IconTerminos,
    IconTarjeta,
    IconChevronRight,
    Perfil,
    Pedidos,
    Pago,
    Terminos,
    Ayuda
  },
  props: {
    activa: {
      type: String,
      required: false,
      default: null
    }
  },
  setup(props) {
    const opciones = [
      { id: "perfil", icon: "icon-user", titulo: "Mi perfil" },
      { id: "pedidos", icon: "icon-ticket", titulo: "Pedidos" }
    ];

    //    { id: "pago", icon: "icon-tarjeta", titulo: "Métodos de pago" },
    // {
    //   id: "terminos",
    //   icon: "icon-terminos",
    //   titulo: "Términos y condiciones"
    // },
    // { id: "ayuda", icon: "icon-ayuda", titulo: "Centro de ayuda" }

    let opcionActiva = ref(props.activa);

    const resetOpcionActiva = () => {
      opcionActiva.value = null;
    };
    const goToLocales = () => {
      router.push({ name: "Locales" });
    };

    return { goToLocales, opciones, opcionActiva, resetOpcionActiva };
  }
};
</script>

<style></style>
