<template>
  <div
    v-for="local in sortByLocales"
    :key="local.id"
    :class="[
      {
        'opacity-low':
          !local.baskets_qty || local.baskets_qty - local.sold === 0
      },
      'border font-sans bg-white shadow-ms mt-2 mb-4 grid grid-cols-3 justify-left shadow-md rounded'
    ]"
    @click="goToLocal(local.id)"
  >
    <div class="h-full relative">
      <div
        class="absolute left-0 bottom-0 w-full text-left h-6 px-1 pl-2 font-medium bg-verde-1 text-white text-sm rounded-bl shadow-md"
        v-if="local.baskets_disponibles !== 0"
      >
        {{ local.baskets_disponibles > 5 ? "+5" : local.baskets_disponibles }}
        Disponibles
      </div>
      <div class="w-full rounded-l" style="overflow:hidden;height:99.6%">
        <img
          v-if="local.image"
          class="object-cover	h-36 w-full"
          :src="local.image"
          alt=""
        />
        <img v-else class="h-full w-full" src="default-profile.png" alt="" />
      </div>
    </div>

    <div
      class="ml-4 mt-1 flex flex-grow text-left flex-col col-span-2 relative"
    >
      <!-- Favoritos -->
      <div class="absolute right-2 top-0">
        <favorito
          class="w-4 h-4"
          :active="local.favorite"
          @click.stop="toggleFavorito(local.id)"
        ></favorito>
      </div>

      <div class="text-lg font-semibold">
        {{ local.name }}
      </div>
      <div class="text-gray-400 text-sm leading-4">
        Retiro: hoy {{ local.open }}hs a {{ local.close }}hs
      </div>
      <div
        class="absolute right-2 bottom-1 text-sm"
        v-if="local.baskets_qty && local.baskets_qty - local.sold > 0"
      >
        <div class="flex flex-grow md:justify-end md:mr-2">
          <div
            class="bg-azul-1 px-3 py-1 shadow-md text-white rounded cursor-pointer"
          >
            ${{ local.baskets_price }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router/router.js";
import { toRefs, computed } from "vue";
import { apiPostFavorito } from "@/services/routes.js";
import store from "@/services/store";
import Favorito from "../elementos/Favorito.vue";

export default {
  name: "Listado",
  components: {
    Favorito
  },
  props: {
    locales: {
      type: Array,
      required: true
    }
  },
  emits: ["toggleFavorito"],
  setup(props, { emit }) {
    let locales = toRefs(props).locales;
    let usuario = store.state.usuario;
    let auth = usuario.auth;

    const goToLocal = id => {
      router.push({ name: "local", params: { id: id } });
    };

    const sortByLocales = computed(() => {
      return locales.value;
    });

    const toggleFavorito = async id => {
      let add = !locales.value.find(l => l.id === id).favorite;
      let res = await fetch(apiPostFavorito(), {
        method: "POST",
        headers: {
          Authorization: auth,
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          client_id: usuario.id,
          store_id: id,
          add: add
        })
      });
      res = await res.json();
      if (res.result === "ok") {
        emit("toggleFavorito", id, add);
      }
    };

    return { goToLocal, sortByLocales, toggleFavorito };
  }
};
</script>

<style>
.opacity-low {
  opacity: 0.4;
}
</style>
