<template>
  <closer class="z-40" @click="$emit('onClose')" />
  <div
    class="bg-white text-negro-1 rounded-t-xl border-t border-gris-2 py-2 px-2 h-auto w-full fixed bottom-14 flex flex-col z-50 items-center"
  >
    <slot />
  </div>
</template>

<script>
import Closer from "../Closer.vue";
export default {
  components: {
    Closer
  }
};
</script>

<style></style>
