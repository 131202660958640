<template>
  <div class="py-1 h-8"></div>
  <div
    class="fixed top-0 z-50 flex w-full bg-verde-1 text-white shadow-md py-1 px-2"
  >
    <div @click="goBack" v-if="arrow">
      <icon-flecha-izquierda></icon-flecha-izquierda>
    </div>
    <div class="px-2 font-semibold">
      {{ titulo }}
    </div>
  </div>
</template>

<script>
import iconFlechaIzquierda from "../Icons/iconFlechaIzquierda.vue";

export default {
  components: { iconFlechaIzquierda },
  props: {
    titulo: {
      type: String,
      required: true
    },
    arrow: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  emits: ["go-back"],
  setup(props, { emit }) {
    const goBack = () => {
      emit("go-back");
    };

    return { goBack };
  }
};
</script>

<style></style>
