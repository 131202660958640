<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 105.579 99.586">
    <g
      id="Group_240"
      data-name="Group 240"
      transform="translate(-42.407 -5.493)"
    >
      <path
        id="Path_305"
        data-name="Path 305"
        d="M110.768,85.976c.485-.454.971-.91,1.447-1.371,1.12-1.076,2.269-2.216,3.42-3.387,13.143-13.378,23.1-28.082,28.8-42.514.293-.737.57-1.484.831-2.209s.5-1.436.719-2.143a38.522,38.522,0,0,0,2-10.915c.008-.711-.018-1.42-.078-2.112a17.8,17.8,0,0,0-.291-2.025l-.043-.171a13.85,13.85,0,0,0-.713-2.294,11.738,11.738,0,0,0-1.521-2.666l-.15-.2a12.915,12.915,0,0,0-2.511-2.392C140.011,9.625,136.388,8.29,131.6,7.5c-.661-.109-1.375-.212-2.187-.308l-.431-.056c-.834-.094-1.711-.173-2.606-.238-.215-.014-.432-.027-.647-.038l-.251-.014c-1.222-.072-2.46-.116-3.674-.132-.99-.013-2-.009-2.994.011l-.588.015-.71.018q-.834.029-1.678.067c-.559.024-1.121.055-1.686.09l-.077,0c-.233.015-.465.034-.7.051l-.667.049-.672.049-.184.015c-.1.008-.209.016-.315.029l-.284.025c-.545.047-1.086.1-1.631.15l-.169.017c-1.184.12-2.429.262-3.792.434-13.534,1.706-24.889,5.105-33.75,10.1a59.519,59.519,0,0,0-17.2,14.318c-.432.535-.8,1.01-1.137,1.45s-.657.9-.979,1.35A58.1,58.1,0,0,0,45.636,48.3c-.252.685-.484,1.464-.708,2.213s-.436,1.517-.64,2.32a60.885,60.885,0,0,0-1.311,6.693c-.072.534-.137,1.071-.2,1.609s-.114,1.077-.156,1.614l-.016.168c-.021.238-.038.474-.058.712-.074,1.083-.122,2.194-.137,3.308-.02,1.2,0,2.368.046,3.488a47.4,47.4,0,0,0,3.793,17.617,41.133,41.133,0,0,0,5,8.29,33.933,33.933,0,0,0,4.267,4.473,17.1,17.1,0,0,0,2.156,1.523,23.285,23.285,0,0,0,2.255,1.253,24.264,24.264,0,0,0,8.258,2.287c.671.068,1.357.108,2.033.123.755.019,1.439.012,2.089-.02a40.182,40.182,0,0,0,9.146-1.557c.578-.163,1.2-.358,1.9-.595.614-.209,1.252-.436,1.892-.681,7.776-2.976,15.673-8.176,24.144-15.9Q110.087,86.617,110.768,85.976Z"
        transform="translate(0 -0.927)"
        fill="#e4b134"
      />
      <g
        id="Group_237"
        data-name="Group 237"
        transform="translate(52.635 5.493)"
      >
        <path
          id="Path_306"
          data-name="Path 306"
          d="M438.34,12.379a3.443,3.443,0,1,1,2.435-1.007A3.434,3.434,0,0,1,438.34,12.379Zm0-4.512a1.068,1.068,0,1,0,.755.313A1.066,1.066,0,0,0,438.34,7.867Z"
          transform="translate(-351.883 -5.493)"
          fill="#3f6a32"
        />
        <path
          id="Path_307"
          data-name="Path 307"
          d="M138.062,390.023a3.443,3.443,0,1,1,2.436-1.007,3.434,3.434,0,0,1-2.436,1.007Zm0-4.511a1.068,1.068,0,1,0,.756.312A1.066,1.066,0,0,0,138.062,385.511Z"
          transform="translate(-122.941 -293.422)"
          fill="#3f6a32"
        />
        <path
          id="Path_308"
          data-name="Path 308"
          d="M395.044,32.488a1.184,1.184,0,0,1-.84-.348l-1.417-1.417L391.37,32.14a1.188,1.188,0,0,1-1.68-1.68l2.257-2.257a1.188,1.188,0,0,1,1.68,0l2.257,2.257a1.188,1.188,0,0,1-.84,2.028Z"
          transform="translate(-317.151 -22.543)"
          fill="#3f6a32"
        />
        <path
          id="Path_309"
          data-name="Path 309"
          d="M426.023,378.263a1.183,1.183,0,0,1-.84-.348l-1.417-1.417-1.417,1.417a1.188,1.188,0,1,1-1.68-1.68l2.257-2.257a1.187,1.187,0,0,1,1.68,0l2.257,2.257a1.188,1.188,0,0,1-.84,2.028Z"
          transform="translate(-340.77 -286.174)"
          fill="#3f6a32"
        />
        <path
          id="Path_310"
          data-name="Path 310"
          d="M91.161,383.013a1.182,1.182,0,0,1-.839-.348L88.9,381.248l-1.417,1.417a1.187,1.187,0,0,1-1.679-1.68l2.257-2.257a1.187,1.187,0,0,1,1.679,0L92,380.985a1.188,1.188,0,0,1-.84,2.028Z"
          transform="translate(-85.46 -289.796)"
          fill="#3f6a32"
        />
      </g>
      <g
        id="Group_238"
        data-name="Group 238"
        transform="translate(70.273 19.052)"
      >
        <path
          id="Path_311"
          data-name="Path 311"
          d="M202.175,78.483h18.768V62.566H199.087V68.03H180.319V79.045l21.856-.323Z"
          transform="translate(-175.423 -62.566)"
          fill="#fff"
        />
        <path
          id="Path_312"
          data-name="Path 312"
          d="M366.112,87.011a10.528,10.528,0,0,0-11.793-9.018V90.36l11.693-.028A10.45,10.45,0,0,0,366.112,87.011Z"
          transform="translate(-308.086 -74.258)"
          fill="#fff"
        />
        <path
          id="Path_313"
          data-name="Path 313"
          d="M159.708,134.566l9.112,33.5h44.072c1.834,0,3.433-1.551,3.876-3.786l5.9-29.711Z"
          transform="translate(-159.708 -117.461)"
          fill="#fff"
        />
      </g>
      <path
        id="Path_314"
        data-name="Path 314"
        d="M155.158,324.542a5.015,5.015,0,1,1-5.015,5.015A5.015,5.015,0,0,1,155.158,324.542Z"
        transform="translate(-82.142 -243.254)"
        fill="#fff"
      />
      <path
        id="Path_315"
        data-name="Path 315"
        d="M261.958,67.347V61.6h22.5V78.063h-19.08V67.347Z"
        transform="translate(-167.394 -42.776)"
        fill="#ee8484"
      />
      <g
        id="Group_239"
        data-name="Group 239"
        transform="translate(54.269 17.648)"
      >
        <path
          id="Path_316"
          data-name="Path 316"
          d="M197.051,74.331a1.624,1.624,0,0,0-1.254-.593h-3.834a12.187,12.187,0,0,0-4.7-10.959,12.072,12.072,0,0,0-8.094-2.486V58.281a1.622,1.622,0,0,0-1.622-1.622h-21.9a1.622,1.622,0,0,0-1.622,1.622v3.974H137.076a1.622,1.622,0,0,0-1.622,1.622v9.86h-2.9l-2.075-8.688A1.622,1.622,0,0,0,128.9,63.8h-4.475a1.622,1.622,0,0,0,0,3.244h3.194l10,41.89-3.789,9.683a6.83,6.83,0,1,0,6.615,3.195h37.175a6.823,6.823,0,1,0,5.808-3.244H137.337l3.077-7.866h45.55c2.633,0,4.89-2.111,5.488-5.132l5.935-29.9a1.623,1.623,0,0,0-.337-1.345Zm-62.409,54.648a3.581,3.581,0,1,1,3.581-3.581A3.585,3.585,0,0,1,134.641,128.979Zm52.37-3.581a3.581,3.581,0,1,1-3.581-3.581A3.585,3.585,0,0,1,187.011,125.4Zm5.352-41.076H177.332l-1.82-7.339h18.309Zm-2.1,10.584H179.956l-1.82-7.34h13.583Zm-21.305,12.556-2.309-9.311h10.769l2.308,9.311Zm-14.112,0-2.308-9.311h10.769l2.309,9.311Zm-14.24,0-2.224-9.311h10.813l2.309,9.311Zm31.564-30.479,1.82,7.339H163.22l-1.82-7.339Zm-14.112,0,1.82,7.339h-10.77l-1.82-7.339Zm2.624,10.583,1.82,7.34h-10.77l-1.82-7.34Zm-12.292,7.34H137.606l-1.753-7.34H146.57Zm17.454,0-1.82-7.34h10.769l1.82,7.34Zm22.865-23.64a8.935,8.935,0,0,1-.015,2.472H179.17v-10.2A8.933,8.933,0,0,1,188.709,71.266ZM157.271,59.9h18.655V73.738H160.6v-9.86a1.622,1.622,0,0,0-1.622-1.622h-1.7ZM138.7,65.5h18.654v8.238H138.7Zm5.248,11.483,1.82,7.339H135.078l-1.753-7.339Zm42.018,30.479h-2.9L180.76,98.15h8.858l-1.348,6.792c-.289,1.46-1.259,2.52-2.306,2.52Z"
          transform="translate(-115.565 -56.659)"
          fill="#3f6a32"
        />
        <path
          id="Path_317"
          data-name="Path 317"
          d="M93.939,86.97a1.6,1.6,0,1,1-1.6,1.6A1.6,1.6,0,0,1,93.939,86.97Z"
          transform="translate(-92.34 -79.769)"
          fill="#3f6a32"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
