<template>
  <div class="w-full bg-white py-4">
    <div
      class="rounded-full border-2 py-2 px-4 text-verde-1 border-verde-1 w-4/5 max-w-lg mx-auto font-semibold text-xl"
      @click="goToLogin"
    >
      Iniciar sesión o registrarse
    </div>
  </div>
</template>

<script>
import router from "@/router/router.js";

export default {
  setup() {
    const goToLogin = () => {
      router.push({ name: "Login" });
    };

    return { goToLogin };
  }
};
</script>

<style></style>
