<template>
  <div class="absolute top-4 h-full overflow-hidden z-0" style="height:97%">
    <img src="fondo_1.svg" class="z-0" alt="" />
    <img src="fondo_2.svg" class="z-0" alt="" />
  </div>
  <div class="z-10">
    <img class="mx-auto w-full" src="logo.svg" alt="" />
  </div>
</template>

<script>
export default {};
</script>

<style></style>
