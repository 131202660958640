<template>
  <div class="p-4">
    <div class="flex mb-4">
      <div v-if="auth" class="px-2 py-1 bg-verde-1 text-white rounded">
        Logueado
      </div>
      <div v-else class="px-2 py-1 bg-rojo-1 rounded" @click="loginCliente()">
        Click para loguearse
      </div>

      <div class="flex text-left ml-4">
        <div v-for="(value, key) in datos" :key="key">
          {{ key }}
          <input type="text" class="rounded-md pl-2" v-model="datos[key]" />
        </div>
      </div>
    </div>

    <div class="h-auto mt-4 mb-8 text-left">
      LOG
      <div
        class="w-full p-2 border border-negro-1 rounded bg-gray-100 whitespace-pre overflow-y-scroll	"
      >
        <div v-if="errores" class="text-rojo-1">
          <span v-for="(error, index) in errores" :key="index">
            {{ error.msg ? error.msg : error }}
          </span>
        </div>
        <div v-if="success" class="text-verde-1">
          Funcionó <br />
          Respuesta:<br />
          {{ success }}
        </div>
      </div>
    </div>

    <div class="flex mt-4">
      <div
        v-for="opcion in opciones"
        :key="opcion.id"
        @click="opcion.fnc"
        class="px-2 py-1 rounded bg-gris-2 w-auto mr-2"
      >
        {{ opcion.nombre }}
      </div>
    </div>
  </div>
</template>

<script>
import {
  apiRegistroCliente,
  apiLogin,
  apiMe,
  apiPostLocalBasket,
  apiPostSale,
  apiGetLocalBasket
} from "@/services/routes.js";
import { ref } from "vue";

export default {
  setup() {
    let errores = ref(null);
    let success = ref(null);
    let auth = ref(null);

    let datos = ref({
      id_client: 1,
      id_store: 1,
      id_basket: 10
    });

    let opciones = [
      {
        id: "registroCliente",
        nombre: "Registro cliente",
        fnc: () => registroCliente()
      },
      {
        id: "loginCliente",
        nombre: "Login cliente",
        fnc: () => loginCliente()
      },
      {
        id: "meCliente",
        nombre: "Me Cliente",
        fnc: () => meCliente()
      },
      {
        id: "getBasket",
        nombre: "Traer Canasta",
        fnc: () => getBasket()
      },
      {
        id: "postBasket",
        nombre: "Publicar Canasta",
        fnc: () => postBasket()
      },
      {
        id: "postSale",
        nombre: "Comprar Canasta",
        fnc: () => postSale()
      }
    ];

    const registroCliente = () => {
      reset();
      fetch(apiRegistroCliente(), {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          email: "email1@gmail.com",
          password: "1234",
          first_name: "probando1",
          last_name: "probando1",
          district_id: null
        })
      })
        .then(res => res.json())
        .then(res => {
          if (res.detail) {
            showError(res);
          } else {
            showWorks(res);
          }
        });
    };

    const loginCliente = async () => {
      reset();
      let details = {
        username: "email1@gmail.com",
        password: "1234"
      };

      const formBody = Object.keys(details)
        .map(
          key =>
            encodeURIComponent(key) + "=" + encodeURIComponent(details[key])
        )
        .join("&");
      let res = await fetch(apiLogin(), {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
        },
        body: formBody
      });
      res = await res.json();
      if (res.detail) {
        showError(res);
      } else {
        auth.value = "Bearer " + res.access_token;
        showWorks(res);
      }
    };

    const meCliente = async () => {
      reset();
      let res = await fetch(apiMe(), {
        method: "GET",
        headers: {
          Authorization: auth.value
        }
      });
      res = await res.json();
      if (res.detail) {
        showError(res);
      } else {
        datos.value.id_client = res.id;
        showWorks(res);
      }
    };

    const postBasket = () => {
      reset();
      fetch(apiPostLocalBasket(), {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          price: 100,
          qty: 20,
          store_id: datos.value.id_store,
          description: "",
          date: new Date().toISOString().substring(0, 10)
        })
      })
        .then(res => res.json())
        .then(res => {
          if (res.detail) {
            showError(res);
          } else {
            showWorks(res);
          }
        });
    };

    const getBasket = () => {
      reset();
      fetch(apiGetLocalBasket(datos.value.id_store), { method: "GET" })
        .then(res => res.json())
        .then(res => {
          if (res.detail) {
            showError(res);
          } else {
            showWorks(res);
          }
        });
    };

    const postSale = () => {
      reset();
      fetch(apiPostSale(), {
        method: "POST",
        headers: {
          Authorization: auth,
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          qty: 5,
          basket_id: datos.value.id_basket,
          client_id: datos.value.id_client,
          date: new Date().toISOString().substring(0, 10),
          state: "OPEN"
        })
      })
        .then(res => res.json())
        .then(res => {
          if (res.detail) {
            showError(res);
          } else {
            showWorks(res);
          }
        });
    };

    const showWorks = res => {
      success.value = res;
    };

    const showError = res => {
      errores.value = res.detail;
    };

    const reset = () => {
      errores.value = null;
      success.value = null;
    };

    return {
      opciones,
      errores,
      success,
      auth,
      loginCliente,
      postBasket,
      postSale,
      getBasket,
      datos
    };
  }
};
</script>

<style></style>
