<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g id="Group_129" data-name="Group 129">
      <path
        id="Path_180"
        data-name="Path 180"
        d="M20,22a1,1,0,0,1-.447-.1L16,20.118,12.447,21.9a1,1,0,0,1-.894,0L8,20.118,4.447,21.9A1,1,0,0,1,3,21V5A3,3,0,0,1,6,2H18a3,3,0,0,1,3,3V21a1,1,0,0,1-1,1ZM8,18a.989.989,0,0,1,.447.106L12,19.882l3.553-1.776a1,1,0,0,1,.894,0L19,19.382V5a1,1,0,0,0-1-1H6A1,1,0,0,0,5,5V19.382l2.553-1.276A.989.989,0,0,1,8,18Z"
        fill="#373a3c"
      />
    </g>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
