<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 500 500"
    enable-background="new 0 0 500 500"
    xml:space="preserve"
  >
    <g>
      <g>
        <path
          fill="currentColor"
          d="M499.137,7.022c-1.75-4.25-5.909-7.022-10.5-7.022C47.614,0,0,182.773,0,261.363
			c0,28.273,6.273,54.682,18.66,78.478c1.909,3.682,5.681,6.022,9.818,6.114c3.909-0.114,8.023-2.068,10.091-5.682
			C68.342,288.818,152.659,161.818,291.91,94.341c5.705-2.727,12.523-0.387,15.227,5.228c2.728,5.659,0.363,12.478-5.271,15.205
			c-10.5,5.091-20.592,10.637-30.479,16.386c-3.771,2.204-7.432,4.545-11.135,6.84c-5.569,3.455-11.068,6.977-16.433,10.614
			c-5.34,3.591-10.614,7.273-15.796,11.045c-1.614,1.181-3.159,2.386-4.75,3.591C84.682,266.523,0,431.272,0,488.637
			c0,6.273,5.091,11.364,11.364,11.364c6.272,0,11.364-5.091,11.364-11.364c0-18.318,12.477-55.454,36.09-99.75
			c34.273,28.023,80.387,42.933,134.364,42.933c145.932,0,176.501-138.864,192.909-213.478
			c22.091-100.41,70.704-159.068,110.568-198.955C499.933,16.137,500.888,11.272,499.137,7.022z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
