<template>
  <div id="map" style="height:100%;width:100%"></div>
  <div class="absolute bottom-6 h-22 w-full" v-if="localSeleccionado.name">
    <div
      class="w-2/3 mx-auto flex border rounded shadow-md bg-white p-2 cursor-pointer"
      @click="irALocal(localSeleccionado.id)"
    >
      <div class="w-1/3 flex justify-center items-center">
        <div class="">
          <img
            v-if="localSeleccionado.image"
            class="object-cover	rounded-full w-14 h-14"
            :src="localSeleccionado.image"
            alt=""
          />
          <img v-else class="h-full w-full" src="default-profile.png" alt="" /> 
        </div>
      </div>
      <div class="flex flex-col text-left text-sm">
        <div class="font-semibold">{{ localSeleccionado.name }}</div>
        <div>Lunes - Martes - Jueves</div>
        <div>
          Retiro: hoy {{ localSeleccionado.open }}hs a
          {{ localSeleccionado.close }}hs
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, toRefs, watch } from "vue";
import { Loader } from "@googlemaps/js-api-loader";

export default {
  props: {
    getIcon: {
      type: Function,
      required: false,
      default: null
    },
    markers: {
      type: Array,
      required: false,
      default: () => []
    },
    zoomInicial: {
      type: Number,
      required: false,
      default: 12
    },
    centerInicial: {
      type: Array,
      required: false,
      default: () => [-34.572644, -58.454645]
    },
    tooltip: {
      type: Boolean,
      required: false,
      default: true
    },
    ubicacion: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  emits: ["marker-clickeado"],
  setup(props, { emit }) {
    /* eslint-disable no-undef */

    let map = ref(null);
    let zoom = toRefs(props).zoomInicial;
    let center = toRefs(props).centerInicial;
    let markers = toRefs(props).markers;
    let getIcon = toRefs(props).getIcon;
    let tooltip = toRefs(props).tooltip;
    let ubicacion = toRefs(props).ubicacion;

    let markersInMap = [];

    let localSeleccionado = ref({});

    onMounted(() => {
      // eslint-disable-next-line no-undef
      const loader = new Loader({
        apiKey: "AIzaSyB83nMg2JYfHnLKQYnqmFBRDIhUxWNr2As",
        version: "weekly",
        libraries: ["places"]
      });

      loader
        .load()
        .then(() => {
          initializeMap();
        })
        .catch(() => {
          // do something
        });
    });

    const mapOptions = {
      center: {
        lat: center.value[0],
        lng: center.value[1]
      },
      zoom: zoom.value,
      disableDefaultUI: true
    };

    watch(markers, () => {
      for (let i in markersInMap) {
        markersInMap[i].setMap(null);
      }
      markersInMap = [];

      initializeMarkers();
    });
    let initializeMap = () => {
      map.value = new google.maps.Map(
        document.getElementById("map"),
        mapOptions
      );

      const locationButton = document.createElement("div");

      if (ubicacion.value) {
        locationButton.textContent = "Mi ubicación";
        locationButton.classList.add("bg-white");
        map.value.controls[google.maps.ControlPosition.TOP_LEFT].push(
          locationButton
        );
        locationButton.addEventListener("click", () => {
          if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
              position => {
                const pos = {
                  lat: position.coords.latitude,
                  lng: position.coords.longitude
                };
                map.value.setCenter(pos);
                map.value.setZoom(16);
              },
              () => {
                handleLocationError(true, infoWindow, map.value.getCenter());
              }
            );
          } else {
            // Browser doesn't support Geolocation
            handleLocationError(false, infoWindow, map.value.getCenter());
          }
        });
      }

      initializeMarkers();
    };

    let initializeMarkers = () => {
      let markersFilter = markers.value.filter(m => m.latitude && m.longitude);
      markersFilter.forEach(m => {
        let markerOptions = {
          position: {
            lat: m.latitude,
            lng: m.longitude
          },
          map: map.value
        };

        let icon = null;
        if (getIcon.value) {
          icon = {
            url: getIcon.value(m), // url
            scaledSize: new google.maps.Size(20, 20), // scaled size
            origin: new google.maps.Point(0, 0), // origin
            anchor: new google.maps.Point(0, 0) // anchor
          };
          markerOptions.icon = icon;
        }

        let marker = new google.maps.Marker(markerOptions);
        marker.addListener("click", () => {
          localSeleccionado.value = m;
        });

        if (tooltip.value) {
          var infowindow = new google.maps.InfoWindow({
            content: `
          <div>
              <b> ${m.name} </b> <br />
              ${m.rubroName} <br />
              ${m.address} ${m.number}
          </div>
          `
          });
          marker.addListener("mouseover", function() {
            infowindow.open(map.value, marker);
          });
          marker.addListener("mouseout", function() {
            infowindow.close();
          });
        }

        markersInMap.push(marker);
      });
    };

    const irALocal = id => {
      emit("marker-clickeado", id);
    };

    return { localSeleccionado, irALocal };
  }
};
</script>

<style></style>
