<template>
  <div class="max-w-xl" style="margin: 0 auto;">
    <!-- Ventas -->
    <div class="text-left">
      <div class="text-xs px-1">23/08/2020</div>
      <div
        class="bg-white w-full py-2 border border-gris-1 flex space-x-4 px-4"
      >
        <div class="font-bold">JD45</div>
        <div>Nombre de usuario</div>
        <div>2</div>
        <div>$200</div>
        <div class="flex flex-grow justify-end">Estado</div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { apiGetClientSales } from "@/services/routes.js";

export default {
  setup() {
    let localId = 1;
    let sales = ref(null);

    onMounted(() => {
      fetchSales();
    });

    const fetchSales = async () => {
      let res = await fetch(apiGetClientSales(localId));
      res = await res.json();
      console.log(res);
    };

    return { sales };
  }
};
</script>

<style></style>
