<template>
  <div class="flex">
    <div class="flex flex-col items-center">
      <span
        class="select-none cursor-pointer text-t-negro-1"
        @click="sumarHora"
      >
        ▲
      </span>
      <input
        disabled
        v-model="hora"
        type="text"
        class="border w-8 text-center bg-white"
      />
      <span
        class="select-none cursor-pointer text-t-negro-1"
        @click="restarHora"
      >
        ▼
      </span>
    </div>
    <div class="flex flex-col items-center ml-2">
      <span
        class="select-none cursor-pointer text-t-negro-1"
        @click="sumarMinutos"
      >
        ▲
      </span>
      <input
        disabled
        v-model="minutos"
        type="text"
        class="border w-8 text-center bg-white"
      />
      <span
        class="select-none cursor-pointer text-t-negro-1"
        @click="restarMinutos"
      >
        ▼
      </span>
    </div>
  </div>
</template>

<script>
import { ref, toRefs, onMounted } from "vue";
export default {
  props: {
    horario: {
      type: String,
      required: true
    }
  },
  emits: ["actualizar"],
  setup(props, { emit }) {
    let horario = toRefs(props).horario.value.split(":");
    let hora = ref(horario[0]);
    let minutos = ref(horario[1]);

    let horaMax = 24;
    let horaMin = 0;

    let minutosHabilitados = {
      cuarto: ["00", "15", "30", "45"],
      media: ["00", "30"]
    };
    let tipoMinutos = "cuarto";

    onMounted(() => {
      emitActualizar();
    });

    let sumarHora = () => {
      let horaParseada = parseInt(hora.value);
      if (horaParseada < horaMax) {
        horaParseada = (horaParseada + 1).toString();
        hora.value =
          horaParseada.length === 1 ? "0" + horaParseada : horaParseada;
      }
      emitActualizar();
    };

    let restarHora = () => {
      let horaParseada = parseInt(hora.value);
      if (horaParseada > horaMin) {
        horaParseada = (horaParseada - 1).toString();
        hora.value =
          horaParseada.length === 1 ? "0" + horaParseada : horaParseada;
      }
      emitActualizar();
    };

    let sumarMinutos = () => {
      let minutoIndex =
        minutosHabilitados[tipoMinutos].findIndex(m => m === minutos.value) + 1;
      if (minutosHabilitados[tipoMinutos][minutoIndex]) {
        minutos.value = minutosHabilitados[tipoMinutos][minutoIndex];
      } else {
        minutos.value = minutosHabilitados[tipoMinutos][0];
      }
      emitActualizar();
    };
    let restarMinutos = () => {
      let minutoIndex =
        minutosHabilitados[tipoMinutos].findIndex(m => m === minutos.value) - 1;
      if (minutosHabilitados[tipoMinutos][minutoIndex] !== undefined) {
        minutos.value = minutosHabilitados[tipoMinutos][minutoIndex];
      } else {
        let habilitadosLength = minutosHabilitados[tipoMinutos].length - 1;
        minutos.value = minutosHabilitados[tipoMinutos][habilitadosLength];
      }
      emitActualizar();
    };

    let emitActualizar = () => {
      emit(
        "actualizar",
        `${hora.value}:${
          minutos.value.length === 1 ? "0" + minutos.value : minutos.value
        }`
      );
    };
    return {
      hora,
      minutos,
      sumarHora,
      restarHora,
      sumarMinutos,
      restarMinutos
    };
  }
};
</script>

<style></style>
