<template>
  <!-- Div oscuro  que ocupa toda la pantalla, para detectar un "clickout" a tooltips activos -->
  <div class="w-full h-full fixed top-0 left-0 bg-black opacity-60"></div>
</template>

<script>
export default {};
</script>

<style></style>
